@import "../abstracts/variables.scss";

.newest-members-expanded-item {
    background-color: $color-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 90%;
    border-bottom: solid 0.03rem $color-grey;
    padding: 0.5rem 0 0.5rem 0;

    &__left-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        min-width: 7rem;
        align-items: center;

        @media screen and (max-width: $breakpoint-420) {
            min-width: 5rem;
        }
        &:hover {
            cursor: pointer;
            filter: brightness(0.95);
        }
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: center;
        width: 90%;
        overflow: hidden;
    }

    &__right-panel {
        display: flex;
        justify-content: center;
        align-content: center;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-blue;
        min-width: 4rem;
        min-height: 4rem;
        max-width: 4rem;
        max-height: 4rem;
        margin-right: 1rem;
        margin-left: 1rem;
        background-color: transparent;
        border-radius: $border-radius-circular;

        &:hover {
            background-color: $color-lighter-blue;
        }

        @media screen and (max-width: $breakpoint-420) {
            min-width: 3rem;
            min-height: 3rem;
        }
    }

    &__subtext-container {
        width: 100%;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-dark-blue;
        align-self: flex-start;
        word-break: break-word;
        hyphens: auto;
        margin-bottom: 0.25rem;
        &:hover {
            cursor: pointer;
            filter: brightness(0.95);
            text-decoration: underline;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font16;
        }
    }

    &__right-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 500;
        color: transparent;
        text-shadow: 0 0 0.313rem $color-blue;
    }
}
