@import "../abstracts/variables.scss";

.donate-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    max-width: 19.375rem;
    min-width: 14rem;
    background-color: transparent;
    padding-bottom: 2.938rem;

    &__text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-bottom: 1.813rem;
    }

    &__text {
        font-family: $font-family-inter;
        color: $color-blue;
        text-align: center;
        font-weight: 400;
        font-size: $font18;
        &--font-weight-700 {
            font-weight: 700;
        }

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font16;
        }
    }
    &__button-size {
        width: 100%;
        height: 3.5rem;
    }

    &__button-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
    }
}
