@import "../abstracts/mixins/component-mixins.scss";

.search-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;
    top: 5.1rem;
    z-index: $z-index-second-layer;

    @media screen and (max-width: $breakpoint-1439) {
        top: 4.4rem;
    }
    @media screen and (max-width: $breakpoint-1023) {
        top: 3.4rem;
    }
    @media screen and (max-width: $breakpoint-660) {
        top: 3.3rem;
    }
    @media screen and (max-width: $breakpoint-600) {
        left: -2rem;
        right: -2rem;
    }

    @media screen and (max-width: $breakpoint-450) {
        left: -4rem;
        right: -4rem;
    }
    @media screen and (max-width: $breakpoint-389) {
        left: -3rem;
        right: -5rem;
    }

    &__content {
        width: 100%;
        max-height: 27rem;
        height: fit-content;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
    }

    &__item {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        border-bottom: 0.031rem solid $color-grey-c4;
        background-color: $color-white;
        cursor: pointer;
        padding: 0.2rem;
        box-sizing: border-box;

        &:hover {
            filter: $hover-brightness-darker;
        }

        &--no-border {
            border-bottom: none !important;
        }

        &--post {
            height: 5rem;
        }
    }

    &__left-panel {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__right-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__image {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: $border-radius-circular;
        object-fit: cover;
        margin-left: 0.7rem;
        margin-right: 0.7rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        word-break: break-word;

        &--font-large {
            font-size: $font16;
            font-weight: 700;
            color: $color-blue;
        }

        &--deleted {
            color: $color-grey-c4;
        }

        &--hashtag {
            font-size: $font18;
        }
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-mid-dark-grey;

        &--hashtag {
            color: $color-grey-9c;
        }
    }

    &__button-container {
        width: 100%;
        height: 1.7rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    &__button {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-blue;
        cursor: pointer;

        &:hover {
            filter: $hover-brightness;
        }
    }
    &__no-data-text {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__tag {
        @include tag();
    }

    &__tag-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }
}
