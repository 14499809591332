@import "../abstracts/variables.scss";

.my-organizations-screen {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: transparent;
    margin-bottom: 5rem;
    min-width: 20rem;
}