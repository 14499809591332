.loading-screen {
    background-color: $color-white;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &__image {
        width: 15rem;
        height: 15rem;
    }
}
