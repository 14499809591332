.go-premium-price-card {
    width: 17.5rem;
    height: 12rem;
    position: relative;
    border: solid $color-gold 0.03rem;
    border-radius: $border-radius-09;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0.75rem;

    @media screen and (max-width: $breakpoint-1279) {
        width: 13rem;
    }

    @media screen and (max-width: $breakpoint-1023) {
        width: 17.5rem;
    }
    @media screen and (max-width: $breakpoint-819) {
        width: 13rem;
    }

    @media screen and (max-width: $breakpoint-660) {
        width: 20.5rem;
        height: 7rem;
        flex-direction: row-reverse;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    @media screen and (max-width: $breakpoint-389) {
        width: 19rem;
    }

    &--display-none {
        display: none;
    }

    &--color-red {
        border: solid $color-red 0.03rem;
    }

    &__text {
        font-family: $font-family-inter;
        &--font-weight-400 {
            font-weight: 400;
        }

        &--font-weight-500 {
            font-weight: 500;
        }

        &--font-weight-700 {
            font-weight: 700;
        }
        &--font-weight-900 {
            font-weight: 900;
        }

        &--color-gold {
            color: $color-gold;
        }

        &--color-red {
            color: $color-red;
        }

        &--color-blue {
            color: $color-darker-blue;
        }

        &--font-size-16 {
            font-size: $font16;
            text-align: center;
        }

        &--font-size-18 {
            font-size: $font18;
            text-align: center;
        }

        &--font-size-30 {
            font-size: $font30;
        }
        &--font-size-60 {
            font-size: $font60;
            @media screen and (max-width: $breakpoint-660) {
                font-size: $font45;
            }
        }

        &--margin-right-05 {
            margin-right: 0.5rem;
        }
    }

    &__permium-container {
        display: flex;
        flex-direction: column;
        margin-top: 0.7rem;

        @media screen and (max-width: $breakpoint-660) {
            width: 50%;
            height: 100%;
            margin-top: 0;
            justify-content: center;
            align-items: center;
        }
    }

    &__premium-title {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__permium-icon {
        margin-right: 0.5rem;

        @media screen and (max-width: $breakpoint-389) {
            margin-right: 0.3rem;
        }
    }

    &__offer-container {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $breakpoint-660) {
            width: 50%;
            height: 100%;
            justify-content: center;
            align-items: center;
            margin-left: 0.5rem;
        }

        @media screen and (max-width: $breakpoint-389) {
            width: 60%;
        }
    }

    &__month-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &__price-container {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
    }

    &__button {
        height: 2.25rem;
        width: 6.75rem;
        position: absolute;
        bottom: -1.1rem;

        @media screen and (max-width: $breakpoint-660) {
            display: none;
        }
    }

    &__button-text padding {
        font-size: $font14;
    }

    &__mobile-button {
        height: 2.2rem;
        width: 5rem;
        margin-top: 0.5rem;
        @media screen and (min-width: $breakpoint-661) {
            display: none;
        }
    }
}
