@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.user-profile-experience-item {
    width: 100%;
    height: 5.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 0.03rem $color-grey;
    word-break: break-word;

    @media screen and (max-width: $breakpoint-768) {
        height: 4rem;
    }
    @media screen and (max-width: $breakpoint-550) {
        height: 100%;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    &--border-none {
        border-bottom: none;
    }

    &__job-container {
        width: 55%;
        display: flex;
        flex-direction: row;
        margin-left: 2rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 16rem;
            margin-left: 1rem;
        }
        @media screen and (max-width: $breakpoint-500) {
            flex-direction: column;
            width: 12rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 11rem;
        }
        @media screen and (max-width: $breakpoint-389) {
            width: 10rem;
        }
    }

    &__position {
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font16;
        padding-right: 1rem;
        width: 50%;
        display: flex;
        align-items: center;
        hyphens: auto;

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font14;
            width: 85%;
        }
    }

    &__company {
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font16;
        padding-right: 1rem;
        width: 50%;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-500) {
            margin-top: 0.2rem;
            width: 100%;
        }

        &--linked {
            color: $color-blue;
            cursor: pointer;
        }
    }

    &__company-link-icon {
        padding-left: 1.673rem;
        display: flex;
        align-items: center;

        @media screen and (max-width: $breakpoint-1199) {
            padding-left: 0.673rem;
        }

        @media screen and (max-width: $breakpoint-500) {
            padding-right: 1rem;
        }
    }

    &__right-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: flex-start;
        width: 13rem;
        @media screen and (max-width: $breakpoint-550) {
            width: 11rem;
        }
    }

    &__date-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 11rem;
        @media screen and (max-width: $breakpoint-389) {
            width: 7rem;
        }
        @media screen and (max-width: $breakpoint-340) {
            margin-right: 0;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 8rem;
        }
    }

    &__date {
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font14;

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }
    }

    &__icon {
        margin-right: 0.4rem;
        width: 2rem;
        @include icon();

        @media screen and (max-width: $breakpoint-1199) {
            margin-left: 0;
        }
    }
}
