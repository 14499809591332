.room-filter-dropdown {
    @media screen and (max-width: $breakpoint-550) {
        display: none;
    }
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 31%;
        max-width: 7.75rem;
        min-width: 7rem;
        height: 1.875rem;
        background-color: $color-grey-c4;
        color: $color-white;
        border-radius: 0.5rem;
        font-weight: 700;
        position: relative;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        &:hover {
            cursor: pointer;
            filter: $hover-brightness;
        }

        @media screen and (max-width: $breakpoint-1280) {
            max-width: none;
            min-width: 0;
            width: 3.5rem;
            margin-left: 0.3rem;
            margin-right: 0.3rem;
        }
    }

    &__element-container {
        position: absolute;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        box-shadow: $box-shadow-25;
        border-radius: 0.75rem;
        background: $color-white;
        width: 13.638rem;
    }

    &__element {
        font-size: $font14;
        font-weight: 500;
        color: $color-grey-c4;
        padding-left: 1.375rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

        &--blue {
            color: $color-blue;
            font-weight: 700;
        }

        &:hover {
            cursor: pointer;
            color: $color-blue;
            font-weight: 700;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
    }

    &__header-text {
        font-size: $font12;
    }
}
