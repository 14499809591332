@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.comments {
    &__text {
        color: $color-dark-blue;
        background-color: $color-grey;
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: $font14;
        width: 95%;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 0.75rem;
        margin-left: 1.2rem;

        @media screen and (max-width: $breakpoint-1024) {
            margin-left: 0.9rem;
            width: 94%;
        }

        @media screen and (max-width: $breakpoint-768) {
            margin-left: 1.2rem;
            width: 95%;
        }

        @media screen and (max-width: $breakpoint-600) {
            margin-left: 0.9rem;
            width: 94%;
        }
    }

    &__text a {
        color: $color-blue;
        word-break: break-all;
    }

    &__actions-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 20rem;

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
        }
    }
    &__reactions-container {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        align-content: center;
        position: relative;
        margin-left: 1.7rem;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 1rem;
        }
    }
    &__reaction {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
        &--no-margin {
            margin-right: 0;
        }
    }

    &__reaction-plus {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-grey-c4;
        margin-right: 0.5rem;
        margin-left: 0.25rem;
        &:hover {
            cursor: pointer;
            font-weight: 700;
        }
    }

    &__reaction-count {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-grey-c4;
        margin-left: 0.25rem;
    }
    &__add-emoji-icon {
        cursor: pointer;
        align-self: center;
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
    }
    &__emoji {
        display: flex;
        background: $color-grey;
        border: 1.59px solid $color-mid-dark-grey;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
        margin-left: -0.4rem;
    }
    &__emoji-image {
        width: 1.125rem;
        height: 1.125rem;
    }
    &__emoji-picker {
        z-index: $z-index-third-layer;
    }
}

.comments-profile-card {
    display: flex;
    flex-direction: row;
    width: 94.6%;
    margin-left: 1rem;
    height: 5rem;

    @media screen and (max-width: $breakpoint-1024) {
        margin-left: 0.5rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        margin-left: 1rem;
    }

    @media screen and (max-width: $breakpoint-600) {
        margin-left: 0.5rem;
    }

    &__left-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        min-width: 4.5rem;

        &--cursor-pointer {
            cursor: pointer;
        }
    }

    &__middle-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 87%;

        &--cursor-pointer {
            cursor: pointer;
        }
    }

    &__right-panel {
        display: flex;
        width: fit-content;
        position: relative;
        width: 9.5rem;
        margin-top: 0.75rem;

        @media screen and (max-width: $breakpoint-389) {
            width: 9.5rem;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;
        @media screen and (max-width: $breakpoint-768) {
            font-size: 0.75rem;
        }

        &--deleted {
            color: $color-grey-c4;
        }
        &--organization,
        &--admin {
            @include role-room();
        }

        &--organization {
            background-color: #e09f53;
            border-radius: 0.9rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &--admin {
            background-color: #d57467;
            padding-left: 1.125rem;
            padding-right: 1.125rem;
            border-radius: 1.25rem;
        }
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-grey;
        margin-right: 0.3rem;
    }

    &__date {
        width: max-content;
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font12;
        color: $color-grey-c4;
        padding-top: 0.5rem;
        padding-left: 0.25rem;

        @media screen and (max-width: $breakpoint-389) {
            word-break: break-word;
            width: 7rem;
            text-align: end;
        }
    }

    &__comment-dropdown {
        position: absolute;
        width: 100%;
    }
}
