.add-employee-modal {
    width: 50rem;

    @media screen and (max-width: $breakpoint-1439) {
        width: 40rem;
    }
    @media screen and (max-width: $breakpoint-1350) {
        width: 35rem;
    }
    @media screen and (max-width: $breakpoint-1200) {
        width: 30rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 1.3rem;
        margin-right: 1.3rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font18;
        color: $color-blue;
    }

    &__close-icon {
        @include icon;
    }

    &__search-container {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: center;
    }

    &__item-container {
        height: 25rem;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-bottom: 2.188rem;
        margin-top: 1.563rem;
        @media screen and (max-width: $breakpoint-1350) {
            height: 21rem;
        }

        @media screen and (max-width: $breakpoint-1200) {
            height: 17rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            height: 100%;
        }
    }

    &__no-data-text {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;
    }
    &__save-button {
        width: 7rem;
        height: 2.3rem;
        margin-right: 2rem;
    }

    &__add-button {
        width: fit-content;
        height: 2.3rem;
        margin-right: 1.9rem;

        @media screen and (max-width: $breakpoint-1350) {
            margin-right: 1.5rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            margin-right: 1.2rem;
        }
    }

    &__button-content {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: fit-content;
        height: 2rem;
    }
}
