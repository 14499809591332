@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.reactions-group-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 33rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $box-shadow-overlay;
    width: 100%;
    max-height: 35.375rem;

    @media screen and (max-width: $breakpoint-768) {
        min-width: 32.2rem;
        height: 100vh;
        max-height: none;
    }

    @media screen and (max-width: $breakpoint-550) {
        min-width: 25.5rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        min-width: 24.2rem;
    }

    @media screen and (max-width: $breakpoint-389) {
        min-width: 23.2rem;
    }

    @media screen and (max-width: $breakpoint-360) {
        min-width: 22.2rem;
    }

    &__close-icon {
        @include modal-close-icon();
    }

    &__title {
        width: 100%;
        font-family: $font-family-inter;
        font-size: $font24;
        padding-left: 2.625rem;
        padding-top: 1.626rem;
        padding-bottom: 0.625rem;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 500;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-660) {
            margin-top: 1rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            padding-left: 1.625rem;
        }
    }

    &__double-input-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-self: flex-start;

        @media screen and (max-width: $breakpoint-768) {
            width: 85%;
            margin: auto;
        }
        &--margin-left-02 {
            @media screen and (max-width: $breakpoint-660) {
                margin-left: 2rem;
            }
        }

        @media screen and (max-width: $breakpoint-420) {
            width: 86%;
        }

        @media screen and (max-width: $breakpoint-350) {
            width: 81%;
        }
    }

    &__reaction-count {
        padding-left: 0.25rem;
    }

    &__text-icon-info {
        font-size: $font20;

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font16;
        }
        @media screen and (max-width: $breakpoint-420) {
            font-size: $font14;
        }
    }

    &__input-container {
        justify-content: space-between;
        font-family: "Inter";
        font-size: 1.25rem;
        font-weight: 500;
        color: $color-grey-c4;
        align-items: center;
        padding-left: 0.594+ 0.594rem;
        padding-right: 0.594+ 0.594rem;
        padding-top: 0.813+0.813rem;
        padding-bottom: 0.48+0.48rem;
        display: flex;
        border-bottom: 0.25rem solid transparent;
        z-index: 1000;

        &:hover {
            border-bottom: 0.25rem solid $color-red;
            color: $color-red;
            position: static;
            bottom: 0;
            cursor: pointer;
        }

        &--active {
            border-bottom: 0.25rem solid $color-red;
            color: $color-red;
            position: static;
            bottom: 0;
            cursor: pointer;
        }

        @media screen and (max-width: $breakpoint-660) {
            margin-top: 1rem;
            padding-left: 0.9rem;
            padding-right: 0.9rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font16;
            padding-left: 0.6rem;
            padding-right: 0.6rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            font-size: $font14;
            padding-left: 0.4rem;
            padding-right: 0.4rem;
        }

        @media screen and (max-width: $breakpoint-389) {
            padding-left: 0.263rem;
            padding-right: 0.263rem;
        }

        &--optional {
            color: $color-grey-c4;
            background-color: white;
            margin-left: 0.5rem;
        }
    }

    &__text {
        color: $color-grey-c4;
        font-size: $font16;
        font-family: $font-family-inter;
        padding-left: 0.594+ 0.594rem;
        padding-right: 0.594+ 0.594rem;
        padding-top: 0.813+0.813rem;
        padding-bottom: 0.48+0.48rem;
        text-align: center;
    }

    &__double-input-container-profile {
        display: flex;
        flex-direction: column;
        margin-top: -0.188rem;
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
            height: 100%;
        }
    }

    .reactions-profile-card {
        display: flex;
        width: 100%;
        min-width: 33rem;
        background-color: transparent;
        flex-direction: row;
        padding-top: 1.25rem;
        margin-bottom: 1.25rem;
        border-top: 0.113rem solid $color-grey-c4;
        z-index: 100;

        @media screen and (max-width: $breakpoint-768) {
            min-width: 32.2rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            min-width: 25.6rem;
        }

        @media screen and (max-width: $breakpoint-389) {
            min-width: 23.2rem;
        }

        @media screen and (max-width: $breakpoint-360) {
            min-width: 22.2rem;
        }

        &__images {
            display: flex;
            align-items: flex-end;
            position: relative;
        }

        &__profile-image {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            margin-left: 2.875rem;

            @media screen and (max-width: $breakpoint-550) {
                margin-left: 0.875rem;
            }
        }

        &__profile-icon {
            position: absolute;
            left: 5rem;
            border-radius: 50%;
            padding: 0.1rem;
            width: 1.063rem;
            height: 1.063rem;
            background-color: aliceblue;
            @media screen and (max-width: $breakpoint-550) {
                left: 3rem;
            }
        }

        &__emoji-image {
            width: 1.063rem;
            height: 1.063rem;
        }

        &__profile-right-container {
            display: block;
            padding-left: 0.875rem;
        }

        &__user-name,
        &__user-nameMore25 {
            font-size: $font14;
            font-family: "Inter";
            font-weight: 500;
            background-color: transparent;
            overflow-wrap: break-word;

            @media screen and (max-width: $breakpoint-1350) {
                max-height: 4.75rem;
                position: static;
                display: flex;
                align-items: flex-end;
            }

            &:hover {
                cursor: pointer;
                font-weight: 700;
            }
        }

        &__user-nameMore25 {
            font-size: $font14;
            padding-top: 0.7rem;
            min-height: 0;
            width: 90%;
            overflow-wrap: break-word;
        }

        &__tags-container {
            width: 100%;
        }

        &__tags-content {
            width: 80%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 0.3rem;
        }

        &__tag {
            @include tag();

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
