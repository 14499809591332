.post-dropdown {
    font-size: $font16;
    font-family: $font-family-inter;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    width: 100%;

    @media screen and (max-width: $breakpoint-1024) {
        position: relative;
    }

    @media screen and (max-width: $breakpoint-600) {
        font-size: $font14;
    }

    &__item-container {
        width: 12.843rem;
        max-width: 12.843rem;
        min-width: 8rem;
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.938rem;
        z-index: 1;

        @media screen and (max-width: $breakpoint-1500) {
            max-width: 11.843rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media screen and (max-width: $breakpoint-1279) {
            max-width: 10.843rem;
        }

        @media screen and (max-width: $breakpoint-1024) {
            position: absolute;
            top: 1.813rem;
        }
    }

    &__item-comment-container {
        width: 12.843rem;
        max-width: 12.843rem;
        min-width: 8rem;
        height: 6rem;
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.938rem;
        z-index: 1;

        @media screen and (max-width: $breakpoint-1500) {
            max-width: 11.843rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media screen and (max-width: $breakpoint-600) {
            max-width: 11rem;
        }
    }

    &__icon {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        :hover {
            cursor: pointer;
        }

        @media screen and (max-width: $breakpoint-768) {
            padding-right: 1.2rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            padding-right: 0.5rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            padding-right: 0;
        }
    }

    &__item-edit {
        padding-left: 0.7rem;
    }

    &__item-delete {
        padding-left: 1rem;
    }

    &__item-comment {
        width: 85%;
        display: flex;
        align-items: center;
        padding-left: 1.688rem;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        margin-top: 0.156rem;
        margin-left: 0.156rem;
        margin-right: 0.156rem;
        border-radius: 0.75rem;
        cursor: pointer;
        color: $color-darker-blue;

        &:hover {
            background-color: $color-grey;
        }

        &--disabled {
            cursor: default;
            &:hover {
                background-color: transparent;
            }
            color: $color-grey-c4;
        }

        &--margin-top {
            margin-top: 0.5rem;

            @media screen and (max-width: $breakpoint-1500) {
                margin-top: 0;
            }
        }

        @media screen and (max-width: $breakpoint-1500) {
            padding-left: 1.3rem;
            padding-right: 0;
        }

        &:hover {
            background-color: $color-grey;
        }
    }

    &__item {
        width: 85%;
        display: flex;
        align-items: center;
        padding-left: 1.688rem;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        margin-top: 0.156rem;
        margin-left: 0.156rem;
        margin-right: 0.156rem;
        border-radius: 0.75rem;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1500) {
            padding-left: 1.5rem;
        }
        @media screen and (max-width: $breakpoint-1279) {
            padding-left: 1.4rem;
        }

        @media screen and (max-width: $breakpoint-600) {
            padding-left: 1.5rem;
            width: 83%;
        }

        &:hover {
            background-color: $color-grey;
        }

        &--disabled {
            cursor: default;
            &:hover {
                background-color: transparent;
            }
            display: none !important; //to be removed when edit feature is added
            color: $color-grey-c4;
        }
    }
}
