.reaction-card {
    display: flex;
    height: 2.75rem;
    background: $color-white;
    box-shadow: $dropdown-box-shadow;
    border-radius: $radio-radius;
    position: absolute;
    bottom: -0.875rem;
    left: 0;
    width: 0;

    &--feed-item {
        left: -4.5rem;
    }

    &--close {
        width: 0;
        transition: all 0.4s ease-in-out;
    }

    &--open {
        width: 14.313rem;
        transition: all 0.4s ease-in-out;
    }

    &__emoji-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: width ease 2s;
    }

    &__emoji-image {
        width: 1.625rem;
        height: 1.625rem;

        &:hover {
            width: 1.825rem;
            height: 1.825rem;
            filter: brightness(1);
            transition: all 0.2s ease-in-out;
            cursor: pointer;
        }
    }
}
