@import "../abstracts/variables.scss";

.header-question-menu {
    width: 15rem;
    height: 23rem;
    top: 5.6rem;
    position: absolute;
    border-radius: 1rem;
    right: -6rem;

    @media screen and (max-width: $breakpoint-420) {
        width: 13rem;
    }
    &__card {
        max-width: 15.625rem;
        height: fit-content;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    &__item {
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.55rem;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 500;
        color: $color-dark-blue;
        cursor: pointer;
        text-decoration: none;

        @media screen and (max-width: $breakpoint-1439) {
            height: 3rem;
        }

        &:hover {
            filter: $hover-brightness-darker;
        }

        &--border-bottom-3 {
            border-bottom: 0.03rem solid $color-light-grey;
        }
    }
}
