.edusiia-video {
    max-height: 38rem;
    max-width: 100%;
    margin-top: 2rem;
    width: 100%;

    @media screen and (max-width: $breakpoint-768) {
        margin-top: 0;
        max-height: 16rem;
    }
}

.edusiia-cover {
    max-height: 38rem;
    margin-top: 2rem;
    object-fit: contain;
    width: 100%;

    @media screen and (max-width: $breakpoint-768) {
        margin-top: 0;
        max-height: 16rem;
    }
}

.image {
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
}

.video-wrapper {
    position: relative;
}

.cover-img {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: auto;
}

.hidden {
    display: none;
}