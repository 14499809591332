@import "../abstracts/mixins/component-mixins.scss";

.article-card {
    &__organization-image {
        width: 2.808rem;
        height: 2.808rem;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 0.875rem;
        &:hover {
            cursor: pointer;
        }
    }

    &__content {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        text-overflow: ellipsis;
        margin-bottom: 2rem;
        padding-bottom: 5%;
        font-family: "Inter";
        &:hover {
            cursor: pointer;
        }
        @media screen and (max-width: $breakpoint-1365) {
            width: 95%;
            margin: auto;
            margin-bottom: 2rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
        }
    }

    &__title {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        width: 100%;
        hyphens: auto;
        width: 14rem;
        font-size: $font16;
        color: $color-darker-blue;
        font-family: "Inter";
        margin-left: 7%;
        display: flex;
        align-items: center;
        padding-bottom: 1.375rem;

        @media screen and (max-width: $breakpoint-1700) {
            width: 95%;
            margin-left: auto;
        }
        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font14;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font16;
            padding-top: 1rem;
            margin: auto;
            width: 100%;
        }

        @media screen and (max-width: $breakpoint-500) {
            width: 92%;
        }
    }

    &__article-title {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        width: 100%;
        hyphens: auto;
        width: 14rem;
        font-size: $font20;
        color: $color-dark-blue;
        margin-bottom: 0.25rem;
        color: $color-blue;
        font-family: "Inter";
        padding-top: 5%;
        margin-left: 9%;

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 90%;
            font-size: $font20;
        }
    }

    &__article-image {
        margin: auto;
        width: 90%;
        height: 47%;
        justify-items: center;
        margin-top: 0.6rem;
        margin: auto;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 0;
        border-radius: 0.563rem;
        max-height: 9.375rem;
    }

    &__text {
        margin-left: 9%;
        margin-bottom: 0.25rem;
        margin-right: 9%;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font10;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font14;
        }

        &--bold {
            color: $color-blue;

            font-weight: 700;
            &:hover {
                cursor: pointer;
                filter: $hover-brightness-darker;
            }
        }
    }

    &__article-content {
        max-width: 19.375rem;

        @media screen and (max-width: $breakpoint-1599) {
            max-width: 18.375rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 30.063rem;
        }
    }
}
