@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.my-organizations {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    max-width: 50rem;

    @media screen and (max-width: $breakpoint-1023) {
        width: 90%;
    }

    &__title {
        width: calc(100% - 2rem);
        max-width: 50rem;
        margin-left: 2rem;
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-light-blue;
        margin-bottom: 3rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        overflow-y: auto;

        @media screen and (max-width: $breakpoint-550) {
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
    }
}

.organizations-card {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 7.5rem;
    background-color: $color-white;
    margin: 1rem;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;
    cursor: pointer;

    &:hover {
        filter: $hover-brightness-darker;
    }

    @media screen and (max-width: $breakpoint-550) {
        margin: 0.5rem;
    }

    &__left-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 5.5rem;
        height: 100%;
    }

    &__mid-panel {
        display: flex;
        align-items: center;
        min-width: 65%;
        height: 100%;
    }

    &__text-style,
    &__textMore25 {
        font-family: $font-family-inter;
        font-size: $font20;
        font-weight: 700;
        color: $color-darker-blue;
        margin-left: 0.5rem;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font16;
        }
    }
}
