@import "../abstracts/mixins/modal-mixins.scss";
@import "../abstracts/variables.scss";
.room-limit-modal {
    width: 39rem;
    height: 15rem;
    min-width: 20rem;
    @include room-modal();

    &__text-container {
        width: 90%;
        height: 33%;
        margin: auto;

        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            flex-direction: column;
            height: 67%;
        }
    }
}
