@import "../abstracts/variables.scss";

.view-button {
    position: absolute;
    top: 20rem;
    right: 6%;

    @media screen and (max-width: $breakpoint-1599) {
        right: 0;
    }

    @media screen and (max-width: $breakpoint-1439) {
        top: 21rem;
    }

    @media screen and (max-width: $breakpoint-1050) {
        top: 20.7rem;
    }

    @media screen and (max-width: $breakpoint-1023) {
        right: 10%;
    }

    @media screen and (max-width: $breakpoint-768) {
        top: 20.5rem;
        right: 6%;
    }

    @media screen and (max-width: $breakpoint-550) {
        top: 93%;
        left: auto;
        right: auto;
        width: 50%;
    }

    @media screen and (max-width: $breakpoint-450) {
        width: 65%;
    }

    &__text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 9.375rem;
        height: 3.125rem;
        border-radius: $border-radius-09;
        border: solid 0.03rem $color-gold;
        background-color: $color-white;
        cursor: pointer;
        &:hover {
            background-color: $color-gold-opacity-02;
        }
        @media screen and (max-width: $breakpoint-550) {
            display: flex;
            width: 100%;
            flex-direction: row;
        }
    }

    &__text {
        text-align: center;
        font-family: $font-family-inter;
        font-weight: 700;
        color: $color-gold;
        font-size: $font14;
        @media screen and (max-width: $breakpoint-550) {
            margin: 0.094rem;
        }
    }
}

.view-header {
    width: 88%;
    height: 3.125rem;
    max-width: 52rem;
    order: -1;
    display: flex;
    justify-self: center;
    background-color: $color-gold-opacity-2;
    margin-bottom: 0.563rem;
    border-radius: 0.938rem;

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }

    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
    }

    @media screen and (max-width: $breakpoint-550) {
        width: 100%;
        margin-bottom: 0;
        border-radius: 0;
    }

    &__container {
        display: flex;
        max-width: 52rem;
        width: 93%;
        align-items: center;
        margin: auto;

        @media screen and (max-width: $breakpoint-550) {
            width: 90%;
        }
    }
    &__text {
        width: 95%;
        font-family: $font-family-inter;
        font-weight: 700;
        color: $color-gold;
        font-size: $font14;
        cursor: default;
    }
    &__icon {
        display: flex;
        justify-content: flex-end;
        width: 8%;
        cursor: pointer;
    }
}
