@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.donation-guidelines {
    width: 52rem;
    height: 38rem;
    @media screen and (max-width: $breakpoint-1439) {
        width: 48rem;
    }

    @media screen and (max-width: $breakpoint-1200) {
        width: 42rem;
        height: 33rem;
    }

    @media screen and (max-width: $breakpoint-819) {
        width: 40rem;
        height: 31rem;
    }
    @media screen and (max-width: $breakpoint-819) {
        width: 40rem;
        height: 31rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 100vh;
    }

    &__title-container {
        display: flex;
        height: 5rem;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: $font22;
        font-family: $font-family-inter;
        color: $color-blue;
        font-weight: 700;
        margin-left: 3.375rem;
    }

    &__icon {
        @include icon();
        margin-right: 1rem;
    }

    &__text {
        margin-top: 0.5rem;
    }
    &__content {
        overflow-y: scroll;
        height: 80%;
        width: 97%;
        margin: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
