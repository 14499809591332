@font-face {
    font-family: "Caudex";
    font-style: normal;
    font-weight: 400;
    src: url("../../../public/fonts/caudex-v15-latin-regular.eot");
    src: local(""), url("../../../public/fonts/caudex-v15-latin-regular.woff") format("woff"),
        url("../../../public/fonts/caudex-v15-latin-regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: url("../../../public/fonts/inter-v12-latin-regular.eot");
    src: local(""), url("../../../public/fonts/inter-v12-latin-regular.woff") format("woff"),
        url("../../../public/fonts/inter-v12-latin-regular.ttf") format("truetype");
}
