@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.chat-screen {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 56rem;

    @media screen and (max-width: $breakpoint-1023) {
        height: 94vh;
    }
}

.chat-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 65vh;
    max-width: 108rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;
    ;

    @media screen and (max-width: $breakpoint-1023) {
        height: 100%;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    &__no-chat {
        text-align: center;
        width: 100%;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        margin-top: 1rem;
    }
    
    &__center {
        cursor: default;
        display: flex;
        justify-content: center;
    }

    &__left-panel {
        width: 25%;
        max-width: 22.6rem;
        min-width: 17rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        &--visible {
            @media screen and (max-width: $breakpoint-1350) {
                display:flex !important;
                max-width: 100% !important;
                width: 100% !important;
            }
            
        }

        @media screen and (max-width: $breakpoint-1350) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__header {
        width: 100%;
        flex: 0 0 100%;
        height: 4rem;
        flex-direction: column;
    }

    &__no-access {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__left-panel-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 7%;
        box-sizing: border-box;
        height: 100%;

        @media screen and (max-width: $breakpoint-1023) {
            padding-top: 5%;
        }
    }

    &__left-panel-member {
        height: 100%;
        padding-top: 9%;
        box-sizing: border-box;
        overflow: hidden;
        border-bottom-left-radius: 0.9rem;
    }

    &__left-panel-button {
        max-width: 8.156rem;
        width: 100%;
        margin-bottom: 1.25rem;
    }

    &__left-panel-button-content {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        font-size: $font16;
        font-weight: 700;
        display: flex;
        align-items: center;

        @media screen and (max-width: $breakpoint-1350) {
            font-size: $font12;
        }
    }

    &__left-panel-button-icon {
        margin-right: 0.3rem;
    }

    &__right-panel {
        width: 80%;
        height: 100%;
        border-left: solid 0.03rem $color-grey-c4;
        display: flex;
        flex-direction: column;
        
        &--invisible {
            @media screen and (max-width: $breakpoint-1350) {
                display:none !important;
            }
        }

        @media screen and (max-width: $breakpoint-1350) {
            width: 100%;
            border-left: 0;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
        }
    }

    &__right-panel-header {
        width: 100%;
        min-height: 3.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        &--join-button {
            @media screen and (max-width: $breakpoint-768) {
                height: auto;
                max-height: 8.5rem;
            }
        }
    }

    &__close-icon {
        position: absolute;
        top: 0.7rem;
        right: 1rem;

        @include icon();
    }

    &__right-panel-content {
        width: 100%;
        //height: 82%;
        height: 69vh;
        display: flex;
        //flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        border-top: solid 0.03rem $color-grey-c4;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &--overflow-hiden {
            overflow: hidden;
        }
    }

    &__right-panel-input-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-height: 78%;
        border-top: solid 0.03rem $color-grey-c4;

        @media screen and (max-width: $breakpoint-500) {
            align-items: flex-end;
        }
    }

    &__right-panel-input-no-access {
        width: calc(100% - 1rem);
        height: 9%;
        min-height: 4.5rem;
        display: flex;
        align-items: center;
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        margin-left: 1rem;
    }

    &__photo-text-container {
        width: 78%;
    }

    &__error-message {
        color: $color-red;
        margin-left: 2rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font12;

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 0.5rem;
            font-size: $font10;
        }
    }

    &__right-panel-input {
        width: 100%;
        height: 2rem;
        margin-left: 2rem;
        margin-right: 1rem;
        outline: none;
        border: none;
        resize: none;
        font-family: $font-family-inter;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        max-height: 18rem;

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    &__right-panel-input::-webkit-scrollbar {
        display: none;
        width: none;
        scrollbar-width: 0;
        -ms-overflow-style: none;
    }

    &__right-panel-icons-container {
        display: flex;
        flex-direction: row;
        margin-right: 1rem;
        position: relative;

        @media screen and (max-width: $breakpoint-420) {
            margin-right: 0.5rem;
        }
    }

    &__msg-container {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    &__msg-container::-webkit-scrollbar {
        display: none;
        width: none;
        scrollbar-width: 0;
        -ms-overflow-style: none;
    }

    &__right-panel-icon {
        @include icon();

        &--disable {
            &:hover {
                background-color: transparent;
                cursor: initial;
            }
        }

        @media screen and (max-width: $breakpoint-1023) {
            min-width: 2.5rem;
            min-height: 2.5rem;
            max-width: 2.5rem;
            max-height: 2.5rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            min-width: 2rem;
            min-height: 2rem;
            max-width: 2rem;
            max-height: 2rem;
        }

        position: relative;
    }

    &__input-file {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        top: 0;
        right: 0;
        opacity: 0;
    }

    @include text-area-image();

    &__emoji-picker {
        position: absolute;
        bottom: 3.5rem;
        right: 3rem;
        z-index: $z-index-third-layer;

        @media screen and (max-width: $breakpoint-420) {
            right: 0;
        }

        margin-right: 0.25rem;
    }

    &__back-icon-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 98%;
        height: 3.5rem;
        margin-left: 1rem;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            filter: $hover-brightness-darker;
        }
    }

    &__back-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 2rem;
        margin-right: 0.5rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-dark-grey;
    }

    &__no-post {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: $font16;
        font-family: $font-family-inter;
        color: $color-mid-dark-grey;
    }

    &__document-container {
        width: max-content;
        display: flex;
        border-radius: 0.625rem;
        margin-left: 2rem;

        @media screen and (max-width: $breakpoint-1350) {
            margin-left: 0.9rem;
        }

        &--edit-post {
            margin-left: 0.6rem;
        }
    }

    &__document-image {
        height: 3rem;
        width: 3rem;
        border: 2px solid $color-blue;
        box-shadow: 0 0 0.313rem rgba(0, 0, 0, 0.15);
        border-radius: 0.625rem;

        @media screen and (max-width: $breakpoint-768) {
            height: 2.5rem;
            width: 2.5rem;
        }

        @media screen and (max-width: $breakpoint-500) {
            height: 2rem;
            width: 2rem;
        }
    }

    &__document-title {
        color: $color-dark-blue;
        font-family: $font-family-inter;
        font-weight: 600;
        font-size: $font16;

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-500) {
            font-size: $font12;
        }
    }

    &__document-content {
        height: 100%;
        padding-left: 0.4rem;
        margin: auto;
    }

    &__document-size {
        color: $color-dark-grey;
        font-family: $font-family-inter;
        font-size: $font12;

        @media screen and (max-width: $breakpoint-500) {
            font-size: $font11;
        }
    }

    &__document-cancel {
        width: 0.8rem;
        height: 0.8rem;
        display: flex;
        margin-left: 0.2rem;

        &:hover {
            filter: $hover-brightness;
            cursor: pointer;
        }
    }

    &__search-container-outer {
        width: 100%;
        border-bottom: solid 0.03rem $color-grey-c4;
    }

    &__search-container {
        width: 90%;
        margin-left: 5%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__chatlist {
        width: 100%;
        overflow: auto;
        border-radius: 0;
    }
}

textarea:focus {
    .footer {
        display: none;
    }
}

.send-icon {
    height: 1.813rem;
    width: 1.75rem;

    @media screen and (max-width: $breakpoint-1023) {
        height: 1.5rem;
        width: 1.5rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        height: 1.25rem;
        width: 1.25rem;
    }

    &--green {
        fill: $color-blue;
    }

    &--grey {
        fill: $color-mid-dark-grey;
    }
}

.file-icon {
    width: 1.563ren;
    height: 1.5rem;

    @media screen and (max-width: $breakpoint-1023) {
        width: 1.25rem;
        height: 1.188rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        height: 1.063rem;
        width: 1.063rem;
    }
}

.smile-icon {
    width: 1.625rem;
    height: 1.625rem;

    @media screen and (max-width: $breakpoint-1023) {
        height: 1.313rem;
        width: 1.25rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        height: 1.063rem;
        width: 1.063rem;
    }
}

.chat-header-text {
    color: $color-light-blue;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: Inter;
    word-break: break-word;
    margin-left: 5%;
    width: 95%;
}

.chat-status-dot {
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;

    &__online {
        background-color: #89bcb9;
    }

    &__offline {
        background-color: red;
    }
  }

.chat-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 3.5rem;
    align-items: center;
    padding-top: 0.6rem;
}

.chat-header {
    min-height: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem;
    width: calc(100% - 1rem);
    position: relative;
    flex-wrap: wrap;

    @media screen and (min-width: $breakpoint-1024) {
        margin-top: 0;
    }

    &__back-to-list {
        display: none;

        @media screen and (max-width: $breakpoint-1350) {
            display: flex;
            margin-right: 1rem;
        }
    }

    &__notice {
        width: 100%;
        height: 2rem;
        border-radius: $border-radius-09;
        background-color: $color-blue-CD;
        border: solid 0.03rem $color-blue-54;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 0.875rem;
        font-family: Inter;
        color: #375558;
        white-space: pre-wrap;
    }

    &__chat-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;
        height: 100%;
    }

    &__dropdown-overlay {
        &--breakpoint-768 {
            @media screen and (max-width: $breakpoint-768) {
                position: fixed;
                top: 8.8rem;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color-overlay-07;
                z-index: $z-index-first-layer;
            }
        }

        &--breakpoint-550 {
            @media screen and (max-width: $breakpoint-550) {
                position: fixed;
                top: 8rem;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color-overlay-07;
                z-index: $z-index-first-layer;
            }
        }
    }

    &__rooms-dropdown-container {
        position: relative;
    }

    &__arrow-icon {
        @include icon;

        transition: all ease-in-out 200ms;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        &--selected {
            transform: rotate(180deg);
        }

        @media screen and (max-width: $breakpoint-1024) {
            min-width: 2.5rem;
            min-height: 2.5rem;
            max-width: 2.5rem;
            max-height: 2.5rem;
        }
    }

    &__name-container {
        max-width: 43rem;
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-1400) {
            max-width: 25rem;
        }

        @media screen and (max-width: $breakpoint-819) {
            max-width: 16rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            max-width: 13rem;
        }

        @media screen and (max-width: $breakpoint-389) {
            max-width: 10rem;
        }
    }

    &__name {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;
        word-break: break-word;
        cursor: default;

        @media screen and (max-width: $breakpoint-1400) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-900) {
            word-break: break-word;
        }

        @media screen and (max-width: $breakpoint-830) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-500) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-340) {
            font-size: $font12;
        }

        &--margin-left-75 {
            margin-left: 0.75rem;
        }
    }

    &__type {
        font-family: $font-family-inter;
        font-size: $font10;
        font-weight: 400;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.125rem;
        height: 1.563rem;
        border-radius: $border-radius-09;
        user-select: none;
        margin-left: 0.75rem;
        margin-right: 0.75rem;

        &--background-color-blue {
            background-color: $color-blue-opacity-04;
        }

        &--background-color-gold {
            background-color: $color-gold-opacity-65;
        }

        @media screen and (max-width: $breakpoint-420) {
            display: none;
        }
    }

    &__edit-icon {
        @include icon;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        @media screen and (max-width: $breakpoint-1024) {
            min-width: 2.5rem;
            min-height: 2.5rem;
            max-width: 2.5rem;
            max-height: 2.5rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__menu-icon {
        @include icon;
        min-width: 3rem;
        min-height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
        margin-right: 1rem;
        margin-right: 1rem;

        @media screen and (min-width: $breakpoint-769) {
            display: none;
        }
    }

    &__menu {
        justify-content: flex-end;
        display: flex;

        @media screen and (min-width: $breakpoint-769) {
            display: none;
        }
    }

    &__button {
        width: 3rem;
        height: 2.25rem;

        &--margin-right-3 {
            margin-right: 3rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__button-icon {
        margin-right: 0.5rem;
        margin-bottom: -0.1rem;
    }

    &__button-container {
        display: flex;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__lower-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 3rem;
        cursor: pointer;
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-white;
        font-weight: 700;

        &--zindex-2 {
            z-index: 2;
        }

        &:hover {
            filter: $hover-brightness;
        }

        &--color-gold {
            background-color: $color-gold;
        }

        &--color-blue {
            background-color: $color-light-blue;

            &:hover {
                filter: none;
            }

            cursor: default;
        }

        &--display-none {
            display: none;
        }

        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
    }

    &__small-button-container {
        display: flex;

        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
    }

    &__small-button {
        width: 7.5rem;
        height: 2.4rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 6rem;
            height: 2.2rem;
        }

        &--margin-right {
            margin-right: 0.4rem;
        }
    }

    &__icon {
        margin-right: 0.5rem;
    }
}

.chat-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    &__comment-section {
        width: 85%;
        margin-bottom: 2rem;
        margin-left: 3rem;

        @media screen and (max-width: $breakpoint-1023) {
            width: 90%;
        }

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1.5rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 0.75rem;
        }
    }
}

.chat-message-text {
    @media screen and (max-width: $breakpoint-1024) {
        margin-left: 0.9rem;
        width: 94%;
    }

    @media screen and (max-width: $breakpoint-768) {
        margin-left: 1.2rem;
        width: 95%;
    }

    @media screen and (max-width: $breakpoint-600) {
        margin-left: 0.9rem;
        width: 94%;
    }

    --r: 25px;
    --t: 20px;

    padding: calc(2*var(--r)/3);
    -webkit-mask:
        radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100%/calc(100% - var(--r)) var(--t) no-repeat,
        conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r)/-2) calc(var(--r)/-2) padding-box,
        radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0/var(--r) var(--r) space padding-box;
    background-color: $color-grey;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: $font14;
    width: 95%;

    &__left {
        --_d: 0%;
        border-left: var(--t) solid #0000;
        margin-right: var(--t);
        place-self: start;
        min-width: 1.25rem;
    }

    &__right {
        --_d: 100%;
        border-right: var(--t) solid #0000;
        margin-left: var(--t);
        place-self: end;
        min-width: 1.25rem;;
    }
}

.chat-message {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--is_pinned {
        background-color: rgba(245, 251, 252, 1);
    }

    &__break {
        flex-basis: 100%;
        height: 0;
      }

    &__date {
        flex: 0 0 100%;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 0.25rem;
        font-family: $font-family-inter;
        font-size: $font11;
        font-weight: 500;
        color: $color-grey-c4;
    }



    &__left-panel {
        width: 80%;
        height: 100%;

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
        }
    }

    &__right-panel {
        width: 80%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
        }
    }

    &__all-reactions-text {
        padding-left: 0.5rem;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-blue;
        padding-top: 0.3rem;
        cursor: pointer;
    }

    &__emoji-image {
        width: 1.125rem;
        height: 1.125rem;
    }

    &__emoji {
        display: flex;
        background: $color-grey;
        border: 1.59px solid $color-mid-dark-grey;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
        margin-left: -0.4rem;

        @media screen and (max-width: $breakpoint-500) {
            margin-left: -0.47rem;
        }
    }

    &__profile-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__user-data-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__time-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 0.7rem;
    }

    &__profile-photo {
        margin-left: 1rem;
        margin-right: 0.75rem;

        @media screen and (max-width: $breakpoint-360) {
            margin-right: 0.5rem;
        }

        &:hover {
            cursor: pointer;
        }

        &--no-cursor {
            &:hover {
                cursor: default;
            }
        }
    }

    &__username-container {
        max-width: 100%;
        margin-right: 1rem;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;

        &--organization,
        &--admin {
            opacity: 0.8;
            color: white;
            padding-bottom: 0.05rem;
            text-align: center;
            @include role-room();
        }

        &--organization {
            background-color: #e09f53;
            border-radius: 0.9rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &--admin {
            background-color: #d57467;
            padding-left: 1.125rem;
            padding-right: 1.125rem;
            border-radius: 1.25rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            font-size: 0.75rem;
            word-break: break-all;
        }

        @media screen and (max-width: $breakpoint-360) {
            margin-right: 0.3rem;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
        }

        &:hover {
            cursor: pointer;
        }

        &--deleted {
            color: $color-grey-c4;

            &:hover {
                cursor: default;
            }
        }

        @media screen and (min-width: $breakpoint-1199) {
            min-inline-size: max-content;
        }

        @media only screen and (max-width: $breakpoint-1199) and (min-width: $breakpoint-768) {
            min-inline-size: fit-content;
        }
    }

    &__time {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-grey-c4;
        margin-right: 0.75rem;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        @media screen and (min-width: $breakpoint-768) {
            min-inline-size: fit-content;
        }

        @media screen and (max-width: $breakpoint-1023) {
            min-inline-size: fit-content;
            justify-content: flex-end;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
            justify-content: flex-end;
        }

        @media screen and (max-width: $breakpoint-550) {
            min-inline-size: auto;
        }

        @media screen and (max-width: $breakpoint-340) {
            font-size: $font12;
        }
    }

    &__small-date {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        display: flex;
        justify-content: flex-end;
        padding-right: 0.3rem;

        @media screen and (min-width: $breakpoint-1024) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-350) {
            padding-right: 0.9rem;
        }
    }

    &__post-content {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.7rem;
        min-width: 25%;
        max-width: 90%;

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 1rem;
            margin-top: 0.4rem;
        }

        &--direction-row {
            flex-direction: column;
        }

        &__right {
            margin-right: 0.5rem;
            align-items: flex-end;
        }

        &__left {
            margin-left: 0.5rem;
            align-items: flex-start;
        }
    }

    &__post-text-container {
        max-width: 100%;
        min-height: 1.75rem;

        @media screen and (max-width: $breakpoint-420) {
            max-width: 95%;
        }
    }

    .button-textPreview-more,
    .button-textPreview-less {
        color: #60a49f;
    }

    .button-textPreview-more,
    .button-textPreview-less {
        padding-top: 0.5rem;

        @media screen and (max-width: $breakpoint-550) {
            padding-bottom: 0.5rem;
        }
    }

    .truncated {
        max-height: 6.5rem;
        margin: 0 auto;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        width: 100%;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-1763) {
            display: -webkit-box;
        }

        @media screen and (max-width: $breakpoint-550) {
            max-height: 6.6rem;
            display: -webkit-inline-box;
        }
    }

    &__post-text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        margin-top: -0.188rem;
        word-break: break-word;
        margin-bottom: 0.7rem;
        display: flex;
    }

    @media screen and (max-width: $breakpoint-360) {
        font-size: $font10;
    }

    &__post-text a {
        color: $color-blue;
    }

    &__event-image-container {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-1199) {
            margin-bottom: 0.7rem;
        }
    }

    &__event-image {
        width: 20rem;
        height: 9rem;
        object-fit: cover;
        cursor: pointer;
        border-radius: 0.563rem;

        @media screen and (max-width: $breakpoint-1400) {
            width: 16rem;
            height: 8rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 18rem;
            height: 8rem;
        }
    }

    &__post-image {
        cursor: pointer;
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
        max-width: 40%;
        max-height: 50%;
        border-radius: 0.563rem;
    }

    &__post-image-container {
        display: flex;
        margin-top: 0.75rem;
    }

    &__reactions-container {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        align-content: center;
        position: relative;
        margin-left: 4.7rem;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 0rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 1rem;
        }
    }

    &__add-emoji-icon {
        cursor: pointer;
        align-self: center;
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
    }

    &__emoji-picker {
        z-index: $z-index-third-layer;
    }

    &__reaction {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &--no-margin {
            margin-right: 0;
        }
    }

    &__reaction-plus {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-grey-c4;
        margin-right: 0.5rem;
        margin-left: 0.25rem;

        &:hover {
            cursor: pointer;
            font-weight: 700;
        }
    }

    &__reaction-count {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-grey-c4;
        margin-left: 0.25rem;
        display: flex;
        align-items: center;

        @media screen and (max-width: $breakpoint-420) {
            font-size: $font12;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__right-panel-content {
        margin-right: 1rem;

        &--height-5 {
            height: 5rem;

            @media screen and (max-width: $breakpoint-1500) {
                height: 6.5rem;
            }
        }

        &--no-height {
            height: max-content;
        }
    }

    &__date-container {
        background-color: $color-grey;
        border-radius: $border-radius-09;
        width: fit-content;
    }

    &__comments-actions-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 2.75rem;
        width: fit-content;
        margin-bottom: 1rem;
        cursor: pointer;

        &--display-none {
            @media screen and (min-width: $breakpoint-1024) {
                display: none;
            }
        }

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 0rem;
            align-items: center;
        }
    }

    &__comments-count {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-blue;
        padding-top: 0.4rem;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1023) {
            padding-top: 0rem;
        }

        &:hover {
            filter: $hover-brightness;
        }
    }

    &__comment-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-grey;
        border-radius: $border-radius-09;
        height: 2rem;
        width: 3rem;
        margin-left: 0.75rem;
        cursor: pointer;

        &:hover {
            filter: $hover-brightness;
        }

        @media screen and (max-width: $breakpoint-360) {
            margin-left: 0rem;
            width: 2rem;
        }

        &--background-transparent {
            background-color: transparent;
        }
    }

    &__actions-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 20rem;

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
        }
    }
}