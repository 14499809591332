@import "../abstracts/mixins/icon-mixins.scss";

@mixin mobile-icon-size {
    @media screen and (max-width: $breakpoint-1500) {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.heartIcon {
    padding-right: 0.94rem;
}

.plusIcon {
    padding-right: 0.625rem;

    @media screen and (max-width: $breakpoint-1400) {
        padding-right: 0.25rem;
    }
}

.left-arrow {
    margin-right: 0.5rem;
}

.bookmarkIconSmall {
    padding-right: 0.5rem;
}

.chatIcon {
    padding-right: 0.625rem;
}

.bookmarkIcon {
    padding-right: 0.625rem;
}

.button-chat-icon {
    padding-right: 0.625rem;
}

.camera-icon {
    &--green {
        fill: $color-blue;
    }

    &--blue {
        fill: $color-darker-blue;
    }

    &--disable {
        fill: $color-mid-dark-grey;
    }

    @media screen and (max-width: $breakpoint-550) {
        width: 1.438rem;
        height: 1.125rem;
    }
}

.loading-animation {
    position: absolute;
    width: 7.25rem;
    height: 7.25rem;
}

.loading-animation-dropdown {
    position: absolute;
    top: -1rem;
    width: 7.25rem;
    height: 6.25rem;

    @media screen and (max-width: 1919px) {
        top: -1.5rem;
    }
}

.loading-animation-cover {
    display: flex;
    align-items: center;
    margin: auto;
    width: 9.25rem;
    height: 9.25rem;
}

.loading-animation-rooms {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    width: 9.25rem;
    height: 9.25rem;
}

.loading-animation-profile {
    display: flex;
    align-self: center;
    margin: auto;
    width: 6.25rem;
    height: 6.25rem;
}

.loading-animation-post {
    margin: auto;
    width: 6.25rem;
    height: 6.25rem;
}

.add-user-icon {
    margin-right: 0.3rem;
}

.edit-profile-icon {
    @include mobile-icon-size();

    &--small {
        width: 1.306rem;
        height: 1.385rem;
    }
}

.close-modal-icon {
    @media screen and (max-width: $breakpoint-1023) {
        width: 0.875rem;
        height: 0.875rem;
    }
}

.comment-icon {
    @include mobile-icon-size();
    @media screen and (max-width: $breakpoint-1023) {
        width: 1.625rem;
        height: 1.625rem;
    }
}

.copy-icon {
    @include mobile-icon-size();
    @media screen and (max-width: $breakpoint-1023) {
        width: 1.625rem;
        height: 1.625rem;
    }
}

.share-icon {
    &--size-small {
        width: 1.25rem;
        height: 1.25rem;
    }

    &--size-big {
        width: 1.438rem;
        height: 1.564rem;

        @media screen and (max-width: $breakpoint-1500) {
            width: 1.25rem;
            height: 1.25rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 1.438rem;
            height: 1.564rem;
        }
    }

    &--grey {
        stroke: $color-dark-grey;
    }

    &--white {
        stroke: $color-white;
    }

    &--blue {
        stroke: $color-blue-54;
    }
}

.invite-user-icon {
    &--gold {
        fill: $color-gold;
    }

    &--white {
        fill: $color-white;
    }
}

.pin-icon {
    &--green {
        fill: $color-blue;
    }

    &--margin-left {
        margin-left: 0.3rem;

        @media screen and (max-width: $breakpoint-360) {
            width: 1rem;
        }
    }

    @media screen and (max-width: $breakpoint-350) {
        margin-left: 0.1rem;
        width: 1rem;
    }

    &--grey {
        fill: $color-mid-dark-grey;
    }
}

.instagram-icon :hover {
    cursor: pointer;
    fill: $color-instagram-purple;
}

.search-icon {
    &--grey {
        fill: $color-grey-c4;
    }

    &--dark-blue {
        width: 1.25rem;
        height: 1.25rem;
        fill: $color-darker-blue;
    }
}

.members-icon {
    width: 36px;
    height: 26px;
    &--all-rooms {
        fill: $color-gold;
        width: 24px;
        height: 14px;
    }
}

.alert-circle {
    position: absolute;
    top: -0.313rem;
    right: -0.313rem;
}
