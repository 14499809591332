@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.settings-modal {
    display: flex;
    flex-direction: row;
    width: 57rem;
    height: 42rem;
    min-width: 42rem;
    background-color: $color-white;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.3rem $box-shadow-05;

    @media screen and (max-width: $breakpoint-1919) {
        width: 50rem;
    }

    @media screen and (max-width: $breakpoint-1439) {
        height: 34rem;
    }

    @media screen and (max-width: $breakpoint-1200) {
        width: 46.5rem;
        height: 33rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        min-width: 20rem;
        border-radius: 0rem;
        flex-direction: column;
    }

    &__mobile-settings {
        overflow: auto;
        height: 85%;
        padding-top: 2rem;

        @media screen and (min-width: 769px) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-420) {
            height: 61%;
        }

        &--height-none {
            height: 0;
            overflow: inherit;
            @media screen and (max-width: 23.438rem) {
                height: 0;
            }
        }
    }

    &__mobile-close-icon-container {
        display: flex;
        justify-content: flex-end;
        @media screen and (min-width: $breakpoint-769) {
            display: none;
        }
    }

    &__mobile-close-icon {
        @include icon();
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        &:hover {
            background-color: $color-grey;
        }
    }
}

.settings-left-panel {
    width: 25%;
    display: flex;
    flex-direction: column;
    border-right: solid 0.03rem $color-grey;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
    }

    &__title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 4.5rem;
        border-bottom: solid 0.03rem $color-grey;
        font-family: $font-family-inter;
        font-size: 1.375rem;
        font-weight: 700;
        color: $color-blue;
        background-color: $color-white;
        margin-top: 1.5rem;

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 3.75rem;
        border-bottom: solid 0.03rem $color-grey;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 500;
        color: $color-darker-blue;
        background-color: $color-white;
        cursor: pointer;

        &:hover {
            filter: brightness(0.95);
            @media screen and (max-width: $breakpoint-768) {
                filter: none;
            }
        }

        &--align-start {
            @media screen and (max-width: $breakpoint-768) {
                align-items: inherit;
            }
        }

        @media screen and (max-width: $breakpoint-1200) {
            max-width: 99%;
        }

        @media screen and (max-width: $breakpoint-768) {
            justify-content: center;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        position: absolute;
        left: 1rem;
        @media screen and (max-width: $breakpoint-768) {
            align-items: inherit;
        }
    }
}

.settings-text {
    margin-left: 15%;

    @media screen and (max-width: $breakpoint-768) {
        margin-left: 0rem;
    }
}

.settings-right-panel {
    width: 75%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-768) {
        display: none;
    }
    &--mobile {
        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            width: 100%;
            height: 100vh;
        }
    }

    &__settings-privacy-loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 93%;
        @media screen and (max-width: $breakpoint-768) {
            height: 20.938rem;
        }
    }

    &__settings-notifications-loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 46vh;
        @media screen and (max-width: $breakpoint-768) {
            height: 20.938rem;
        }
    }

    &__close-settings {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__settings {
        height: 82%;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }

        &--mobile {
            @media screen and (max-width: $breakpoint-768) {
                height: 77%;
                display: block;
            }
        }
    }
}

.close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    background-color: transparent;
    margin-right: 1.5rem;

    &:hover {
        background-color: $color-grey;
    }
}

.text-position {
    padding-left: 18%;

    @media screen and (max-width: $breakpoint-768) {
        padding-left: 1%;
    }
}
