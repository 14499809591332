@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.edit-post {
    @include text-area-image();

    &--background-colour {
        background-color: rgba(247, 247, 247, 0.75);
        width: 100%;
    }

    width: 97%;
    margin: auto;
    display: flex;

    &__profile-photo {
        margin-left: 1rem;
        margin-right: 0.75rem;
        margin-top: 0.488rem;
        margin-bottom: 0.488rem;
    }
    &__container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 90%;
        padding: 1.25rem;
        border: 0.063rem solid #c4c4c4;
        border-radius: 0.313rem;
        margin-top: 0.488rem;
        margin-bottom: 0.488rem;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-768) {
            display: block;
        }

        @media screen and (max-width: $breakpoint-350) {
            padding: 0.6rem;
        }
    }

    &__text-area {
        width: 90%;
        resize: none;
        outline: none;
        border: none;
        overflow: visible;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font14;
        color: $color-darker-blue;

        &--background-colour {
            background-color: rgba(247, 247, 247, 0.75);
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
            font-size: 0.75rem;
        }
    }

    &__text-area::-webkit-scrollbar {
        display: none;
        width: none;
        scrollbar-width: 0;
        -ms-overflow-style: none;
    }

    &__button {
        width: 7.783rem;
        height: 2.501rem;
        margin-left: 0.55rem;

        @media screen and (max-width: $breakpoint-1199) {
            width: 6rem;
            height: 2rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            font-size: 0.75rem;
        }
    }

    &__button-container {
        display: flex;

        @media screen and (max-width: $breakpoint-768) {
            justify-content: end;
        }
    }
}
