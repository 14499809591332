@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.user-profile-description-edit-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 40rem;
    height: 25rem;
    min-width: 20rem;
    background-color: none;
    border-radius: $border-radius-09;
    box-shadow: $box-shadow-overlay;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        box-shadow: none;
    }

    &__title-container {
        display: flex;
        justify-content: space-around;
        width: 95%;
        align-items: center;
    }

    &__close-icon {
        @include icon();
        margin: 1rem;
    }

    &__content {
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 85%;

        @media screen and (max-width: $breakpoint-768) {
            margin-top: 3.5rem;
        }
    }

    &__title {
        width: 100%;
        font-family: $font-family-inter;
        color: $color-red;
        font-size: $font16;
        font-weight: 700;
    }

    &__input {
        width: 90%;
        min-height: 7rem;
        max-height: 11.5rem;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 500;
        color: $color-darker-blue;
        resize: none;
        border: none;
        outline: none;
        width: 93%;

        @media screen and (max-width: $breakpoint-768) {
            width: 97%;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font14;
        }
    }

    &__max-character-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: max-content;
        margin-bottom: 1rem;
        width: 100%;
        padding-right: 1.5rem;
        box-sizing: border-box;
    }

    &__button-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 3rem;
        width: 85%;
        margin-right: 1.5rem;
    }
    &__button {
        width: 7rem;
        height: 2.3rem;
    }
}
