.character-counter {
    font-size: $font12;
    font-family: $font-family-inter;
    font-weight: 400;
    color: $color-dark-blue;

    &--margin-right {
        margin-right: 0.563rem;
    }
    
    &--margin-left {
        margin-left: 0.5rem;
    }

    &--post {
        display: flex;
        justify-content: flex-end;
        align-items: end;
        height: 100%;
        @media screen and (max-width: $breakpoint-500) {
            font-size: $font10;
            height: inherit;
        }
    }

    &--feed {
        width: 5rem;

        @media screen and (max-width: $breakpoint-500) {
            margin-bottom: 0.75rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            margin-bottom: 0.5rem;
        }
    }

    &--room-description {
        display: flex;
        justify-content: flex-end;
        align-items: end;
    }
}
