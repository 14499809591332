@import "../variables.scss";

//room-modal

@mixin room-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        box-shadow: none;
    }

    &__close-icon {
        @include icon;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
    }

    &__text {
        height: fit-content;
        font-size: $font18;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-weight: 700;

        &--gold {
            color: $color-gold;
            margin-left: 0.2rem;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        &--blue {
            color: $color-blue;
        }
    }
}

//remove-modal

@mixin remove-modal {
    width: 40rem;
    font-family: $font-family-inter;
    font-size: $font18;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $breakpoint-1350) {
        width: 35rem;
        font-size: $font16;
    }
    @media screen and (max-width: $breakpoint-1200) {
        width: 30rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-top: 1.5rem;
        padding-bottom: 0.5rem;
        font-weight: 700;
        align-items: center;
        color: $color-red;
        width: 90%;
        border-bottom: $bottom-border;

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
        }
    }

    &__close-icon {
        @include icon;

        &:hover {
            background-color: $color-grey;
            cursor: pointer;
        }
    }

    &__content {
        margin-top: 2.625rem;
        margin-bottom: 2.625rem;
        align-self: center;
        text-align: center;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    &__button {
        margin-bottom: 1.813rem;
        width: 7.5rem;
        height: 2.3rem;
    }
}

//link modal

@mixin link-modal {
    width: 50rem;
    padding-top: 1.5rem;
    font-family: $font-family-inter;

    @media screen and (max-width: $breakpoint-1350) {
        width: 45rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 100%;
    }

    &__title {
        font-size: $font22;
        display: flex;
        justify-content: space-between;
        font-family: $font-family-inter;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        box-sizing: border-box;
        margin: auto;
        padding-bottom: 0.5rem;
        font-weight: 700;
        align-items: center;
        color: $color-red;
        width: 90%;

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
        }
    }

    &__close-icon {
        @include icon;

        &:hover {
            background-color: $color-grey;
            cursor: pointer;
        }
    }

    &__link-area {
        cursor: auto;
        color: $color-blue;
        background-color: $color-grey;
        max-width: 32.882rem;
        width: 100%;
        height: 2.813rem;
        border-radius: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $breakpoint-1350) {
            max-width: 27.882rem;
        }
    }

    &__link-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0.8rem;
        padding-right: 0.5rem;
    }

    &__button {
        width: 100%;
        max-width: 8.75rem;
        height: 2.813rem;
        padding-left: 1.625rem;
        box-sizing: border-box;

        @media screen and (max-width: $breakpoint-600) {
            margin-top: 1rem;
            padding-left: 0;
        }
    }

    &__container {
        display: flex;
        justify-content: center;
        padding-top: 2.938rem;
        padding-bottom: 3.625rem;
        box-sizing: border-box;

        @media screen and (max-width: $breakpoint-600) {
            flex-direction: column;
            align-items: flex-end;
            width: 90%;
            margin: auto;
        }
    }
}

//photo-modal

@mixin modal {
    background-color: $color-white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-index-forth-layer;
    border-radius: $border-radius-09;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin profile-photo-modal {
    width: 25%;

    @media screen and (max-width: $breakpoint-1500) {
        width: 35%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 50%;
    }
    @media screen and (max-width: $breakpoint-550) {
        width: 60%;
        top: 42%;
    }
}

@mixin cover-photo-modal {
    width: 55%;

    @media screen and (max-width: $breakpoint-1023) {
        width: 65%;
    }
    @media screen and (max-width: $breakpoint-550) {
        width: 80%;
        top: 42%;
    }
}
