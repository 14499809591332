.add-admin-modal {
    height: max-content;
    width: 45rem;
    max-height: 29.375rem;
    font-family: "Inter";
    padding: 2rem;
    font-family: $font-family-inter;

    &--static-height {
        height: 29.375rem;
    }

    @media screen and (max-width: $breakpoint-1439) {
        width: 40rem;
    }
    @media screen and (max-width: $breakpoint-1350) {
        width: 35rem;
    }
    @media screen and (max-width: $breakpoint-1200) {
        width: 30rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 92%;
        height: 100vh;
    }

    &__small-text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 400;
        padding-bottom: 1.938rem;
        padding-left: 2rem;
        width: 90%;
    }

    &__text {
        @media screen and (max-width: $breakpoint-768) {
            font-size: $font14;
        }
    }

    &__title-container {
        display: flex;
        justify-content: space-between;

        padding-bottom: 1.938rem;
        align-items: center;
    }

    &__title {
        font-size: $font18;
        font-weight: 700;
        color: $color-blue;
    }

    &__close-icon {
        @include icon;
    }

    &__search-container {
        width: 90%;
        margin: auto;
        padding-bottom: 1.563rem;
    }
}
