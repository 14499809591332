@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.user-profile-description-item {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &__icon {
        position: absolute;
        top: -0.5rem;
        right: -1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        width: 3.5rem;
        height: 3.5rem;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            background-color: $color-grey;
        }

        @media screen and (max-width: $breakpoint-1199) {
            top: -1rem;
            right: -1.25rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            right: -1.75rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            right: -3.5rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            right: -2.5rem;
        }
    }

    &__title {
        width: 100%;
        font-family: $font-family-inter;
        color: $color-red;
        font-size: $font16;
        font-weight: 700;
        margin-bottom: 1.5rem;
        margin-top: 1rem;

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font14;
            width: 85%;
            margin-bottom: 1rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
        }
    }

    &__text {
        width: 100%;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-size: $font14;
        font-weight: 500;
        white-space: normal;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font12;
        }
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
        }
    }
}
