.donation-card {
    width: 80%;
    max-width: 50rem;
    border-radius: $border-radius-09;
    background-color: $color-white;
    box-shadow: $card-box-shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: $breakpoint-1599) {
        width: 95%;
    }

    @media screen and (max-width: $breakpoint-1023) {
        width: 75%;
    }

    @media screen and (max-width: $breakpoint-819) {
        width: 85%;
    }

    @media screen and (max-width: $breakpoint-550) {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    &__content {
        width: 85%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-c4;
            border-radius: $border-radius-09;
        }

        @media screen and (max-width: $breakpoint-550) {
            margin-top: 1rem;
        }
    }

    &__title-container {
        width: 21rem;
        height: 5.5rem;
        margin-bottom: 3.5rem;
        margin-top: 2rem;

        @media screen and (max-width: $breakpoint-768) {
            height: none;
            width: 15rem;
        }
    }

    &__title {
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font22;
        color: $color-light-blue;

        &--font-weight-400 {
            font-weight: 400;
        }
        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font20;
        }

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font16;
        }
    }

    &__subtitle-container {
        width: 13.2rem;
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 0;
        }
    }

    &__subtitle {
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font18;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font16;
        }
    }

    &__checkbox-conatiner {
        display: flex;
        flex-direction: row;
        height: fit-content;
        align-items: center;
        margin-top: 0.5rem;
    }

    &__checkbox {
        margin-right: 0.5rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font12;
        color: $color-darker-blue;

        &--margin-left-1 {
            margin-left: 1rem;
        }
    }

    &__amounts-list-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }

    &__amount-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 6.5rem;
        min-width: 5.25rem;
        max-height: 6.5rem;
        max-width: 5.25rem;
        margin: 1.25rem;

        @media screen and (max-width: $breakpoint-1599) {
            margin: 0.75rem;
            min-height: 5.25rem;
            max-height: 5.25rem;
            max-width: 4.5rem;
            min-width: 4.5rem;
            font-size: $font28;
        }

        @media screen and (max-width: $breakpoint-550) {
            margin: 0.5rem;
            min-height: 4.5rem;
            max-height: 4.5rem;
            max-width: 4rem;
            min-width: 4rem;
            font-size: $font24;
        }
    }

    &__amount-card {
        min-height: 6.15rem;
        min-width: 4.9rem;
        max-height: 6.15rem;
        max-width: 4.9rem;
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid $color-grey-c4 0.05rem;
        border-radius: $border-radius-09;
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font32;
        color: $color-darker-blue;
        cursor: pointer;

        &:hover {
            filter: $hover-brightness-darker;
        }

        @media screen and (max-width: $breakpoint-1599) {
            min-height: 5rem;
            max-height: 5rem;
            max-width: 4.25rem;
            min-width: 4.25rem;
            font-size: $font28;
        }

        @media screen and (max-width: $breakpoint-550) {
            min-height: 4.25rem;
            max-height: 4.25rem;
            max-width: 3.75rem;
            min-width: 3.75rem;
            font-size: $font24;
        }

        &--selected {
            min-height: 6.5rem;
            min-width: 5.25rem;
            max-height: 6.5rem;
            max-width: 5.25rem;
            filter: $hover-brightness-darker !important;

            @media screen and (max-width: $breakpoint-1599) {
                min-height: 5.25rem;
                max-height: 5.25rem;
                max-width: 4.5rem;
                min-width: 4.5rem;
            }

            @media screen and (max-width: $breakpoint-550) {
                min-height: 4.5rem;
                max-height: 4.5rem;
                max-width: 4rem;
                min-width: 4rem;
            }
        }
    }

    &__divider-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 2.25rem;
    }

    &__divider {
        width: 90%;
        height: 0.031rem;
        background-color: $color-grey-c4;
    }

    &__amount-input {
        margin-top: 2rem;
        margin-left: 4rem;
        width: 10rem;
        height: 5rem;
        outline: none;
        border: solid $color-grey-c4 0.05rem;
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font32;
        color: $color-darker-blue;
        border-radius: $border-radius-09;
        text-align: center;

        &::placeholder {
            color: $color-light-grey;
        }

        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        @media screen and (max-width: $breakpoint-1599) {
            width: 8rem;
            height: 4rem;
            font-size: $font28;
        }

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
            width: 7rem;
            height: 3.5rem;
            font-size: $font24;
        }
    }

    &__checkbox-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-top: 2rem;
        margin-left: 2rem;
        max-width: 33.5rem;
        margin-bottom: 2rem;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
        }
    }

    &__link-text {
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font14;
        color: $color-light-blue;
        cursor: pointer;
        margin-top: 1.5rem;
        margin-left: 5rem;

        &:hover {
            filter: $hover-brightness;
        }

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 4rem;
        }

        &--no-left-margin {
            cursor: default;
            margin-left: 0rem;
            &:hover {
                filter: none;
            }
        }

        &--display-none {
            display: none;
        }
    }

    &__button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
    }

    &__button {
        width: 7.25rem;
        height: 2.25rem;
    }

    &__button-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 5.5rem;
        font-family: $font-family-inter;
        font-size: $font12;
        color: $color-white;
        font-weight: 500;
    }

    &__image-container {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;

        @media screen and (max-width: $breakpoint-1599) {
            right: 0.5rem;
            top: 0.5rem;
        }

        @media screen and (max-width: $breakpoint-1279) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-1023) {
            display: flex;
            right: 1rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            top: 1rem;
            right: 1rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            top: 1rem;
            right: -0.7rem;
        }
    }

    &__image {
        display: flex;
        width: 13.688rem;
        height: 13.375rem;

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__small-image {
        display: flex;
        width: 8.313rem;
        height: 8.125rem;

        @media screen and (min-width: $breakpoint-551) {
            display: none;
        }
    }
}
