.app {
    overflow-x: hidden;
    &--background {
        background-image: url("../../../public/Background.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
}

:where(*, *::before, *::after) {
    box-sizing: content-box !important;
}