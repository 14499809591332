@import "../abstracts/variables.scss";

.notifications-dropdown {
    top: 7.25rem;
    position: absolute;
    width: 29rem;
    scrollbar-width: none;
    z-index: $z-index-second-layer;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;
    display: flex;
    align-items: center;

    @media screen and (max-width: $breakpoint-1439) {
        top: 5.85rem;
        width: 25rem;
    }

    @media screen and (max-width: $breakpoint-1099) {
        right: 1rem;
    }

    @media screen and (max-width: $breakpoint-1023) {
        top: 3.8rem;
    }

    &__content {
        width: 100%;
        margin-top: 0.75rem;
        display: flex;
        flex-direction: column;
    }

    &__upper-panel {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: 100%;
        text-align: center;
        margin-top: 4.25rem;
    }

    &__lower-panel {
        height: 10%;
        min-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 0.75rem;
    }

    &__button {
        width: 94%;
        height: 3.125rem;
    }
}
