@import "../abstracts/variables.scss";

.accordion {
    max-width: 100%;

    &__selector {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        height: 5.125rem;
        width: $accordion__selector-width;
        @media screen and (max-width: $breakpoint-1023) {
            width: 18.75rem;
            height: 3rem;
        }
    }

    &__title {
        color: $color-darker-blue;
        font-weight: 700;
        margin: 0;
        bottom: 1.5rem;
        margin-right: 0.25rem;
        font-family: $font-family-inter;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font14;
        }
    }

    &__button {
        margin-left: 1rem;
        bottom: 1.5rem;

        &:hover {
            cursor: pointer;
        }
    }

    &__main-container {
        display: flex;
        flex-direction: column;
    }

    &__container {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 43.75rem;
        justify-content: flex-start;
        height: fit-content;
        margin-bottom: 2rem;

        @media screen and (max-width: $breakpoint-1023) {
            flex-direction: column;
        }
    }

    &__input-container {
        display: flex;
        width: 38rem;

        @media screen and (max-width: $breakpoint-1023) {
            flex-direction: column;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
        }
    }

    &__email-input {
        width: 40%;
        @media screen and (max-width: $breakpoint-1023) {
            width: auto;
        }

        &--margin-left {
            margin-left: 1.5rem;

            @media screen and (max-width: $breakpoint-1023) {
                margin-left: 0;
            }
        }
    }

    &__small-text {
        margin-left: 1.5rem;
        margin-bottom: 0.75rem;
        font-family: $font-family-inter;
        color: $color-dark-blue;
    }

    &__switch-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 2rem;
        margin-bottom: 0.75rem;
        margin-top: 0rem;

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: -1rem;
        }
    }

    &__switch-text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-light-blue;
        margin-left: 1rem;
        transition: all 150ms;

        &--color-red {
            color: $color-red;
        }

        &--padding {
            padding-top: 1rem;
        }
    }

    &__text {
        display: flex;
        padding-left: 1.5rem;
        font-family: $font-family-inter;
        padding-bottom: 0.75rem;
        color: $color-dark-blue;
        @media screen and (max-width: $breakpoint-1023) {
            justify-content: flex-start;
            padding-left: 1rem;
        }
    }
    &__checkbox {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        width: 70%;
        margin-bottom: 0.8rem;
        margin-top: 1.7rem;
        @media screen and (max-width: $breakpoint-1919) {
            margin-bottom: 0;
        }

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 2rem;
            width: 100%;
            margin-left: 0;
        }
    }

    &__checkbox-text {
        font-family: $font-family-inter;
        color: $color-dark-blue;
        margin: 0;
        width: 100%;
        height: 2.813rem;
        padding-left: 1.25rem;

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    &__margin-bottom {
        margin-bottom: 1rem;
    }

    &__error-message {
        width: 77%;
        font-size: 0.688rem;
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        margin-bottom: 1.5rem;
        font-family: $font-family-inter;

        &--representative {
            margin-bottom: 0;
            text-align: center;
            margin-left: 0;
            margin-top: 1.2rem;
            @media screen and (max-width: $breakpoint-1439) {
                margin-top: 3rem;
            }
            @media screen and (max-width: $breakpoint-1279) {
                margin-top: 3rem;
                text-align: start;
                padding-left: 2.188rem;
            }
            @media screen and (max-width: $breakpoint-1023) {
                margin-top: 1.2rem;
                margin-left: 0.625rem;
                padding-left: 0;
            }
            @media screen and (max-width: $breakpoint-819) {
                margin-top: 2.2rem;
            }
            @media screen and (max-width: $breakpoint-350) {
                margin-top: 3.2rem;
            }
        }
    }
}

.tooltipIcon {
    margin-bottom: 1.5rem;
}

.orangeVector {
    @media screen and (max-width: $breakpoint-1023) {
        width: 0.813rem;
        height: 0.438rem;
    }
}
