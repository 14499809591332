.photo-modal {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__photo {
        max-width: 100%;
        min-width: 100%;
        justify-self: center;
        margin: auto;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        max-height: 86vh;
    }
    &__close-modal-container {
        display: flex;
        justify-content: flex-end;
        padding: 1.5%;
        @media screen and (max-width: $breakpoint-768) {
            padding: 1.5%;
        }
    }

    &__close-modal {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.2rem;
        width: 2.2rem;
        border-radius: 50%;
        background-color: transparent;

        &:hover {
            background-color: $color-grey;
            cursor: pointer;
        }
    }
}
