@import "../abstracts/variables.scss";

.newest-members-screen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50rem;

    @media screen and (max-width: $breakpoint-768) {
        height: none;
        position: absolute;
        top: 3.9rem;
    }

    &__title {
        display: flex;
        width: 100%;
        max-width: 50rem;
        margin-left: 2rem;
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-light-blue;
        margin-bottom: 2rem;
        justify-self: flex-start;

        @media screen and (max-width: $breakpoint-1200) {
            font-size: $font18;
        }

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 45rem;
            font-size: $font22;
        }
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 5rem;
        max-height: 40rem;
        max-width: 50rem;
        min-width: 20rem;
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: $card-border-radius;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 45rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            max-width: none;
            border-radius: 0;
            box-shadow: none;
        }
    }

    &__loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-c4;
            border-radius: $border-radius-09;
        }
    }
}
