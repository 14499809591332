.delete-room-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33rem;
    height: 15rem;
    min-width: 20rem;
    background-color: none;
    border-radius: $border-radius-09;
    box-shadow: $box-shadow-overlay;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        box-shadow: none;
    }

    &__content {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__upper-panel {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
    }

    &__title {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-red;
    }

    &__close-icon {
        position: absolute;
        right: -1rem;
        top: -0.3rem;
        @include icon;

        @media screen and (max-width: $breakpoint-768) {
            right: -1.5rem;
            top: -2rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            right: -1rem;
            top: -1.5rem;
        }
    }

    &__divider {
        height: 0.03rem;
        width: 80%;
        background-color: $color-grey-c4;
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 500;
        color: $color-darker-blue;
        text-align: center;
    }

    &__button-container {
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        &--leave-room {
            width: 80%;
            justify-content: space-evenly;
        }
    }

    &__button {
        width: 7.5rem;
        height: 2.3rem;
    }
}
