@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.organization-dashboard-feed {
    @include dashboard-feed();
    &__button-container {
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-size: $font18;
        font-weight: 700;
        display: flex;
        justify-content: flex-end;
        justify-content: space-between;

        align-items: center;

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font16;
        }
        @media screen and (max-width: $breakpoint-1280) {
            width: 36vw;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
        }

        @media screen and (max-width: $breakpoint-550) {
            width: 85vw;
            font-size: $font16;
        }

        &--width {
            width: 63%;
            @media screen and (max-width: $breakpoint-1350) {
                width: 61%;
            }

            @media screen and (max-width: $breakpoint-1280) {
                width: 45%;
            }

            @media screen and (max-width: $breakpoint-420) {
                width: 55%;
            }
            display: flex;
            justify-content: flex-end;
        }
    }

    &__button {
        width: 31%;
        max-width: 8.75rem;
        min-width: 8rem;
        height: 1.875rem;

        @media screen and (max-width: $breakpoint-1280) {
            max-width: none;
            min-width: 0;
            width: 4rem;
        }
        @media screen and (max-width: $breakpoint-420) {
            width: 4rem;
        }
    }
    &__button-text {
        font-size: $font12;
        font-weight: 700;
        display: flex;
        align-items: center;
    }

    &__container {
        width: 100%;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-weight: 700;
        font-size: $font18;
        margin: auto;
        margin-top: 1rem;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font16;
        }
    }
}
