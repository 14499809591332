.go-premium {
    display: flex;
    justify-content: center;
    width: 100%;
    height: fit-content;

    &__card {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: $card-border-radius;
        width: 50rem;
        max-width: 50rem;
        height: fit-content;

        @media screen and (max-width: $breakpoint-1500) {
            width: 42rem;
        }

        @media screen and (max-width: $breakpoint-1279) {
            width: 32rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 50rem;
        }

        @media screen and (max-width: $breakpoint-819) {
            width: 40rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
            box-shadow: none;
            border-radius: 0;
            margin-top: -3.25rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            margin-top: 0;
        }
    }

    &__content {
        width: 92%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__text {
        font-family: $font-family-inter;

        &--font-size-14 {
            font-size: $font14;
            @media screen and (max-width: $breakpoint-550) {
                margin-top: 0;
            }
        }
        &--font-size-16 {
            font-size: $font16;
        }
        &--font-size-18 {
            font-size: $font18;
        }

        &--font-weight-400 {
            font-weight: 400;
        }

        &--font-weight-500 {
            font-weight: 500;
        }

        &--font-weight-700 {
            font-weight: 700;
        }

        &--color-blue {
            color: $color-darker-blue;
        }

        &--color-yellow {
            color: $color-yellow;
        }

        &--color-gold {
            color: $color-gold;
        }

        &--color-red {
            color: $color-red;
        }

        &--color-blue-54 {
            color: $color-blue-54;
        }

        &--margin-right-02 {
            margin-right: 0.2rem;
        }
        &--margin-left-02 {
            margin-left: 0.2rem;
        }

        &--margin-left-02-web {
            margin-left: 0.2rem;
            @media screen and (max-width: $breakpoint-545) {
                margin-left: 0;
            }
        }

        &--decoration-none {
            text-decoration: none;
        }

        &--cursor-pointer {
            cursor: pointer;
        }

        &--hover-brightness {
            &:hover {
                filter: $hover-brightness;
            }
        }

        &--margin {
            margin-top: 2.5rem;
            margin-bottom: 3.188rem;
        }

        &--promo-text {
            width: 91%;

            @media screen and (max-width: $breakpoint-768) {
                margin-bottom: 0;
            }

            @media screen and (max-width: $breakpoint-350) {
                font-size: $font14;
                margin-bottom: 0.6rem;
            }
        }
    }

    &__header-upper-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__profile-image {
        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__header-upgrade-profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 19rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 100%;
            justify-content: space-between;
        }
    }

    &__header-upgrade-profile-text-container {
        text-align: flex-end;

        @media screen and (max-width: $breakpoint-550) {
            text-align: flex-start;
        }
    }

    &__premium-icon {
        margin-left: 1rem;
    }

    &__header-middle-panel {
        margin-top: 1.5rem;
    }

    &__border-divider {
        width: 100%;
        height: 0.047rem;
        background-color: $color-grey-c4;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    &__title-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        //margin-top: 4rem;
    }

    &__text-container {
        width: 100%;
        margin-right: 2rem;
    }

    &__cards-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 3rem;

        @media screen and (max-width: $breakpoint-660) {
            flex-direction: column;
            justify-content: start;
            align-items: center;
        }
    }

    &__for-profit-radio-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 3rem;

        @media screen and (max-width: $breakpoint-420) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 2rem;
            width: 100%;
            margin: auto;
        }
    }

    &__radio-container {
        display: flex;
        flex-direction: row;
        margin: 0.5rem;

        @media screen and (max-width: $breakpoint-420) {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 12.625rem;
            margin-bottom: 0.5rem;
        }
    }

    &__radio {
        margin-right: 0.5rem;
    }

    &__header-lower-panel {
        margin-top: 2.5rem;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__promo-card {
        background-image: url("../../../public/edusiia_illustration_rabatt.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 20.973rem;
        border-radius: 0.9rem;
        padding-bottom: 1rem;
        background-color: rgba(255, 199, 0, 0.15);
        display: none;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        @media screen and (max-width: $breakpoint-1279) {
            height: 19.973rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            height: 20.973rem;
        }
    }

    &__custom-card {
        width: 100%;
        height: 6rem;
        border-radius: $border-radius-09;
        border: solid 0.03rem $color-blue-54;
        display: flex;
        align-items: center;
        padding-left: 4rem;
        padding-right: 4rem;

        @media screen and (max-width: $breakpoint-660) {
            width: 90%;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            padding: 1rem;
        }
    }

    &__info-text-container {
        margin-top: 2rem;
    }

    &__package-info-container {
        margin-top: 2rem;
    }

    &__divider {
        width: 32%;
        height: 0.047rem;
        background-color: $color-grey-c4;
        margin: auto;
        @media screen and (max-width: $breakpoint-1599) {
            width: 27%;
        }

        @media screen and (max-width: $breakpoint-1500) {
            width: 17%;
        }

        @media screen and (max-width: $breakpoint-1280) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-1023) {
            display: block;
            width: 32%;
        }

        @media screen and (max-width: $breakpoint-819) {
            width: 17%;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 24%;
        }

        @media screen and (max-width: $breakpoint-660) {
            width: 17%;
        }
        @media screen and (max-width: $breakpoint-600) {
            display: none;
        }
    }
}
