@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.settings-profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*justify-content: space-around;*/
    width: 100%;
    background-color: transparent;
    min-height: 100%;

    @media screen and (max-width: $breakpoint-768) {
        width: 75%;
        margin: auto;
        height: 100vh;
        display: block;
    }

    @media screen and (max-width: $breakpoint-550) {
        padding-left: 2rem;
    }

    &__form {
        height: 60%;
        width: 27rem;
        padding-left: 2rem;

        @media screen and (max-width: $breakpoint-1023) {
            height: 85%;
            padding-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            padding-left: 0;
            width: 100%;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 100%;
            margin-left: -1rem;
        }
    }

    &__button-container {
        height: 8%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        top: 90%;
        width: 100%;
        background-color: white;
        right: 0;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        @media screen and (max-width: $breakpoint-768) {
            padding-right: 0;
            height: 1.25rem;
            top: 5.938rem;
        }

        &--mobile {
            display: none;
            top: 0;
        }
    }

    &__button {
        width: 7rem;
        height: 2.3rem;
        margin-right: 2rem;

        @media screen and (max-width: $breakpoint-768) {
            margin-right: 0;
            width: 100%;
        }
    }
}
