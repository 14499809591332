@import "../abstracts/mixins/component-mixins.scss";

.search-component {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: $z-index-second-layer;

    &__content {
        width: 100%;
        max-width: 50rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 1rem;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-top: 2rem;
        }
    }

    &__filter-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    &__filter-item {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-mid-dark-grey;
        border-bottom: solid 0.2rem transparent;
        cursor: pointer;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
        }

        &--selected {
            border-bottom: solid 0.2rem $color-light-blue;
            color: $color-light-blue;
        }
    }

    &__results-container {
        width: 100%;
        max-width: 50rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        border-radius: $border-radius-09;
        box-shadow: $card-box-shadow;
        margin-top: 1rem;

        @media screen and (max-width: $breakpoint-600) {
            margin-bottom: 6rem;
        }
    }

    &__results-content {
        width: 100%;
        height: fit-content;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        max-height: 40rem;
        overflow-y: auto;
        min-height: 3rem;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
    }

    &__result {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        border-bottom: 0.031rem solid $color-mid-dark-grey;
        background-color: $color-white;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        align-items: center;

        &:hover {
            filter: $hover-brightness-darker;
        }

        &--no-border {
            border-bottom: none;
        }

        &--post {
            font-family: $font-family-inter;
            font-size: $font14;
            color: $color-dark-blue;
            font-weight: 500;
            word-break: break-word;
            height: fit-content;
            cursor: default;

            &:hover {
                filter: none;
            }
        }
    }

    &__result-container {
        border-bottom: 0.031rem solid $color-mid-dark-grey;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        &--no-border {
            border-bottom: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__left-panel {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
    }

    &__right-panel {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__right-panel-post {
        font-size: $font12;
        width: 18%;
        text-align: end;
        padding-right: 0.5rem;
        box-sizing: border-box;
        &:hover {
            cursor: pointer;
        }
    }

    &__middle-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 69%;
        &:hover {
            cursor: pointer;
        }
    }

    &__post-button {
        background-color: $color-grey;
        width: 97%;
        margin: auto;
        margin-top: 0.6rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font18;
        font-weight: 400;
        color: $color-dark-grey;
        border-radius: 0.75rem;
        font-family: $font-family-inter;

        &:hover {
            cursor: pointer;
            filter: $hover-brightness-darker;
        }

        @media screen and (max-width: $breakpoint-768) {
            height: 2rem;
            font-size: $font14;
        }
    }

    &__image {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: $border-radius-circular;
        object-fit: cover;
        margin-left: 0.7rem;
        margin-right: 0.7rem;
    }

    &__post-image {
        width: 30%;
        border-radius: $border-radius-09;
        margin-top: 0.3rem;

        @media screen and (max-width: $breakpoint-768) {
            width: 65%;
        }
    }

    &__text {
        width: 98%;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 500;
        color: $color-darker-blue;
        word-break: break-word;
        &:hover {
            cursor: pointer;
        }

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font15;
        }

        &--post {
            display: block;
            width: 80%;
            padding-left: 4.9rem;
            box-sizing: border-box;
            word-break: break-word;
        }

        &--bold {
            font-weight: 700;
        }

        &--font-large {
            font-size: $font18;
            font-weight: 700;
            color: $color-blue;
        }

        &--font-size-14 {
            font-size: $font14;
        }

        &--font-size-12 {
            font-size: $font12;
            font-weight: 700;
            color: $color-red;
            width: 100%;
        }

        &--deleted {
            color: $color-grey-c4;
            &:hover {
                cursor: default;
            }
        }
    }

    &__tag {
        @include tag();
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__tag-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 400;
        color: $color-mid-dark-grey;
        width: 100%;
        &:hover {
            cursor: pointer;
        }

        &--post {
            padding-bottom: 1rem;
        }
    }

    &__no-data-text {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
