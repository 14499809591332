@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.room-screen {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 73vh;
    flex-direction: column;
    padding-top: 3.25rem;

    @media screen and (max-width: $breakpoint-550) {
        padding-top: 0;
    }

    @media screen and (max-width: $breakpoint-1023) {
        height: 94vh;
    }
}

.room-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    height: 65vh;
    max-width: 108rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;

    @media screen and (max-width: $breakpoint-1023) {
        height: 100%;
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    &__left-panel {
        width: 25%;
        max-width: 22.6rem;
        min-width: 17rem;
        height: 65vh;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__no-access {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__left-panel-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 11%;
        padding-bottom: 11%;
        height: 100%;
        box-sizing: border-box;
        overflow: auto;


        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            padding-top: 5%;
        }
    }

    &__left-panel-member {
        height: 100%;
        padding-top: 9%;
        box-sizing: border-box;
        border-bottom-left-radius: 0.9rem;
    }

    &__left-panel-button {
        max-width: 8.156rem;
        width: 100%;
        margin-bottom: 1.25rem;
        margin-top: auto;
    }

    &__left-panel-button-content {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        font-size: $font12;
        font-weight: 700;
        display: flex;
        align-items: center;
    }

    &__left-panel-button-icon {
        margin-right: 0.3rem;
    }

    &__right-panel {
        width: 80%;
        //height: 65vh;
        height: 100%;
        border-left: solid 0.03rem $color-grey-c4;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
        }
    }

    &__right-panel-header {
        width: 100%;
        //min-height: 3.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.25rem;

        &--join-button {
            @media screen and (max-width: $breakpoint-768) {
                height: auto;
                max-height: 11.25rem;
            }
        }
    }

    &__close-icon {
        position: absolute;
        top: 0.7rem;
        right: 1rem;

        @include icon();
    }

    &__right-panel-content {
        flex: 1 1 0;
        width: 100%;
        height: 74%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &--overflow-hiden {
            overflow: hidden;
        }

        &--member-panel {
            flex-grow: 1;
            height: 100%;
            flex:  unset;
            overflow-x: hidden;
            overflow-y: hidden;
        }
    }

    &__right-panel-input-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1.25rem;
        max-height: 78%;
        border-top: solid 0.03rem $color-grey-c4;
        

        @media screen and (max-width: $breakpoint-500) {
            align-items: flex-end;
        }
    }

    &__right-panel-input-no-access {
        width: calc(100% - 1rem);
        height: 9%;
        min-height: 4.5rem;
        display: flex;
        align-items: center;
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        margin-left: 1rem;
    }

    &__photo-text-container {
        width: 78%;
    }

    &__error-message {
        color: $color-red;
        margin-left: 2rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font12;
        @media screen and (max-width: $breakpoint-420) {
            margin-left: 0.5rem;
            font-size: $font10;
        }
    }

    &__right-panel-input {
        width: 100%;
        height: 2rem;
        margin-left: 2rem;
        margin-right: 1rem;
        outline: none;
        border: none;
        resize: none;
        font-family: $font-family-inter;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        max-height: 18rem;

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    &__right-panel-input::-webkit-scrollbar {
        display: none;
        width: none;
        scrollbar-width: 0;
        -ms-overflow-style: none;
    }

    &__right-panel-icons-container {
        display: flex;
        flex-direction: row;
        margin-right: 1rem;
        position: relative;

        @media screen and (max-width: $breakpoint-420) {
            margin-right: 0.5rem;
        }
    }

    &__right-panel-icon {
        @include icon();

        &--disable {
            &:hover {
                background-color: transparent;
                cursor: initial;
            }
        }
        @media screen and (max-width: $breakpoint-1023) {
            min-width: 2.5rem;
            min-height: 2.5rem;
            max-width: 2.5rem;
            max-height: 2.5rem;
        }
        @media screen and (max-width: $breakpoint-420) {
            min-width: 2rem;
            min-height: 2rem;
            max-width: 2rem;
            max-height: 2rem;
        }
        position: relative;
    }

    &__input-file {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        top: 0;
        right: 0;
        opacity: 0;
    }

    @include text-area-image();
    &__emoji-picker {
        position: absolute;
        bottom: 3.5rem;
        right: 3rem;
        z-index: $z-index-third-layer;

        @media screen and (max-width: $breakpoint-420) {
            right: 0;
        }
        margin-right: 0.25rem;
    }

    &__emoji-picker-feed {
        position: relative;
        left: -5rem;
        bottom: -12rem;
        z-index: $z-index-third-layer;

        @media screen and (max-width: $breakpoint-420) {
            right: 0;
        }
        margin-right: 0.25rem;
    }

    &__back-icon-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 98%;
        height: 3.5rem;
        margin-left: 1rem;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            filter: $hover-brightness-darker;
        }
    }

    &__back-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 2rem;
        margin-right: 0.5rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-dark-grey;
    }

    &__no-post {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: $font16;
        font-family: $font-family-inter;
        color: $color-mid-dark-grey;
    }

    &__document-container {
        width: max-content;
        display: flex;
        border-radius: 0.625rem;
        margin-left: 2rem;

        @media screen and (max-width: $breakpoint-1350) {
            margin-left: 0.9rem;
        }

        &--edit-post {
            margin-left: 0.6rem;
        }
    }

    &__document-image {
        height: 3rem;
        width: 3rem;
        border: 2px solid $color-blue;
        box-shadow: 0 0 0.313rem rgba(0, 0, 0, 0.15);
        border-radius: 0.625rem;

        @media screen and (max-width: $breakpoint-768) {
            height: 2.5rem;
            width: 2.5rem;
        }
        @media screen and (max-width: $breakpoint-500) {
            height: 2rem;
            width: 2rem;
        }
    }

    &__document-title {
        color: $color-dark-blue;
        font-family: $font-family-inter;
        font-weight: 600;
        font-size: $font16;
        cursor: pointer;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font14;
        }
        @media screen and (max-width: $breakpoint-500) {
            font-size: $font12;
        }
    }

    &__document-content {
        height: 100%;
        padding-left: 0.4rem;
        margin: auto;
    }

    &__document-size {
        color: $color-dark-grey;
        font-family: $font-family-inter;
        font-size: $font12;

        @media screen and (max-width: $breakpoint-500) {
            font-size: $font11;
        }
    }

    &__document-cancel {
        width: 0.8rem;
        height: 0.8rem;
        display: flex;
        margin-left: 0.2rem;

        &:hover {
            filter: $hover-brightness;
            cursor: pointer;
        }
    }
}

textarea:focus {
    .footer {
        display: none;
    }
}

.send-icon {
    height: 1.813rem;
    width: 1.75rem;

    @media screen and (max-width: $breakpoint-1023) {
        height: 1.5rem;
        width: 1.5rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        height: 1.25rem;
        width: 1.25rem;
    }

    &--green {
        fill: $color-blue;
    }

    &--grey {
        fill: $color-mid-dark-grey;
    }
}

.file-icon {
    width: 1.563ren;
    height: 1.5rem;

    @media screen and (max-width: $breakpoint-1023) {
        width: 1.25rem;
        height: 1.188rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        height: 1.063rem;
        width: 1.063rem;
    }
}

.smile-icon {
    width: 1.625rem;
    height: 1.625rem;

    @media screen and (max-width: $breakpoint-1023) {
        height: 1.313rem;
        width: 1.25rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        height: 1.063rem;
        width: 1.063rem;
    }
}
