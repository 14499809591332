@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.checkbox {
    display: block;
    position: relative;
    font-family: $font-family-inter;
    color: $color-dark-grey;
    padding-left: 0.5rem;
    font-size: $font12;

    @media screen and (max-width: $breakpoint-768) {
        padding-left: 0rem;
    }

    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.5rem;
        background-color: $color-light-grey;

        &:hover {
            cursor: pointer;
        }

        &--red {
            @include clicked();
            background-color: $color-red;
        }

        &--dark-blue {
            @include clicked();
            background-color: $color-dark-blue;
        }

        &--clicked {
            @include clicked();
            background-color: $color-dark-blue;
        }
    }
}

input[type="checkbox"] {
    visibility: hidden;
}
