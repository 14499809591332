
.donation-screen {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-color: transparent;
    margin-bottom: 5rem;
    min-width: 20rem;
}