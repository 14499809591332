.managers-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $font12;
    font-family: $font-family-inter;
    color: $color-darker-blue;
    font-weight: 500;

    &__container {
        display: flex;
        flex-wrap: wrap;
        width: 78%;
        columns: 2 auto;
        cursor: pointer;
        justify-content: center;

        @media screen and (max-width: $breakpoint-1500) {
            width: 88%;
        }
    }

    &__outer-container {
        cursor: pointer;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        padding: 0.2rem;
        @media screen and (max-width: $breakpoint-1500) {
            width: 46%;
        }
        position: relative;
    }

    &__full-name {
        display: none;
    }

    &__outer-container:hover > &__full-name {
        display: block;
        position: absolute;
        white-space: nowrap;
        bottom: -1.25rem;
        z-index: 1;
        padding-left: 1.125rem;
        padding-right: 1.125rem;
        padding-top: 0.6rem;
        width: fit-content;
        padding-bottom: 0.6rem;
        background-color: $color-white;
        text-align: center;
        box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.15);
        border-radius: 0.5rem;
        border-bottom: 0.325rem solid $color-red;
        min-width: 7rem;
    }

    &__outer-name {
        margin-top: 0.2rem;
        max-width: 100%;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: clip; 
    }
    &__title {
        display: flex;
        padding-left: 1.125rem;
        padding-right: 1.125rem;
        background-color: $color-red;
        border-radius: 0.9rem;
        color: $color-white;
        margin-bottom: 1.125rem;
    }
}
