@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.donation-bank-transfer {
    width: 45rem;
    height: 22rem;
    font-family: $font-family-inter;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 100vh;
    }

    @media screen and (max-width: $breakpoint-550) {
        font-size: $font14;
    }

    &__title-container {
        display: flex;
        height: 5rem;
        align-items: center;
        justify-content: space-between;
    }
    &__title {
        font-size: $font22;
        color: $color-blue;
        font-weight: 700;
        margin-left: 3.375rem;
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 2.375rem;
        }
    }

    &__icon {
        @include icon();
        margin-right: 1rem;
    }

    &__first-text-container {
        margin-left: 1rem;
        margin-top: 1rem;
        margin-bottom: 3rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 0;
            margin-top: 0;
        }
    }

    &__text {
        margin-top: 0.7rem;
        color: $color-darker-blue;
    }
    &__bold-text {
        margin-top: 0.7rem;
        margin-right: 0.2rem;
        font-weight: 700;
        color: $color-blue;
    }

    &__content {
        margin-left: 3.375rem;
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 2.375rem;
        }
    }

    &__content-container {
        display: flex;
        align-items: center;
    }
}
