//radio-button
@mixin radio-button {
    position: relative;
    content: "";
    display: flex;
    align-items: flex-end;
    visibility: visible;
    font-family: $font-family-inter;
    font-size: $radio-font-size;
    font-weight: $radio-font-weight;
}

@mixin radio-button-checked {
    width: $radio-checked-width;
    height: $radio-checked-height;
    border: $radio-border-checked;
    border-radius: $radio-radius;
    position: absolute;
    top: 0;
    left: 0;
}

//checkbox

@mixin clicked {
    width: 0.875rem;
    height: 0.875rem;
    border: $radio-border-checked;
}

//dropdown

@mixin dropdown {
    border-radius: $dropdown-radius;
    font-family: $font-family-inter;
    font-size: $font14;
    max-width: 33.375rem;
    color: $color-dark-blue;

    @media screen and (max-width: 768px) {
        width: 16.875rem;
        margin: 0;
    }
}

@mixin alignText {
    display: flex;
    align-items: center;
    padding-left: 1.875rem;
    margin-block-end: 0;
}

@mixin container {
    max-height: 10rem;
    box-shadow: $dropdown-box-shadow;
    overflow-y: auto;
    overflow-x: hidden;

    background-color: $color-white;
}

@mixin item {
    height: 2.5rem;
    max-width: 33.375rem;
    display: flex;
    flex-direction: column;
}

//textinput

@mixin input {
    background-color: $color-grey;
    border-radius: 0.5rem;
    border: $text-input-border;
    color: $color-dark-blue;
    font-size: $font14;
    font-family: $font-family-inter;
    height: 3.75rem;
    display: flex;
    border: 0.031rem solid $color-grey;
    padding-left: 1.4rem;
    box-sizing: border-box;
    width: 100%;
    font-weight: 500;

    &:hover {
        border: $text-input-hover-border;
    }

    &:focus {
        outline: none;
    }

    &--disabled {
        &:hover {
            border: 0.031rem solid $color-grey;
            cursor: default;
        }
    }
}

//delete-account

@mixin titles {
    font-family: $font-family-inter;
    font-size: $font14;
    font-weight: 500;
}

@mixin photo-dropdown-card {
    width: 15.625rem;
    max-width: 15.625rem;
    min-width: 10rem;
    height: 6.5rem;
    background: $color-white;
    box-shadow: $card-box-shadow;
    border-radius: $card-border-radius;
    font-size: $font16;
    font-family: $font-family-inter;
    color: $color-darker-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &__input {
        display: none;
    }

    &__text {
        padding-left: 0.875rem;

        &--small {
            font-size: $font10;
            padding-left: 0rem;
            @media screen and (max-width: $breakpoint-1439) {
                font-size: 0.563rem;
            }
        }
    }

    &__modal {
        width: 86%;
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        margin-top: 0.031rem;
        margin-bottom: 0.031rem;
        border-radius: 0.75rem;
        background-color: $color-white;
        z-index: 1;
        cursor: pointer;
        @media screen and (max-width: $breakpoint-1439) {
            font-size: $font12;
        }

        @media screen and (max-width: $breakpoint-768) {
            padding-left: 0.5rem;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
        }

        &--disable {
            background-color: $color-white !important;
            cursor: default;
            color: $color-mid-dark-grey;
        }
    }

    &__modal:hover {
        background-color: $color-grey;
    }

    @media screen and (max-width: $breakpoint-1439) {
        font-size: $font12;
        width: 13rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 11rem;
    }
}

@mixin user-profile-cover-container {
    position: relative;
    min-width: 100%;
    max-width: 100%;
    height: 14.625rem;
    border-radius: $border-radius-09;
    background-color: transparent;

    @media screen and (max-width: $breakpoint-1599) {
        height: 14rem;
    }
    @media screen and (max-width: $breakpoint-1439) {
        height: 12.625rem;
    }
    @media screen and (max-width: $breakpoint-1350) {
        height: 11rem;
    }
    @media screen and (max-width: $breakpoint-1200) {
        height: 10rem;
    }
    @media screen and (max-width: $breakpoint-1099) {
        height: 8rem;
    }
    @media screen and (max-width: $breakpoint-1050) {
        height: 9rem;
    }
    @media screen and (max-width: $breakpoint-1023) {
        height: 13rem;
    }
    @media screen and (max-width: $breakpoint-819) {
        height: 12rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        height: 11rem;
    }
    @media screen and (max-width: $breakpoint-660) {
        height: 10rem;
    }
    @media screen and (max-width: $breakpoint-550) {
        height: 9rem;
    }
    @media screen and (max-width: $breakpoint-500) {
        height: 8rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        height: 8rem;
    }
}

//label-cards

@mixin label-style {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.438rem;
    height: 1.063rem;
    width: 8rem;
    font-weight: 500;
    border-radius: $border-radius-09;
    font-size: $font12;
    color: $color-white;
    text-align: center;
}

@mixin label-name-style {
    font-family: $font-family-inter;
    font-size: $font16;
    color: $color-darker-blue;

    &--font-weight-400 {
        font-weight: 400;
    }

    &--font-weight-700 {
        font-weight: 700;
    }
}

//mention-dropdown

@mixin at_dropdown {
    display: flex;
    align-items: center;
    visibility: hidden;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 1.059rem;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 2;
    background-color: $color-white;
}

//members of rooms

@mixin members {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-inter;
    font-weight: 500;
    font-size: $font14;
    color: $color-gold;
    margin-left: 0.5rem;
    width: 7.5rem;
}

@mixin members-container {
    width: 7.188rem;
    height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-gold-opacity-2;
    border-radius: $border-radius-09;
}

@mixin text-area-image {
    &__post-image {
        width: 5rem;
        height: 5rem;
        box-shadow: 0 0 0.313rem rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin-left: 2rem;
        @media screen and (max-width: $breakpoint-420) {
            margin-left: 0.5rem;
        }

        &--opacity {
            opacity: 0.3;
            filter: brightness(0.8);
            margin-left: 0;
            box-shadow: none;

            &:hover {
                cursor: pointer;
                filter: brightness(1);
            }

            @media screen and (max-width: $breakpoint-420) {
                margin-left: 0;
            }
        }

        &--no-margin {
            margin-left: 0.625rem;
        }
    }

    &__post-image-cancel-container {
        width: 5rem;
        height: 5rem;
        position: relative;
        margin-left: 0.5rem;
        border-radius: 10rem;
        &:hover {
            box-shadow: 0rem 0rem 10rem rgba(0, 0, 0, 0.15);
        }
    }

    &__post-image-cancel {
        position: absolute;
        top: 2rem;
        right: 1.875rem;
        cursor: pointer;
    }

    &__post-image-container {
        display: flex;

        &--edit-post {
            @media screen and (max-width: $breakpoint-768) {
                margin-bottom: 0.5rem;
            }
        }
    }
}

@mixin role-room {
    opacity: 0.8;
    color: white;
    padding-bottom: 0.05rem;
    text-align: center;
}

//hashtag

@mixin tag {
    font-family: $font-family-inter;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.438rem;
    background-color: $tag-background-color-blue;
    padding: 0.3rem;
    width: max-content;
    border-radius: 0.9rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: $color-light-blue;
    text-align: center;
    margin-right: 0.5rem;
    word-break: break-all;
}
