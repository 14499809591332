@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";
.react-datepicker-wrapper,
.react-datepicker__input-container input {
    position: relative;
    width: 15.25rem !important;
    max-width: 15.25rem !important;
    height: 2.5rem !important;
    max-height: 2.5rem !important;
    border: none;
    background-color: $color-grey;
    border-radius: $border-radius-05;
    outline: none;
    padding-left: 1.4rem;
    box-sizing: border-box;
    font-family: $font-family-inter;
    font-size: $font14;
    font-weight: 500;
    color: $color-darker-blue;
}

.react-datepicker {
    border-radius: $border-radius-05 !important;
    font-family: $font-family-inter !important;
    border: solid 0.03rem $color-grey-c4 !important;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__header {
    border-top-left-radius: $border-radius-05 !important;
    border-top-right-radius: $border-radius-05 !important;
}

.react-datepicker__current-month {
    margin-bottom: 0.75rem !important;
    font-family: $font-family-inter !important;
    font-size: $font16 !important;
    font-weight: 700 !important;
    color: $color-light-blue !important;
}

.react-datepicker__day-name {
    font-family: $font-family-inter !important;
    font-size: $font14 !important;
    font-weight: 500 !important;
    color: $color-darker-blue !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected ::before,
.react-datepicker__day--selected ::after,
.react-datepicker__day--keyboard-selected {
    background-color: $color-light-blue !important;
    color: $color-white !important;
}

.react-datepicker__day,
.react-datepicker__day--006,
.react-datepicker__day--disabled,
.react-datepicker__day--today,
.eact-datepicker__day--keyboard-selected {
    font-family: $font-family-inter !important;
}

.react-datepicker__year-select,
.react-datepicker__year-select option {
    width: 5rem;
    height: 1.75rem;
    font-family: $font-family-inter !important;
    font-size: $font14 !important;
    font-weight: 500 !important;
    color: $color-darker-blue !important;
    background-color: $color-grey !important;
    border: solid 0.03rem $color-grey-c4 !important;
    border-radius: $border-radius-05 !important;
    outline: none !important;
    cursor: pointer;
}

.react-datepicker__year-dropdown-container,
.react-datepicker__year-dropdown-container--select {
    background-color: $color-light-blue !important;
    border-radius: $border-radius-05 !important;
    outline: none !important;
}

.react-datepicker__year-dropdown-container::-webkit-scrollbar {
    width: 0.375rem !important;
}
.react-datepicker__year-dropdown-container::-webkit-scrollbar-track {
    background: $color-mid-dark-grey !important;
}
.react-datepicker__year-dropdown-container::-webkit-scrollbar-thumb {
    background: $color-grey-c4 !important;
}
