@import "../abstracts/variables.scss";

.header-burger-menu {
    width: 64%;
    max-width: 20rem;
    height: 100vh;
    top: 3.8rem;
    position: absolute;
    border: 0.005rem solid $color-light-grey;
    right: 0;
    min-width: 13.5rem;
    max-width: 18.125rem;
    background-color: $color-white;
    z-index: $z-index-third-layer;

    @media screen and (min-width: $breakpoint-1023) {
        display: none;
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 3.75rem;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font14;
        color: $color-darker-blue;
        border-bottom: 0.03rem solid $color-light-grey;
        background-color: $color-white;
        cursor: pointer;

        &:hover {
            filter: brightness(0.95);
        }

        &--background-red {
            background-color: $color-red;

            &:hover {
                filter: brightness(1.1);
            }
        }

        &--rooms-dropdown {
            border-bottom: none;
        }
    }

    &__rooms-dropdown-content {
        padding-left: 25%;
        box-sizing: border-box;
    }

    &__rooms-dropdown-item {
        font-family: $font-family-inter;
        color: $color-dark-grey;
        font-size: $font14;
        padding-bottom: 1.125rem;

        &:hover {
            cursor: pointer;
            color: $color-dark-blue;
            font-weight: 500;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
    }

    &__text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 75%;

        &--color-white {
            color: $color-white;
        }

        &--rooms-dropdown {
            width: 50%;
        }
    }
}
