@import "../abstracts/variables.scss";

.header-profile-menu {
    width: 15.625rem;
    height: 23rem;
    top: 5.6rem;
    position: absolute;
    border-radius: 1rem;
    right: -4rem;

    @media screen and (max-width: $breakpoint-1439) {
        width: 14rem;
        height: 20rem;
        top: 4.9rem;
    }
    @media screen and (max-width: $breakpoint-1279) {
        right: -3rem;
    }
    @media screen and (max-width: $breakpoint-1199) {
        right: -2rem;
    }
    @media screen and (max-width: $breakpoint-1099) {
        right: -0.5rem;
    }
    &__card {
        max-width: 15.625rem;
        height: fit-content;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    &__profile {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 6.2rem;
        border-bottom: 0.03rem solid $color-light-grey;
        background-color: $color-white;
        cursor: pointer;

        &:hover {
            filter: brightness(0.95);
        }
    }
    &__image {
        min-width: 6rem;
        max-width: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__profile-text-container {
        width: 9rem;
        overflow: hidden;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1439) {
            width: 7.15rem;
        }
    }

    &__profile-text {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-darker-blue;
    }

    &__item {
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.55rem;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 500;
        color: $color-dark-blue;
        cursor: pointer;
        text-decoration: none;

        &--color-gold {
            background-color: $color-gold-opacity-2;
            border: $color-gold;
            color: $color-gold;

            &:hover {
                filter: $hover-brightness;
            }
        }

        @media screen and (max-width: $breakpoint-1439) {
            height: 3rem;
        }

        &:hover {
            filter: $hover-brightness-darker;
        }

        &--border-bottom-3 {
            border-bottom: 0.03rem solid $color-light-grey;
        }

        &--hover-color-red {
            &:hover {
                background-color: $color-red;
            }
        }

        &--text-color-white {
            &:hover {
                color: $color-white;
            }
        }
    }
}
