.pop-up {
    width: 35rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: $breakpoint-768) {
        width: 26rem;
        height: 11rem;
    }

    @media screen and (max-width: $breakpoint-550) {
        width: 20rem;
        height: 9rem;
    }

    @media screen and (max-width: $breakpoint-420) {
        width: 18rem;
    }

    @media screen and (max-width: $breakpoint-360) {
        width: 17rem;
        height: 8rem;
    }

    &__title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 7%;
        box-sizing: border-box;
        margin-bottom: 2rem;

        &--no-margin {
            margin-bottom: 0;
        }
    }

    &__title {
        color: $color-blue;
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        margin-top: 0.5rem;

        @media screen and (max-width: $breakpoint-1199) {
            font-size: $font16;
        }
    }

    &--signupError {
        color: $color-red;
    }

    &--signin-message {
        width: 50rem;
        height: 19.688rem;

        @media screen and (max-width: $breakpoint-1365) {
            width: 45rem;
            height: 17rem;
        }

        @media screen and (max-width: $breakpoint-1199) {
            width: 35rem;
            height: 15rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 28rem;
        }

        @media screen and (max-width: $breakpoint-500) {
            width: 20rem;
        }

        @media screen and (max-width: $breakpoint-360) {
            width: 18rem;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        min-width: 3rem;
        min-height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
        background-color: transparent;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-550) {
            min-width: 2.5rem;
            min-height: 2.5rem;
            max-width: 2.5rem;
            max-height: 2.5rem;
        }

        &:hover {
            background-color: $color-grey;
        }
    }

    &__text-container {
        width: 100%;
        text-align: center;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-768) {
            height: 45%;
            flex-direction: column-reverse;
        }

        &--signin-message {
            flex-direction: column;
        }
    }

    &__text {
        margin-left: 0.7rem;
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;

        @media screen and (max-width: $breakpoint-768) {
            margin-left: 0;
            margin-bottom: 0.7rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-420) {
            font-size: $font12;
        }

        &--successful {
            color: $color-blue;
        }

        &--error {
            color: $color-red;
        }

        &--signin-message {
            margin-bottom: 0.5rem;
            font-size: $font16;
            color: $color-dark-blue;
            margin-left: 0;
            font-weight: 500;
            width: 85%;
            max-width: 38.75rem;
            text-align: start;

            @media screen and (max-width: $breakpoint-1365) {
                width: 76%;
            }
            @media screen and (max-width: $breakpoint-1199) {
                width: 70%;
                font-size: $font12;
            }
        }

        &--upgrade {
            color: $color-dark-blue;
            color: $color-blue;
            font-weight: 500;
        }

        &--border {
            border-top: 0.05rem solid $color-grey-c4;
            margin-top: 1.25rem;
            padding-top: 2.813rem;
            @media screen and (max-width: $breakpoint-1199) {
                margin-top: 1rem;
                padding-top: 1.5rem;
            }
        }

        &--successful-verification {
            margin-bottom: 0;
        }
    }
}

.trash-can-icon {
    @media screen and (max-width: $breakpoint-768) {
        width: 1.875rem;
        height: 1.875rem;
    }
}
