@import "../abstracts/variables.scss";

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: none;
    font-family: $font-family-inter;

    &--font-size-small {
        font-size: $font14;
    }

    &--font-size-medium {
        font-size: $font16;

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font14;
        }
    }
    &--font-size-large {
        font-size: $font20;

        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font18;
        }

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font16;
        }
    }

    &--font-weight-400 {
        font-weight: 400;
    }

    &--font-weight-700 {
        font-weight: 700;
    }

    &--text-color-white {
        color: $color-white;
    }

    &--text-color-red {
        color: $color-red;
    }

    &--text-color-grey {
        color: $color-dark-grey;
    }

    &--border-radius-05 {
        border-radius: $border-radius-05;
    }

    &--border-radius-09 {
        border-radius: $border-radius-09;
    }

    &--radius-15 {
        border-radius: 0.938rem;

        @media screen and (max-width: $breakpoint-450) {
            border-radius: 0.438rem;
        }
    }

    &--color-red {
        background-color: $color-red;
    }

    &--color-grey {
        background-color: $color-grey;
    }

    &--color-grey-c4 {
        background-color: $color-grey-c4;
    }

    &--color-blue {
        background-color: $color-blue;
    }

    &--color-green {
        background-color: $color-green;
    }

    &--color-blue-54 {
        background-color: $color-blue-54;
    }

    &--color-gold {
        background-color: $color-gold;
    }

    &--hover-brighter {
        &:hover {
            filter: brightness(1.1);
        }
    }

    &--hover-darker {
        &:hover {
            filter: brightness(0.95);
        }
    }

    &--cursor-pointer {
        cursor: pointer;
    }

    &:disabled {
        background-color: $color-grey;
        cursor: initial;
        filter: none;
    }
}

.button__content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chat-button__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 6rem;
}

.chat-button-desktop {
    position: absolute;
    top: 15.5rem;
    right: 6%;

    @media screen and (max-width: $breakpoint-1599) {
        right: 0;
    }

    @media screen and (max-width: $breakpoint-1439) {
        top: 13.25rem;
    }

    @media screen and (max-width: $breakpoint-1350) {
        top: 12rem;
    }

    @media screen and (max-width: $breakpoint-1023) {
        right: 10%;
        top: 13rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        top: 12rem;
        right: 6%;
    }

    @media screen and (max-width: $breakpoint-550) {
        top: 16rem;
        left: auto;
        right: auto;
        width: 50%;
        display: none;
    }

    @media screen and (max-width: $breakpoint-450) {
        width: 65%;
        display: none;
    }

}

.chat-button-mobile {
    position: absolute;
    top: 15.5rem;
    right: 6%;

    @media screen and (max-width: $breakpoint-1599) {
        right: 0;
        display: none;
    }

    @media screen and (max-width: $breakpoint-1439) {
        top: 13.25rem;
        display: none;
    }

    @media screen and (max-width: $breakpoint-1350) {
        top: 12rem;
        display: none;
    }

    @media screen and (max-width: $breakpoint-1023) {
        right: 10%;
        top: 13rem;
        display: none;
    }

    @media screen and (max-width: $breakpoint-768) {
        top: 12rem;
        right: 6%;
        display: none;
    }

    @media screen and (max-width: $breakpoint-550) {
        /*top: 16rem;
        left: auto;
        right: auto;
        width: 50%;*/
        display: contents;
    }

    @media screen and (max-width: $breakpoint-450) {
        //width: 65%;
        display: contents;
    }

}

.chat-button__spacer {
    margin-left: 0.5rem;
}
