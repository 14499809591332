@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";
.new-members-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 18.8rem;
    min-width: 14rem;
    padding-top: 1.625rem;
    padding-bottom: 1.875rem;

    @media screen and (max-width: $breakpoint-1365) {
        width: 95%;
    }

    &__loading-card {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        max-width: 19.375rem;
        width: 95%;
        min-width: 14rem;
        height: 19.875rem;
        margin-bottom: 1.875rem;
        margin-top: 1.625rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    &__members {
        margin-top: 0.5rem;
        align-self: flex-start;
        overflow: hidden;
    }

    &__button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.75rem;
    }

    &__button {
        width: 100%;
        height: 2.8rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

.new-members-item {
    background-color: $color-white;
    display: flex;
    flex-direction: row;
    height: 5.18rem;
    width: 100%;

    &--no-border {
        border-bottom: 0;
    }

    &--border {
        border-bottom: 0.031rem solid $color-mid-dark-grey;
    }

    cursor: pointer;
    &:hover {
        filter: brightness(0.95);
    }

    &__left-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
    }

    &__right-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 20%;
    }

    &__text {
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font14;
        color: $color-darker-blue;
    }

    &__subtext {
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font12;
        color: $color-blue;
    }

    &__right-text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-blue;
    }
}
