@import "../abstracts/variables.scss";

.auth-layout {
    position: relative;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    &__main-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            flex-direction: column;
        }
    }
}
.left-panel {
    width: 40%;

    background-color: $color-grey;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 15rem;
    }

    &__logo {
        display: none;

        @media screen and (max-width: $breakpoint-768) {
            display: block;
            margin-top: 4.15%;
            margin-left: 11.54%;
            width: 6.953rem;
        }
    }

    &__description {
        margin-left: 14%;
        margin-top: 12.2%;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }
}

.right-panel {
    width: 60%;
    min-height: 63.5rem;
    background-color: $color-white;

    &--height-61 {
        min-height: 61rem;
        @media screen and (max-width: $breakpoint-1200) {
            min-height: 58rem;
        }
    }

    @media screen and (max-width: $breakpoint-1599) {
        min-height: 60.4rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 60%;
        min-height: 43rem;
    }

    &__edusiia-image {
        display: none;

        @media screen and (max-width: $breakpoint-768) {
            display: block;
            max-width: 24rem;
            max-height: 15.813rem;
            margin: auto;
            position: absolute;
            top: 5.5rem;
        }
    }

    &__signup-form {
        width: 32rem;
        max-width: 32rem;
        margin-left: 21%;
        margin-top: 4.938rem;
        margin-bottom: 5rem;

        @media screen and (max-width: $breakpoint-1919) {
            width: 28rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            width: 24rem;
            margin-top: 3rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 24rem;
            margin-left: 8%;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 19.063rem;
            margin-left: 0;
            align-self: center;
            margin-top: 20%;
        }

        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }

    &__signin-form {
        width: 32rem;
        max-width: 32rem;
        margin-left: 21%;
        margin-top: 3rem;

        @media screen and (max-width: $breakpoint-1919) {
            width: 28rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            width: 24rem;
        }

        @media screen and (max-width: $breakpoint-1099) {
            margin-left: 10%;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 23rem;
            margin-left: 3%;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 19.063rem;
            margin-top: 0;
            margin-left: 0;
        }

        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }

    &__reset-password-form {
        width: 32rem;
        margin-left: 21%;
        margin-top: 10%;

        @media screen and (max-width: $breakpoint-1919) {
            width: 28rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 23rem;
            margin-left: 15%;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 19.5rem;
            margin-left: 0rem;
            margin-top: 0%;
        }

        @media screen and (max-width: $breakpoint-350) {
            width: 17.5rem;
        }
    }

    &__edusiia-matching-form {
        width: 72%;
        min-width: 33.75rem;
        margin-left: 21%;
        margin-top: 8.5%;
        margin-bottom: 5rem;
        background-color: transparent;

        @media screen and (max-width: $breakpoint-1599) {
            margin-top: 3rem;
        }

        @media screen and (max-width: $breakpoint-1365) {
            margin-left: 15%;
        }

        @media screen and (max-width: $breakpoint-1099) {
            margin-left: 10%;
        }

        @media screen and (max-width: $breakpoint-1023) {
            margin-left: 10%;
            min-width: 0;
        }
        @media screen and (max-width: $breakpoint-768) {
            min-width: 0;
            width: 30.5rem;
            margin-left: 0;
            margin-top: 6rem;
            margin: auto;
            margin-bottom: 3.5rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 25.5rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 19.5rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 17.5rem;
        }
    }

    &__forgot-password-form {
        width: 32rem;
        margin-left: 21%;
        margin-top: 6.938rem;
        @media screen and (max-width: $breakpoint-1023) {
            margin-left: 10%;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 19.063rem;
            margin-left: 0;
            align-self: center;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }

    &__email-confirmation {
        width: 32rem;
        margin-left: 21%;
        margin-top: 6.938rem;
        @media screen and (max-width: $breakpoint-1023) {
            margin-left: 10%;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 19.5rem;
            margin-left: 0;
            margin-bottom: 13rem;
        }
    }
}

.edusiia-image {
    //position: absolute;
    max-width: 100%;
    max-height: 10rem;
    min-width: 10rem;
    background-color: transparent;
    padding-left: 5%;
    padding-right: 5%;
    //margin-top: 15.6%;
    //margin-left: 16%;
    //margin-right: 50%;

    @media screen and (max-width: $breakpoint-1200) {
        margin-top: 20%;
        margin-left: 14%;
    }

    @media screen and (max-width: $breakpoint-1099) {
        display: none;
    }
}
