@import "../abstracts/variables.scss";

.no-permission {
    width: 100%;
    height: 100vh;
    background-color: $color-grey-f7;
    font-family: $font-family-inter;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: $font18;

    @media screen and (max-width: $breakpoint-768) {
        justify-content: flex-start;
        align-items: center;
        padding-top: 6rem;
    }
    @media screen and (max-width: $breakpoint-600) {
        font-size: $font15;
    }
    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color-light-blue;
        font-weight: 700;
        border-bottom: 0.063rem solid #c4c4c4;
        max-width: 34.375rem;
        width: 100%;
        padding-bottom: 1.563rem;
        margin-bottom: 2.5rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__text {
        margin-bottom: 3.313rem;
        @media screen and (max-width: $breakpoint-600) {
            font-size: $font12;
        }
    }

    &__button {
        height: 2.188rem;
        width: 10rem;
    }
}
