@import "../abstracts/variables.scss";

.room-description {
    font-family: $font-family-inter;
    box-sizing: border-box;
    width: 100%;
    max-width: 16rem;
    padding-left: 0.5rem;
    word-break: break-word;
    font-size: $font14;

    @media screen and (max-width: $breakpoint-1350) {
        max-width: 13rem;
    }

    &__title {
        width: 100%;
        font-size: $font18;
        font-weight: 700;
        color: $color-red;
        padding-bottom: 1rem;
    }
    &__text {
        width: 100%;
        font-size: $font14;
        font-weight: 500;
        max-width: 12.438rem;
        color: $color-darker-blue;
        -webkit-line-clamp: unset;
    }

    &__button {
        border: none;
        background-color: transparent;
        color: $color-red;
        font-size: $font16;
        font-weight: 700;
        padding: 0;

        &:hover {
            filter: brightness(1.1);
            cursor: pointer;
        }
    }
}
