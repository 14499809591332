@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.dropdown {
    @include dropdown();
    position: relative;

    &--margin-bottom {
        margin-bottom: 1.55rem;
    }

    &--fixed-width {
        position: static;
        /*width: 20rem;

        @media screen and (max-width: $breakpoint-768) {
            width: 12rem;
        }*/
    }

    &:hover {
        cursor: pointer;
    }

    &__select {
        @include dropdown();
        @include alignText();
        height: 3.875rem;
        background-color: $color-grey;
        cursor: default;

        @media screen and (max-width: $breakpoint-1919) {
            height: 3.2rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            height: 2.9rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 15.125rem;
            font-size: 0.813rem;
        }

        &--fixed-width {
    
            @media screen and (max-width: $breakpoint-768) {
                max-width: 10rem;
            }
        }
    }

    &__select-settings {
        @include dropdown();
        @include alignText();
        height: 2.25rem;
        background-color: $color-grey;

        @media screen and (max-width: $breakpoint-1919) {
            height: 3.2rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            height: 2.9rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 15.125rem;
            font-size: 0.813rem;
        }
    }

    &__default-cursor {
        cursor: default;
    }

    &__select-input {
        width: 96%;
        height: 75%;
        border-radius: $dropdown-radius;
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font14;
        background-color: transparent;
        border: none;
        outline: none;
        color: $color-dark-blue;
    }

    &__select-input:active {
        border: none;
        outline: none;
    }

    &__icon {
        position: absolute;
        right: 1.563rem;
        @media screen and (max-width: $breakpoint-340) {
            right: 0.8rem;
        }
    }

    &__icon {
        position: static;
        right: 1.563rem;
        margin-right: 1rem;
        @media screen and (max-width: $breakpoint-340) {
            right: 0.8rem;
        }
    }

    &__container {
        @include dropdown();
        @include container();
        @media screen and (max-width: $breakpoint-768) {
            width: 18.75rem;
            margin: 0;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
        position: absolute;
        z-index: 3;
        width: 100%;

        /*&--fixed-width{
            max-width: 20rem;
        }*/
    }

    &__content {
        @include dropdown();
        @include container();
        @media screen and (max-width: $breakpoint-768) {
            width: 18.75rem;
            margin: 0;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }
    &__item {
        @include item();
        border-bottom: 0.031rem solid $color-mid-dark-grey;

        &--selected-true {
            @include item();
            background-color: $color-grey;
            border-bottom: 0.031rem solid $color-mid-dark-grey;
        }

        &--padding-left {
            padding-left: 2rem;
            &:hover {
                background-color: $color-grey-f7;
            }
        }

        &--no-cursor {
            cursor: default;
        }
    }

    &__text {
        @include alignText();
        margin-top: 0.7rem;
        height: 1rem;
    }
}

::-webkit-scrollbar {
    width: 0.375rem;
}
::-webkit-scrollbar-track {
    background: $color-mid-dark-grey;
}
::-webkit-scrollbar-thumb {
    background: $color-grey-c4;
}
