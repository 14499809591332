.room-post-expanded {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    &__comment-section {
        width: 85%;
        margin-bottom: 2rem;
        margin-left: 3rem;

        @media screen and (max-width: $breakpoint-1023) {
            width: 95%;
        }
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
        }
        @media screen and (max-width: $breakpoint-420) {
            margin-left: 0.75rem;
        }
    }
}
