@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.organization-profile-our-rooms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50rem;
    margin-top: 1rem;

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
    }

    &__title-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;

        @media screen and (max-width: $breakpoint-1350) {
            margin-bottom: 1rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            margin-bottom: 0.7rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            margin-bottom: 0.5rem;
        }
    }

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        max-width: 50rem;
        margin-left: 2rem;
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-light-blue;

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-768) {
            font-size: $font16;
        }
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
        }
    }

    &__button {
        margin-right: 1rem;
        min-width: 9.375rem !important;
        height: 3.125rem;

        @media screen and (max-width: $breakpoint-450) {
            min-width: 7.5rem !important;
            height: 2.25rem;
        }
    }

    &__button-content {
        color: white;
        font-size: $font14;
        font-weight: 700;
        font-family: $font-family-inter;
        @media screen and (max-width: $breakpoint-450) {
            font-size: $font12;
        }
    }

    &__empty-item {
        font-size: $font16;
        font-weight: 700;
        font-family: $font-family-inter;
        color: $color-mid-dark-grey;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__item {
        width: 100%;
        background-color: $color-white;
        &:hover {
            filter: $hover-brightness-darker;
        }

        &--border {
            border-bottom: solid 0.03rem $color-grey;
        }
    }

    &__button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 3rem;
        margin-bottom: 1rem;
    }

    &__button-size {
        width: 96%;
        height: 3rem;
    }
    &__no-data-text {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
