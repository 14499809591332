@import "../abstracts/variables.scss";

.notifications-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__title-container {
        width: 100%;
        height: 4rem;
        max-width: 50rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-width: 20rem;

        @media screen and (max-width: $breakpoint-1023) {
            background-color: $color-white;
            max-width: none;
            border-bottom: solid 0.03rem $color-grey-c4;
        }
    }

    &__title-invitation-container {
        display: flex;
        justify-content: flex-start;
        justify-self: flex-start;
        align-items: baseline;
        margin-bottom: 1.5rem;
        margin-left: 2rem;
        width: 82%;

        @media screen and (max-width: $breakpoint-1599) {
            width: 86%;
        }

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 1.5rem;
        }
    }

    &__title {
        font-family: $font-family-inter;
        font-size: $font18;
        color: $color-darker-blue;
        font-weight: 700;
        margin-left: 2rem;

        @media screen and (max-width: $breakpoint-500) {
            font-size: $font14;
            margin-left: 1.5rem;
        }

        &--margin-1 {
            margin-left: 0.5rem;
        }
    }

    &__button {
        font-family: $font-family-inter;
        font-size: $font18;
        color: $color-light-blue;
        font-weight: 700;
        cursor: pointer;
        margin-right: 2rem;

        &:hover {
            filter: $hover-brightness;
        }

        @media screen and (max-width: $breakpoint-500) {
            font-size: $font14;
            margin-left: 1.5rem;
            text-align: center;
            margin-right: 1.5rem;
        }
    }

    &__card {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: $card-border-radius;
        max-width: 50rem;
        min-width: 20rem;

        @media screen and (max-width: $breakpoint-1023) {
            max-width: none;
            border-radius: 0;
            box-shadow: none;
        }
    }

    &__content {
        width: 100%;
        max-height: 40rem;
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (max-width: $breakpoint-1023) {
            max-height: none;
            height: 90vh;
        }

        &::-webkit-scrollbar-track {
            background-color: $color-light-grey;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}


.notification-bubble {
    height: 0.4rem;
    width: 0.4rem;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    background-color: #d57467;
  }