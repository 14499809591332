.go-premium-dropdown {
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;
        margin-top: 3rem;
    }

    &__title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 15rem !important;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-gold;

        &--color-blue {
            color: $color-blue-54;
        }
    }

    &__icon {
        background-color: $color-gold;
    }

    &__icon-container {
        transition: all ease-in-out 200ms;
        margin-left: 0.5rem;
        margin-right: 1rem;

        &--clicked {
            transform: rotate(180deg);
        }
    }

    &__divider {
        width: 100%;
        height: 0.047rem;
        background-color: $color-grey-c4;

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__large-text {
        display: flex;
        justify-content: flex-end;
        font-size: $font14;
        font-weight: 700;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        width: 18rem;
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 0;
        }
        @media screen and (max-width: $breakpoint-350) {
            font-size: $font12;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-top: 1rem;

        @media screen and (max-width: $breakpoint-660) {
            flex-direction: column;
            align-items: center;
            justify-content: none;
        }
    }
}
