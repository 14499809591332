@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.text-input {
    @include input();

    &--extra-small {
        height: 2.5rem;
        max-width: 12.5rem;
        @media screen and (max-width: $breakpoint-768) {
            max-width: none;
        }
    }

    &--small-40 {
        height: 2.5rem;
    }

    &--small {
        max-width: 15.813rem;
        @media screen and (max-width: $breakpoint-1919) {
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            height: 2.7rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            max-width: none;
            height: 3rem;
        }
    }

    &--medium-40 {
        max-width: 17.875rem;
        height: 2.5rem;
    }

    &--medium {
        max-width: 25rem;
        @media screen and (max-width: $breakpoint-1919) {
            height: 3rem;
            padding-left: 1rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            height: 2.7rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            height: 3rem;
        }
    }

    &--large-40 {
        height: 2.5rem;
        max-width: 26.963rem;
        @media screen and (max-width: $breakpoint-768) {
            max-width: none;
        }
    }

    &--large {
        max-width: 32rem;
        @media screen and (max-width: $breakpoint-1919) {
            height: 3rem;
            padding-left: 1rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            height: 2.7rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            height: 3rem;
        }
    }

    &--large-42 {
        height: 2.641rem;
    }
}

input:autofill {
    background: $color-grey;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
    box-shadow: 0 0 0 3.125rem $color-grey inset !important;
    background-color: transparent !important;
    background-clip: content-box !important;
}
