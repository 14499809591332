@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.user-profile-edit-experience-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 46rem;
    min-width: 20rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $box-shadow-overlay;

    @media screen and (min-width: $breakpoint-661) {
        height: 36rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        height: none;
    }

    &__close-icon {
        @include modal-close-icon();
    }

    &__title-container {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__title {
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-light-blue;
        margin-left: 3.5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media screen and (max-width: $breakpoint-660) {
            margin-left: 1.5rem;
            margin-top: 3rem;
            margin-bottom: 1rem;
        }
    }

    &__input-list {
        margin-top: -0.063rem;
        background: $color-white;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 15px 15px;
        max-height: 270px;
        overflow-y: scroll;
        position: fixed;
        top: 154px;
        z-index: 10000000;
        width: inherit;

        @media screen and (max-width: $breakpoint-660) {
            height: 165px;
            margin-top: 100px;
        }
    }

    &__input-list_organization {
        border-bottom: 0.5px solid #c4c4c4;
        padding-top: 1rem;
        padding-bottom: 1.313rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-660) {
            padding-top: 0rem;
            padding-bottom: 1rem;
        }
        &__no-border {
            border-top: 0px;
        }
    }

    &__error-text {
        font-family: $font-family-inter;
        font-size: $font12;
        color: $color-red;
        margin-top: 0.5rem;
        height: 2rem;
        width: 15rem;
        @media screen and (max-width: $breakpoint-660) {
            height: 0;
        }

        &--spacing {
            @media screen and (max-width: $breakpoint-660) {
                padding-bottom: 2rem;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 80%;
        height: 20rem;

        @media screen and (max-width: $breakpoint-660) {
            align-items: center;
        }
    }

    &__double-input-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        @media screen and (max-width: $breakpoint-660) {
            flex-direction: column;
        }

        &--margin-left-02 {
            @media screen and (max-width: $breakpoint-660) {
                margin-left: 2rem;
            }
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-darker-blue;
        margin-bottom: 0.5rem;
        margin-left: 1rem;
        display: flex;

        @media screen and (max-width: $breakpoint-660) {
            margin-top: 1rem;
        }

        &--optional {
            color: $color-grey-c4;
            background-color: white;
            margin-left: 0.5rem;
        }
    }

    &__input-container {
        width: 15.25rem;

        &--margin-right {
            margin-right: 2rem;
            @media screen and (max-width: $breakpoint-660) {
                margin-right: 0;
            }
        }
    }

    &__divider {
        height: 0.05rem;
        width: 100%;
        background-color: $color-grey-c4;
        margin-bottom: 2rem;

        @media screen and (max-width: $breakpoint-660) {
            display: none;
        }
    }

    &__date-container {
        &--margin-right-2 {
            margin-right: 2rem;
        }
    }

    &__checkbox-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-top: 2rem;
    }

    &__checkbox-label {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        margin-left: 0.5rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    &__buttons-container {
        height: 5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        margin-top: 4rem;

        @media screen and (max-width: $breakpoint-660) {
            margin-top: 3rem;
            height: 9rem;
        }

        &--flex-end {
            justify-content: flex-end;
        }
    }

    &__left-button {
        margin-left: 3.5rem;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-red;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-660) {
            margin-left: 1.5rem;
        }

        &:hover {
            filter: $hover-brightness;
        }
    }

    &__right-button {
        margin-right: 3.5rem;
        width: 7rem;
        height: 2.3rem;

        @media screen and (max-width: $breakpoint-660) {
            margin-right: 1.5rem;
        }
    }

    &__custom-error-text {
        font-family: $font-family-inter;
        font-size: $font12;
        color: $color-red;
        margin-left: 4.5rem;
        margin-right: 4.5rem;
        width: calc(100% - 9rem);
        height: 1rem;
        padding-top: 1rem;

        @media screen and (max-width: $breakpoint-660) {
            margin-top: 10rem;
            width: 15rem;
            padding-top: 0;
        }
    }
}
