@import "../variables.scss";

//room-member

@mixin room-member {
    display: flex;
    align-items: center;
    padding-left: 1.375rem;
    box-sizing: border-box;
    font-family: $font-family-inter;
    padding-bottom: 0.5rem;
    padding-top: 0.813rem;

    @media screen and (max-width: $breakpoint-1400) {
        padding-left: 0.775rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        padding-left: 1rem;
    }

    &__text {
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
    }

    &__tag {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.438rem;
        height: 1.188rem;
        background-color: rgba(84, 188, 202, 0.15);
        padding-left: 0.35rem;
        padding-right: 0.35rem;
        width: max-content;
        border-radius: 0.9rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: $color-light-blue;
        text-align: center;
        margin-right: 0.5rem;
    }

    &__tag-container {
        display: flex;
    }

    &__middle-panel {
        padding-left: 0.938rem;
        box-sizing: border-box;
        width: 82.5%;

        @media screen and (max-width: $breakpoint-768) {
            width: 87%;
        }
    }
}

//dashboard-feed

@mixin dashboard-feed {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    height: 100%;
    margin-bottom: 3rem;
    max-width: 72rem;
    min-width: 20rem;
    margin: auto;

    @media screen and (max-width: $breakpoint-1023) {
        width: 100%;
        max-width: 33rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        padding-top: 1.5rem;
    }

    &--margin-top-1 {
        margin-top: 1rem;
    }

    &__tutorial-active {
        height:7rem;
        width: 100%;
    }

    &__header {
        width: 92%;
        margin: auto;

        &--no-content-card {
            width: 100%;
            padding: 1rem;

            @media screen and (max-width: $breakpoint-550) {
                padding-right: 0.5rem;
                padding-left: 0.5rem;
            }
        }
    }

    &__text {
        font-family: $font-family-inter;
        color: $color-darker-blue;

        &--font-size-up-to-25-chars {
            font-size: $font36;
            hyphens: auto;

            @media screen and (max-width: $breakpoint-450) {
                font-size: $font18;
            }
        }

        &--font-size-org {
            font-size: $font36;
            hyphens: auto;
            word-wrap: break-word;

            @media screen and (max-width: $breakpoint-550) {
                font-size: $font28;
            }
        }

        &--width-90 {
            background-color: white;
            box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.26);
            border-radius: 0.62rem;
            width: 100%;
            padding: 1rem;
            box-sizing: border-box;
            font-size: $font14;
            padding-left: 2rem;
            padding-right: 1.4rem;
        }

        &--font-size-36 {
            font-size: $font36;
            hyphens: auto;
            word-wrap: break-word;
        }

        &--font-size-18 {
            font-size: $font18;
        }

        &--font-weight-700 {
            font-weight: 700;
        }

        &--font-weight-500 {
            font-weight: 500;
        }

        &--margin-bottom-06 {
            margin-bottom: 0.65rem;
        }

        &--margin-top-1 {
            margin-top: 1rem;
        }

        &--margin-bottom-27 {
            margin-bottom: 2.7rem;
        }

        &--display-flex {
            display: flex;
            justify-content: space-between;
        }

        &--link {
            color: $color-light-blue;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    &__button {
        width: 7rem;
        height: 1.875rem;
        text-align: center;
        font-family: $font-family-inter;
        border: none;
        border-radius: 0.5rem;
        font-weight: 700;
        position: relative;
        font-size: $font12;
        background-color: $color-grey-c4;
        color: $color-white;

        @media screen and (max-width: $breakpoint-1024) {
            width: 5rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 0.5rem;
        }

        &--organization {
            @media screen and (max-width: $breakpoint-1280) {
                width: 5rem;
            }

            @media screen and (max-width: $breakpoint-420) {
                width: 4.5rem;
            }
        }

        @media screen and (max-width: $breakpoint-1600) {
            font-size: $font12;
        }

        &:hover {
            cursor: pointer;
            filter: $hover-brightness;
        }
        &--link {
            color: $color-light-blue;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    &__sponsor-title {
        display: none;
        margin: auto;

        @media screen and (max-width: $breakpoint-1023) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 92%;
            margin-top: 1rem;
            font-family: $font-family-inter;
            color: $color-darker-blue;
            font-weight: 700;
            font-size: $font18;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-height: 65rem;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 0.5rem;

        @media screen and (max-width: $breakpoint-1023) {
            justify-content: center;
        }
    }

    &__content::-webkit-scrollbar {
        display: none;
        width: none;
        scrollbar-width: 0;
        -ms-overflow-style: none;
    }

    &__sponsor-card {
        display: none;

        @media screen and (max-width: $breakpoint-1023) {
            display: flex;
            width: 100%;
            max-width: 32rem;
            overflow: hidden;
            justify-content: center;
            justify-self: center;
        }
    }
}
