@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.sponsor-card {
    // display: inline-block;
    // justify-content: space-evenly;
    width: 100%;
    height: 100%;
    min-width: 14rem;
    max-width: 21.375rem;
    padding-top: 1.875rem;
    padding-right: 0.5rem;
    padding-left: 0.4rem;
    box-sizing: border-box;

    @media screen and (max-width: $breakpoint-1023) {
        max-width: 32rem;
        width: 100%;
        min-width: 14rem;
    }

    &__loading-card {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        max-width: 19.375rem;
        width: 95%;
        min-width: 14rem;
        height: 19.438rem;
        margin-top: 1.875rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 31rem;
            min-width: 14rem;
        }
    }

    &__slider-card {
        width: 100%;
        min-width: 14.7rem;
        height: fit-content;
        white-space: nowrap;
        margin-bottom: 1rem;
        transform: translateX(0);
        transition: transform 1s ease;
        display: -webkit-inline-box;
        white-space: normal;
        //display: inline-block;

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 32rem;
            width: 100%;
            min-width: 14rem;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        border: 0.063rem solid $color-darker-blue;

        &:hover {
            cursor: pointer;
        }
    }

    &__content {
        width: 100%;
    }
    &__organization {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-top: 5%;
        padding-left: 9%;
        box-sizing: border-box;
        padding-bottom: 0.575rem;

        @media screen and (max-width: $breakpoint-1023) {
            padding-top: 8%;
        }

        @media screen and (max-width: $breakpoint-450) {
            padding-top: 7%;
        }
    }

    &__organization-image {
        align-self: flex-start;
    }

    &__organization-name {
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-size: $font14;
        font-weight: 700;
        align-self: center;
        margin-left: 0.938rem;
        width: 12.5rem;
        overflow: hidden;
        white-space: initial;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__background {
        background-image: url("../../../public/sponsorCardBackground.png");
        background-repeat: no-repeat;
        border-bottom-left-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
        background-size: cover;
    }

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-left: 9%;
        padding-top: 15%;
        box-sizing: border-box;
        padding-right: 4%;
        margin-bottom: 0.25rem;
        font-family: "Inter";
        font-size: 1.25rem;
        color: white;
        font-weight: 700;
        width: 90%;
        hyphens: auto;

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font20;
        }
    }

    &__bold-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        width: 100%;
        hyphens: auto;
    }

    &__description-container {
        text-overflow: ellipsis;
        padding-bottom: 4%;
    }

    &__description {
        font-family: $font-family-inter;
        padding-left: 9%;
        padding-top: 4%;
        box-sizing: border-box;
        font-weight: 500;
        color: $color-white;

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font10;
            width: 12rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font12;
        }

        &--small {
            width: 90%;
            font-size: $font12;
            overflow: hidden;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &--large {
            white-space: initial;
            width: 94%;
            max-height: 5.563rem;
            margin-top: 0.613rem;
            font-size: $font16;
            color: $color-white;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            hyphens: auto;
            @media screen and (max-width: $breakpoint-1700) {
                font-size: $font14;
            }
        }
    }

    &__event-image {
        width: 90%;
        height: 47%;
        max-height: 11.601rem;
        justify-items: center;
        padding-top: 0.813rem;
        margin: auto;
        padding-bottom: 3%;

        @media screen and (max-width: $breakpoint-1023) {
            height: 50%;
            padding-top: 0.9rem;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        max-height: 11.601rem;
        object-fit: cover;
        border-radius: 0.563rem;
    }

    &__text {
        color: $color-blue;
        font-family: $font-family-inter;
        font-weight: 700;
        padding-left: 0.5rem;
        font-size: 1rem;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            filter: $hover-brightness;
        }
    }
}
