//general
@import "../abstracts/mixins/component-mixins.scss";
@import "../abstracts/400fonts.scss";
@import "../abstracts/500fonts.scss";
@import "../abstracts/700fonts.scss";

$font-family-inter: "Inter";
$color-white: white;
$color-red: #d57467;
$color-green: #60A49F;
$color-red-opacity25: rgba(213, 116, 103, 0.25);
$color-dark-blue: #375558;
$color-darker-blue: #1c3e42;
$color-light-grey: #e5e5e5;
$color-mid-dark-grey: #c5c5c5;
$color-grey-c4: #c4c4c4;
$color-grey: #f4f4f4;
$color-grey-f7: #f7f7f7;
$color-grey-9c: #9cc5c2;
$color-light-blue: #60a49f;
$color-lighter-blue: #cdebef;
$color-gold: #e09f53;
$color-yellow: #ffc700;
$color-dark-grey: #687373;
$color-blue: #60a49f;
$color-blue-54: #54bcca;
$color-blue-CD: #cdebef80;
$color-aquamarine: #93bbb9;
$color-darker-blue: #1c3e42;
$color-twitter-blue: #1da1f2;
$color-linkedin-blue: #0077b5;
$color-instagram-purple: #5851db;
$color-white-opacity-20: rgba(100, 100, 100, 0.2);
$color-opacity-15: rgba(84, 188, 202, 0.15);
$color-gold-opacity-01: rgba(255, 199, 0, 0.1);
$color-gold-opacity-02: rgba(255, 199, 0, 0.2);
$color-light-blue-opacity-02: rgba(
    $color: #54bcca,
    $alpha: 0.2,
);
$color-blue-blur: #7dd7d1b0;
$color-overlay-07: rgba(247, 247, 247, 0.75);
$box-shadow-05: rgba(247, 247, 247, 0.5);
$color-gold-opacity-65: rgba(224, 159, 83, 0.65);
$color-gold-opacity-2: rgba(224, 159, 83, 0.2);
$color-blue-opacity-04: rgba(96, 164, 159, 0.4);
$color-blue-opacity-05: rgba(84, 188, 202, 0.05);
$color-white: white;
$tag-background-color-blue: rgba(84, 188, 202, 0.15);
$box-shadow-overlap: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.25);
$hover-brightness: brightness(1.1);
$hover-brightness-darker: brightness(0.95);
$box-shadow-overlay: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.25);
$box-shadow-25: 0 0 0.438rem rgba(0, 0, 0, 0.25);
$font65: 4.063rem;
$font38: 2.375rem;
$font60: 3.75rem;
$font45: 2.813rem;
$font40: 2.5rem;
$font36: 2.25rem;
$font32: 2rem;
$font30: 1.875rem;
$font28: 1.75rem;
$font24: 1.5rem;
$font22: 1.375rem;
$font20: 1.25rem;
$font18: 1.125rem;
$font17: 1.07rem;
$font16: 1rem;
$font14: 0.875rem;
$font15: 0.938rem;
$font13: 0.813rem;
$font12: 0.75rem;
$font11: 0.688rem;
$font10: 0.625rem;
$font-family-Caudex: "Caudex";
$border-radius-circular: 50%;
$border-radius-09: 0.9rem;
$border-radius-05: 0.5rem;
$breakpoint-2300: 143.75rem;
$breakpoint-2100: 131.25rem;
$breakpoint-2000: 125rem;
$breakpoint-1919: 119.938rem;
$breakpoint-545: 34.063rem;
$breakpoint-1962: 122.625rem;
$breakpoint-1900: 118.75rem;
$breakpoint-1841: 115.063rem;
$breakpoint-1800: 112.5rem;
$breakpoint-1763: 110.188rem;
$breakpoint-1700: 106.25rem;
$breakpoint-1610: 100.625rem;
$breakpoint-1600: 100rem;
$breakpoint-1599: 99.938rem;
$breakpoint-1500: 93.75rem;
$breakpoint-1439: 89.938rem;
$breakpoint-1403: 87.688rem;
$breakpoint-1401: 87.563rem;
$breakpoint-1400: 87.5rem;
$breakpoint-1365: 85.313rem;
$breakpoint-1350: 84.375rem;
$breakpoint-1280: 80rem;
$breakpoint-1279: 79.938rem;
$breakpoint-1253: 78.313rem;
$breakpoint-1200: 75rem;
$breakpoint-1199: 74.938rem;
$breakpoint-1099: 68.688rem;
$breakpoint-1050: 65.625rem;
$breakpoint-1024: 64rem;
$breakpoint-1023: 63.938rem;
$breakpoint-900: 56.25rem;
$breakpoint-830: 51.875rem;
$breakpoint-819: 51.188rem;
$breakpoint-768: 48rem;
$breakpoint-769: 48.063rem;
$breakpoint-661: 41.313rem;
$breakpoint-660: 41.25rem;
$breakpoint-600: 37.5rem;
$breakpoint-551: 34.438rem;
$breakpoint-550: 34.375rem;
$breakpoint-500: 31.25rem;
$breakpoint-451: 28.188rem;
$breakpoint-450: 28.125rem;
$breakpoint-420: 26.25rem;
$breakpoint-389: 24.313rem;
$breakpoint-360: 22.5rem;
$breakpoint-350: 21.875rem;
$breakpoint-340: 21.25rem;
$z-index-first-layer: 1;
$z-index-second-layer: 2;
$z-index-third-layer: 3;
$z-index-forth-layer: 4;

//footer
$footer-height: 12.5rem;

//images
$image-profile-border-radius: 50%;
$image-profile-small-width: 3rem;
$image-profile-small-height: 3rem;
$image-profile-small-room: 3.5rem;
$image-profile-medium-small-width: 3.75rem;
$image-profile-medium-small-height: 3.75rem;
$image-profile-medium-width: 4.25rem;
$image-profile-medium-height: 4.25rem;
$image-profile-medium-width-5: 5rem;
$image-profile-medium-height-5: 5rem;
$image-profile-large-width: 7.8rem;
$image-profile-large-height: 7.8rem;
$image-cover-border-radius: 94rem;
$image-cover-large-width: 99rem;
$image-cover-small-width: 50rem;
$image-cover-large-height: 15.2rem;
$image-cover-small-height: 14.6rem;
$image-cover-object-fit: cover;

//radio
$checkmark-radius: 0.5rem;
$radio-width: 1.25rem;
$radio-height: 1.25rem;
$radio-radius: 0.5rem;
$radio-top: -0.125rem;
$radio-left: -0.63rem;
$radio-checked-width: 0.875rem;
$radio-checked-height: 0.875rem;
$radio-border-checked: 0.188rem solid #f7f7f7;
$radio-font-size: 1.125rem;
$radio-font-weight: 500;

//tooltip
$tooltip-margin: 1.88rem;
$tooltip-arrow-size: 0.38rem;
$tooltip-tip-transform: translateX(0) translateY(-50%);
$tooltip-border-radius: 0.69rem;
$tooltip-padding: 0.94rem;
$tooltip-font-size: 0.725rem;
$tooltip-line-height: 1;
$tooltip-white-space: nowrap;
$tooltip-z-index: $z-index-first-layer;

//dropdown
$dropdown-radius: 0.938rem;
$dropdown-width: 33.375rem;
$accordion__selector-width: 43.875rem;
$dropdown-box-shadow: 0rem 0rem 0.25rem rgba(0, 0, 0, 0.2);

//auth
$auth-screen-width: 120rem;
$auth-screen-height: 80rem;
$auth-main-container-height: calc(80rem - $footer-height);
$auth-form-margin-top: 8.25rem;
$auth-form-margin-left: 15.813rem;

//faq
$bottom-border: 0.031rem solid #c4c4c4;
//card
$card-border-radius: 0.62rem;
$card-box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.26);

//textinput

$text-input-border: 0.031rem solid #f4f4f4;
$text-input-hover-border: 0.031rem solid #dedede;
