@import "../variables.scss";

@mixin icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-circular;
    min-width: 3.5rem;
    min-height: 3.5rem;
    max-width: 3.5rem;
    max-height: 3.5rem;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: $color-grey;
    }

    @media screen and (max-width: $breakpoint-420) {
        min-width: 2.5rem;
        min-height: 2.5rem;
        max-width: 2.5rem;
        max-height: 2.5rem;
    }
}

@mixin camera-icon {
    position: absolute;
    top: 0.1rem;
    right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-circular;
    width: 3.5rem;
    height: 3.5rem;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: $color-white-opacity-20;
    }

    @media screen and (max-width: $breakpoint-350) {
        width: 2.5rem;
        height: 2.5rem;
    }
}

@mixin modal-close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-circular;
    position: absolute;
    top: -3rem;
    right: 0.5rem;
    min-width: 3rem;
    min-height: 3rem;
    max-width: 3rem;
    max-height: 3rem;
    cursor: pointer;

    &:hover {
        background-color: $color-grey;
    }

    @media screen and (max-width: $breakpoint-768) {
        top: 0.25rem;
        right: 1rem;
    }
}
