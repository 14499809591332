  @import "../abstracts/variables.scss";

  .speechbubble {
    position: absolute;
    display: inline-block;
    min-height: 5rem;
    width: 20rem;
    height: auto;
    background: $color-white;
    border-radius: $card-border-radius;
    z-index: 10;
    background-color: $color-green;
    padding: 0.5rem;
    color: $color-white;
    font-family: "Inter";
    font-size: $font14;
    font-weight: 400;
    box-shadow: 5px 5px 8px lightblue;

    &__button {
      background-color: white;
      font-family: "Inter";
      border: 0;
      border-radius: 5px;
      color: $color-green;
      margin-left: 0.5rem;
    }

    &__left::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 25px solid transparent;
      border-right-color: $color-green;
      border-left: 0;
      margin-top: -25px;
      margin-left: -25px;
    }

    &__right:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 25px solid transparent;
      border-left-color: $color-green;
      border-right: 0;
      margin-top: -25px;
      margin-right: -25px;
    }

    &__bottom:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 25px solid transparent;
      border-top-color: $color-green;
      border-bottom: 0;
      margin-left: -25px;
      margin-bottom: -25px;
    }

    &__top:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: $color-green;
      border-top: 0;
      margin-left: -20px;
      margin-top: -20px;
    }

    &__top-right:after {
      content: '';
      position: absolute;
      right: 0;
      top: 28%;
      width: 0;
      height: 0;
      border: 25px solid transparent;
      border-left-color: $color-green;
      border-right: 0;
      margin-top: -25px;
      margin-right: -25px;
    }

    &__orga-room-type {
      left: 5rem;
      top: 5rem;
    }

    &__orga-room-edit {
      display: inline-flex;
      flex-direction: column;
      position: relative;
      margin-left: 0.5rem;
    }

    &__admin {
      left: 20rem;

      @media screen and (max-width: $breakpoint-1599) {
        left: 11rem;
      }

      @media screen and (max-width: $breakpoint-1400) {
        left: 12rem;
      }
    }

    &__header-profile-avatar {
      top: 0.25rem;
      right: 8rem;
    }

    &__room-invite-button {
      left: 20rem;
      top: 64%;

      @media screen and (min-width: $breakpoint-1350) {
        left: 25rem;
      }

      @media screen and (min-width: $breakpoint-2000) {
        left: 30rem;
      }
      @media screen and (min-width: $breakpoint-2100) {
        left: 33rem;
      }

      @media screen and (min-width: $breakpoint-2300) {
        left: 45rem;
      }
    }

    &__header-profile-chat {
      top: 0.25rem;
      right: 23rem;
    }

    &__header-search {
      top: 8rem;
    }

    &__profile-card {
      left: 21%;

      @media screen and (max-width: $breakpoint-1599) {
        left: 25%;
      }
    }

    &__feed {
      @media screen and (max-width: $breakpoint-1023) {
        display: none;
      }
    }

    &__feed::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 25px solid transparent;
      border-top-color: $color-green;
      border-bottom: 0;
      margin-left: -25px;
      margin-bottom: -25px;
    }

    &__event {
      right: 23rem;

      @media screen and (max-width: $breakpoint-1599) {
        right: 25rem;
      }

      @media screen and (max-width: $breakpoint-1400) {
        right: 22rem;
      }

      @media screen and (max-width: $breakpoint-1024) {
        right: 20rem;
      }

      @media screen and (max-width: $breakpoint-1023) {
        display: none;
      }
    }

    &__roomlist {
      left: 20%;

      @media screen and (max-width: $breakpoint-1599) {
        left: 25%
      }

      @media screen and (max-width: $breakpoint-1023) {
        display: none;
      }
    }

    &__roomlist::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 25px solid transparent;
      border-right-color: $color-green;
      border-left: 0;
      margin-top: -25px;
      margin-left: -25px;
    }

    &__row {
      display: flex;
      align-items: center;
      min-height: 4.8rem;
      padding: 0.25rem;
      font-family: "Inter";

      &__center {
        justify-content: center;
      }
    }

    &__right-panel {
      flex-grow: 1;
      padding-left: 0.5rem;
      font-size: $font12;
    }

    &__bottom-panel {
      margin-top: 0.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: $font12;
      font-weight: 300;
      text-align: center;
    }

    &__bottom-link {
      cursor: pointer;
      margin-right: 1rem;
      margin-left: 1rem;
      font-size: $font14;
      font-weight: 400;
    }

    &__room-name {
      color: $color-red;
      font-size: $font13;
      white-space: nowrap;
    }

    &__room-description {
      font-weight: 300;
    }

    &__stat {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }

    &__room-type {
      display: flex;
      justify-content: flex-end;
    }
  }