@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.organization-accout-settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*justify-content: space-between;*/
    width: 100%;
    background-color: transparent;
    min-height: 100%;
    min-width: 20rem;

    @media screen and (max-width: $breakpoint-768) {
        align-items: center;
    }

    &__content {
        width: 27rem;
        margin-left: 2rem;
        //margin-top: 2rem;

        @media screen and (max-width: $breakpoint-768) {
            width: 30rem;
            margin-left: 0;
        }

        @media screen and (max-width: $breakpoint-550) {
            width: 23rem;
        }
        @media screen and (max-width: $breakpoint-420) {
            width: 17rem;
        }
    }

    &__organization-type {
        display: flex;
        flex-direction: row;
        font-family: $font-family-inter;
        font-size: $font12;
        color: $color-darker-blue;
        font-weight: 500;
        margin-top: 2rem;
        margin-left: 1rem;
        margin-bottom: 1.5rem;
    }

    &__link {
        font-family: $font-family-inter;
        font-size: $font14;
        text-decoration: none;
        color: $color-blue;
        margin-left: 1.5rem;

        &:hover {
            filter: $hover-brightness;
        }
    }

    &__button-container {
        height: 8%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        top: 93%;
        width: 100%;
        background-color: white;
        right: 0;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        @media screen and (max-width: $breakpoint-768) {
            padding-right: 0;
            height: 1.25rem;
            top: 5.938rem;
        }
    }

    &__button {
        height: 2.3rem;
        width: 7rem;
        margin-right: 2rem;
        @media screen and (max-width: $breakpoint-768) {
            margin-right: 0;
            width: 100%;
        }
    }
}
