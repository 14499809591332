@import "../abstracts/variables.scss";

.pinboard-comments {
    width: 100%;
    max-width: 50rem;
    font-family: $font-family-inter;
    height: 52.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__text a {
        color: $color-blue;
        word-break: break-all;
    }

    &__close-icon {
        position: absolute;
        top: 0.125rem;
        right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        min-width: 3rem;
        min-height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
        background-color: transparent;
        cursor: pointer;
        margin-top: 0.5rem;
    }

    &__close-icon:hover {
        background-color: $color-grey;
    }

    &__icons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin: auto;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 0.047rem solid #c4c4c4;
        border-top: 0.047rem solid #c4c4c4;
        margin-top: 1.125rem;
        margin-bottom: 1.125rem;
    }

    &__answers {
        font-size: $font14;
        color: $color-darker-blue;
        font-weight: 500;
        @media screen and (max-width: $breakpoint-1500) {
            font-size: $font12;
        }
    }

    @media screen and (max-width: $breakpoint-1599) {
        height: 40rem;
    }

    @media screen and (max-width: $breakpoint-1365) {
        height: 35rem;
    }

    @media screen and (max-width: $breakpoint-1024) {
        height: 32rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        height: 100vh;
    }

    &__container {
        height: 88%;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
    }

    &__loading-container {
        height: 86%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 49rem;
        @media screen and (max-width: $breakpoint-1500) {
            width: 45rem;
        }

        @media screen and (max-width: $breakpoint-1024) {
            width: 40rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
            height: 92%;
        }
    }

    &__content {
        height: 96%;
        width: 98%;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 1rem;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
        width: 49rem;

        @media screen and (max-width: $breakpoint-1500) {
            width: 45rem;
            height: 86%;
        }

        @media screen and (max-width: $breakpoint-1024) {
            width: 40rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
            height: 92%;
        }
    }

    &__organization-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 2.313rem;

        @media screen and (max-width: $breakpoint-1500) {
            padding-left: 2rem;
        }
    }

    &__name {
        color: $color-darker-blue;
        font-size: $font18;
        font-weight: 700;
        align-self: center;
        padding-left: 1.375rem;
        padding-right: 7%;
        word-break: break-word;
        width: 66%;

        @media screen and (max-width: $breakpoint-1500) {
            font-size: $font16;
            width: 59%;
            padding-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-1024) {
            width: 55%;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 62%;
        }

        @media screen and (max-width: $breakpoint-550) {
            width: 56%;
        }
    }

    &__title-container {
        height: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-left: 2.313rem;
        padding-bottom: 0.25rem;
        padding-top: 1.938rem;
        width: 100%;
        font-size: $font20;
        color: $color-blue;

        @media screen and (max-width: $breakpoint-1500) {
            padding-left: 2rem;
        }

        @media screen and (max-width: $breakpoint-1199) {
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-550) {
            font-size: $font16;
        }
    }

    &__title {
        font-weight: 700;
        word-break: break-word;
        width: 89%;
        @media screen and (max-width: $breakpoint-450) {
            width: 80%;
        }
    }

    &__divider {
        width: 90%;
        margin: auto;
        height: 0.047rem;
        background-color: $color-grey-c4;

        &--margin-top-1 {
            margin-top: 1.125rem;

            @media screen and (max-width: $breakpoint-1500) {
                margin-top: 1.125rem;
            }
        }

        &--margin-bottom-08 {
            margin-bottom: 0.875rem;

            @media screen and (max-width: $breakpoint-1500) {
                margin-bottom: 0.575rem;
            }
        }

        &--margin-top-08 {
            margin-top: 0.875rem;

            @media screen and (max-width: $breakpoint-1500) {
                margin-top: 0.575rem;
            }
        }

        &--margin-bottom-26 {
            margin-bottom: 2.625rem;

            @media screen and (max-width: $breakpoint-1500) {
                margin-bottom: 2.625rem;
            }
        }
    }
    &__event-image {
        margin: auto;
        margin-top: 0.6rem;
        margin: auto;
        display: flex;
        justify-content: center;
    }

    &__text {
        padding-left: 2.313rem;
        padding-bottom: 0.813rem;
        padding-right: 9%;
        font-weight: 500;
        font-size: $font12;
        color: $color-dark-blue;
        word-break: break-word;
        padding-top: 0.625rem;
        width: 89%;

        @media screen and (max-width: $breakpoint-1500) {
            padding-left: 2rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 81%;
            padding-top: 1.625rem;
        }
    }

    &__image {
        width: 90%;
        height: 100%;
        max-height: 23.625rem;
        object-fit: cover;
        border-radius: 0.563rem;
    }

    &__description {
        padding-left: 2.313rem;
        padding-right: 4%;
        padding-top: 0.613rem;
        font-size: $font16;
        color: $color-dark-blue;

        p > a {
            color: $color-blue;
            word-break: break-all;
        }

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font14;
        }
        @media screen and (max-width: $breakpoint-1500) {
            padding-left: 2rem;
        }
    }

    &__icons {
        box-sizing: content-box;
        display: flex;
        width: 22%;
        align-items: center;

        @media screen and (max-width: $breakpoint-1024) {
            width: 33%;
        }

        @media screen and (max-width: $breakpoint-1024) {
            width: 35%;
        }
    }

    &__edit {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        cursor: pointer;
        width: 2.7rem;
        height: 2.7rem;
        margin-right: 0.3rem;

        &:hover {
            background-color: $color-grey;
        }

        @media screen and (max-width: $breakpoint-1610) {
            width: 2rem;
            height: 2rem;
        }
    }
    &__comment-container {
        margin-left: 1.688rem;
        display: flex;
        width: 93%;
        margin-bottom: 1.688rem;

        @media screen and (max-width: $breakpoint-1024) {
            margin-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            margin-left: 1.688rem;
        }

        @media screen and (max-width: $breakpoint-600) {
            margin-left: 1rem;
        }
    }

    &__text-area-container {
        display: flex;
        justify-items: center;
        border: 0.047rem solid $color-grey-c4;
        margin-left: 1.25rem;
        resize: none;
        border-radius: 0.5rem;
        width: 90%;
        max-width: 40.5rem;
        align-items: flex-end;
    }

    &__comment-text-area {
        box-sizing: border-box;
        font-family: $font-family-inter;
        font-size: $font14;
        border: transparent;
        border-radius: 0.5rem;
        margin-left: 0.2rem;
        padding-top: 0.5rem;
        resize: none;
        height: 3.875rem;
        color: $color-dark-blue;
        width: 90%;
        max-width: 40.5rem;
    }

    &__send-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 0.2rem;
        min-width: 2.5rem;
        min-height: 2.5rem;
        max-width: 2.5rem;
        max-height: 2.5rem;
        margin-bottom: 0.5rem;
        background-color: transparent;
        cursor: pointer;
        &:hover {
            background-color: $color-grey;
        }
    }

    &__send-icon-disabled {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.2rem;
        min-width: 2.5rem;
        min-height: 2.5rem;
        max-width: 2.5rem;
        max-height: 2.5rem;
        margin-bottom: 0.6rem;
        background-color: transparent;
    }

    &__comment-text-area:focus {
        outline: none;
    }

    &__comments {
        width: 98%;
        margin: auto;

        @media screen and (max-width: $breakpoint-1350) {
            width: 96%;
        }

        @media screen and (max-width: $breakpoint-1024) {
            width: 98%;
        }

        @media screen and (max-width: $breakpoint-1024) {
            width: 95%;
        }
    }
}
