.entity-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-grey;
    border-radius: 0.9rem;
    padding: 0.5rem;
    margin: 0.75rem;

    &__panel-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0.25rem;
    }

    &__left-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-width: 8rem
    }

    &__right-panel {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 1rem;
        min-width: 10rem;
    }

    &__about-text {
        font-family: $font-family-inter;
        font-size: $font12;
    }

    &__hashtags {
        font-family: $font-family-inter;
        font-size: $font12;
        display: flex;
    }

    &__profile-name {
        display: flex;
        justify-content: center;
        font-family: $font-family-inter;
        font-size: $font14;
        line-height: normal;
        color: $color-darker-blue;
        font-weight: 600;
        text-wrap: nowrap;
    }

    &__profile-image {
        cursor: pointer;
        object-fit: $image-cover-object-fit;
        width: 7rem;
        height: 7rem;
        border-radius: $border-radius-circular;
        display: flex;
        padding-bottom: 0.5rem;

        @media screen and (max-width: $breakpoint-1199) {
            width: 6.5rem;
            height: 6.5rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 7.8rem;
            height: 7.8rem;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 6.5rem;
            height: 6.5rem;
            top: 9.5rem;
        }
        @media screen and (max-width: $breakpoint-500) {
            width: 4.5rem;
            height: 4.5rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            height: 4.5rem;
        }
    }

    &__profile-image-container {
        display: flex;
        justify-content: center;
        border-radius: $border-radius-circular;
        left: 7%;
        top: 12rem;
        @media screen and (max-width: $breakpoint-1599) {
            left: 5%;
        }

        @media screen and (max-width: $breakpoint-1439) {
            top: 9rem;
        }

        @media screen and (max-width: $breakpoint-1199) {
            width: 6.5rem;
            height: 6.5rem;
            top: 7.5rem;
        }
        @media screen and (max-width: $breakpoint-1099) {
            top: 5.5rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 7.8rem;
            height: 7.8rem;
            top: 10.5rem;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 6.5rem;
            height: 6.5rem;
            top: 9.5rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            top: 8.5rem;
        }
        @media screen and (max-width: $breakpoint-660) {
            top: 7.5rem;
        }

        @media screen and (max-width: $breakpoint-500) {
            width: 4.5rem;
            height: 4.5rem;
            top: 6.5rem;
            left: 2rem;
        }

        @media screen and (max-width: $breakpoint-350) {
            top: 5.5rem;
            height: 4.5rem;
        }
    }
}