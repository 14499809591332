@import "../abstracts/variables.scss";

.organization-profile-pinboard {
    cursor: pointer;

    &__edit {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        cursor: pointer;
        width: 2.7rem;
        height: 2.7rem;
        background-color: white;

        &:hover {
            background-color: $color-grey;
        }

        &--hover {
            &:hover {
                background-color: white;
            }
            :has( &--hover) {
               background-color: $color-grey;
            }
          }

        @media screen and (max-width: $breakpoint-1610) {
            width: 2rem;
            height: 2rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 2.7rem;
            height: 2.7rem;
        }
    }

    &__content {
        cursor: pointer;
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        text-overflow: ellipsis;
        margin-bottom: 2rem;
        padding-bottom: 5%;
    }

    &__organization-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding-top: 5%;
        margin-left: 9%;
    }

    &__name {
        color: $color-darker-blue;
        font-size: $font14;
        font-weight: 700;
        align-self: center;
        margin-left: 4.5%;
        margin-right: 7%;
        word-break: break-word;
        width: 46%;
        @media screen and (max-width: $breakpoint-1350) {
            margin-right: 3%;
        }
        @media screen and (max-width: $breakpoint-1199) {
            font-size: $font12;
        }
        @media screen and (max-width: $breakpoint-1099) {
            margin-right: 1.5%;
        }

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font14;
            margin-left: 2.5%;
            margin-right: 7%;
            width: 58%;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 57%;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 54%;
        }
        @media screen and (max-width: $breakpoint-389) {
            width: 48%;
        }
    }

    &__title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 9%;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        width: 14rem;
        font-size: $font20;
        color: $color-blue;
        hyphens: auto;

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font18;
            width: 12rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 90%;
            font-size: $font20;
        }
    }

    &__title {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        width: 100%;
        hyphens: auto;
    }

    &__event-content {
        max-width: 19.375rem;
        @media screen and (max-width: $breakpoint-1023) {
            max-width: 30.063rem;
        }
    }

    &__event-image {
        margin: auto;
        width: 90%;
        height: 47%;
        justify-items: center;
        margin-top: 0.6rem;
        margin: auto;
    }

    &__text {
        margin-left: 9%;
        margin-bottom: 0.813rem;
        margin-right: 9%;
        font-weight: 500;
        font-size: $font12;
        color: $color-dark-blue;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font10;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font12;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.563rem;
        max-height: 9.375rem;
    }

    &__description {
        max-height: 5.563rem;
        margin-left: 9%;
        margin-right: 4%;
        margin-top: 0.613rem;
        font-size: $font16;
        color: $color-dark-blue;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        hyphens: auto;
        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font14;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font16;
        }
    }

    &__icons {
        cursor: default;
        padding-top: 0.5rem;
        box-sizing: content-box;
        display: flex;
        justify-content: space-evenly;
        width: 47%;
        margin-left: 5%;
        align-items: center;

        @media screen and (max-width: $breakpoint-1500) {
            width: 36%;
        }
    }
    &__numberBox{
        margin-bottom: 1.25rem;
        margin-left: -0.625rem;
    }

    &__number{
        color: $color-white;
        background-color: $color-red;
        min-width: 0.9rem;
        height: 0.8rem;
        border-radius: 50%;
        padding: 0.13rem;
        font-family: 'Inter';
        font-weight: 700;
        font-size: 0.625rem;
        justify-content: center;
        text-align: -webkit-center;
        display: flex;
    }

}
