@import "../abstracts/mixins/modal-mixins.scss";
@import "../abstracts/variables.scss";

.not-premium-modal {
    width: 33rem;
    height: 15rem;
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include room-modal();

    &__text-container {
        display: flex;
        width: 100%;
        height: 54%;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $breakpoint-768) {
            flex-direction: column;
        }
    }
}
