.go-premium-package-info-item {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    justify-content: space-between;

    &__left-panel {
        width: 65% !important;
        @media screen and (max-width: $breakpoint-550) {
            width: 50% !important;
        }
    }

    &__text-container {
        width: 90%;

        @media screen and (max-width: $breakpoint-550) {
            width: 100%;
        }
    }

    &__text {
        color: $color-darker-blue;
        font-size: $font16;
        font-family: $font-family-inter;
        font-weight: 500;

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font14;
        }
    }

    &__right-panel {
        width: 33% !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: $breakpoint-550) {
            width: 9.5rem !important;
        }
    }

    &__icon {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    &__icon-unchecked {
        margin-left: 1rem;
        margin-right: 1.5rem;
    }
}
