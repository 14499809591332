@import "../abstracts/variables.scss";

.notification-dropdown-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-between;
    background-color: $color-white;
    border-bottom: solid 0.03rem $color-grey-c4;
    cursor: pointer;

    &--border-none {
        border-bottom: none;
    }

    &:hover {
        filter: $hover-brightness-darker;
    }

    &--color-grey {
        background-color: $color-grey;
    }

    &__content {
        display: flex;
        flex-direction: row;
        width: 80%;
    }

    &__left-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: fit-content;
        min-width: 5rem;
        padding-bottom: 0.8rem;
    }

    &__image {
        border-radius: $border-radius-circular;
        width: 3rem;
        height: 3rem;
        object-fit: cover;
        padding: 0.5rem;

        &--size-large {
            width: 4.5rem;
            height: 4.5rem;

            @media screen and (max-width: $breakpoint-500) {
                width: 3rem;
                height: 3rem;
            }
        }
    }

    &__unnread-icon {
        margin-left: 0.5rem;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
        min-width: 20rem;
        width: 100%;

        @media screen and (max-width: $breakpoint-1439) {
            min-width: 16rem;
        }

        @media screen and (max-width: $breakpoint-500) {
            min-width: 0;
        }

        &--margin-top {
            margin-top: 1rem;

            @media screen and (max-width: $breakpoint-500) {
                margin-top: 0;
            }
        }
    }

    &__user-data {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        padding-top: 1rem;
        width: 100%;
        &--font-large {
            font-size: $font16;

            @media screen and (max-width: $breakpoint-500) {
                font-size: $font14;
            }
        }
    }

    &__notification {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-darker-blue;
        min-height: 1.875rem;
        width: 95%;

        @media screen and (max-width: $breakpoint-1099) {
            width: 100%;
        }

        &--margin-bottom {
            padding-bottom: 1rem;
        }

        &--font-large {
            font-size: $font14;

            @media screen and (max-width: $breakpoint-500) {
                font-size: $font12;
            }
        }
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        height: fit-content;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
    }

    &__button {
        height: 1.7rem;
        width: 6.8rem;
        margin-right: 0.7rem;

        @media screen and (max-width: $breakpoint-1439) {
            width: 5.8rem;
            margin-right: 0.5rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            width: 5.5rem;
            margin-right: 0.5rem;
        }
    }

    &__right-panel {
        display: flex;
        align-items: center;
        height: fit-content;
        min-width: 4rem;
        height: 100%;
        width: 15%;
        min-height: 5rem;
        justify-content: flex-end;

        &--row {
            flex-direction: row;
            /*@media screen and (max-width: $breakpoint-1199) {
                flex-direction: column;
                padding-top: 1rem;
            }*/
        }

        &--column {
            flex-direction: column;
            padding-top: 1rem;
        }
    }

    &__time {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-grey;
        margin-right: 0.5rem;
        height: 100%;
        text-align: right;
        @media screen and (max-width: $breakpoint-660) {
            font-size: 0.688rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 90%;
        }
    }
}
