.upgrade-confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40rem;
    height: 28rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        height: 100vh;
        justify-content: flex-start;
    }

    &__checkbox {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        align-items: center;
    }

    &__checkbox-text {
        font-family: $font-family-inter;
        font-size: $font14;
        color: $color-darker-blue;
    }

    &__link {
        color: $color-blue;
    }

    &__content {
        width: 90%;
        height: 90%;
        display: flex;
        flex-direction: column;
        overflow: auto;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
    }

    &__container-text {
        display: flex;
        margin-bottom: 0.5rem;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 3rem;
        width: 100%;
        position: relative;
        width: 92%;
        margin-top: 1rem;
        @media screen and (max-width: $breakpoint-768) {
            margin-top: 1rem;
        }
    }

    &__title {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-blue;
    }

    &__icon {
        @include icon;
        position: absolute;
        right: -0.7rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 500;
        color: $color-darker-blue;
        margin-bottom: 2rem;
        margin-top: 2rem;
        max-width: 90%;

        &--bold {
            font-weight: 700;
        }

        &--no-margin {
            margin-bottom: 0;
            margin-top: 0;
        }

        &--no-margin-bottom {
            margin-bottom: 0;
        }

        &--margin-left {
            margin-left: 0.3rem;
        }
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        height: 3rem;
        width: 100%;
        margin-bottom: 2rem;
    }

    &__button {
        width: 8rem;
        height: 2.3rem;
        margin-right: 1rem;
    }
}
