@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.delete-account {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    color: $color-darker-blue;

    @media screen and (max-width: $breakpoint-660) {
        align-items: center;
    }

    &__link {
        text-decoration: none;
        color: $color-blue;
    }

    &__form {
        width: 88%;
        padding-left: 10%;
        margin-bottom: 3rem;

        @media screen and (max-width: $breakpoint-660) {
            width: 95%;
            padding-left: 5%;
            padding-right: auto;
            min-width: 20rem;
            height: 100%;
        }
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 10%;

        @media screen and (max-width: $breakpoint-660) {
            margin-top: 2rem;
        }
    }

    &__button {
        width: 7rem;
        height: 2.3rem;
        margin-right: 2rem;
    }

    &__title {
        @include titles();
        width: 60%;
        margin-left: -1rem;
        padding-top: 3%;

        @media screen and (max-width: $breakpoint-660) {
            width: 90%;
            padding-top: 5%;
            margin-left: 0;
        }
    }

    &__information-title {
        @include titles();
        width: 60%;
        padding-top: 3%;

        @media screen and (max-width: $breakpoint-660) {
            width: 90%;
            padding-top: 4%;
        }
    }

    &__information {
        width: 80%;
        padding-top: 2.5%;
        font-size: $font12;
        font-family: $font-family-inter;
        font-weight: 400;

        @media screen and (max-width: $breakpoint-660) {
            width: 90%;
            padding-top: 3%;
        }
    }

    &__text-area-title {
        @include titles();
        padding-top: 4%;
        padding-bottom: 3%;

        @media screen and (max-width: $breakpoint-660) {
            width: 90%;
            padding-top: 5%;
            padding-bottom: 4%;
        }
    }

    &__text-area {
        width: 65%;
        height: 8rem;
        resize: none;
        background-color: $color-grey;
        border-radius: 0.5rem;
        border: transparent;
        color: $color-dark-blue;
        padding-left: 1rem;
        box-sizing: border-box;

        @media screen and (max-width: $breakpoint-1439) {
            height: 6rem;
        }

        @media screen and (max-width: $breakpoint-660) {
            height: 4rem;
        }

        @media screen and (max-height: 600px) {
            height: 3rem;
        }
    }

    &__text-area:focus {
        outline: none;
    }

    &__error-message {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        margin-bottom: 1.5rem;
        font-family: $font-family-inter;
    }

    &__checkbox {
        display: flex;
        &--padding-45 {
            padding-top: 2.813rem;

            @media screen and (max-width: $breakpoint-768) {
                height: 1rem;
            }
        }
    }

    &__checkbox-text {
        font-size: $font12;
        font-family: $font-family-inter;
        width: 65%;
        margin: 0;
        align-self: center;
    }
}
