@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.create-room-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 44rem;
    min-width: 20rem;
    height: 45rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;
    position: relative;

    @media screen and (max-width: $breakpoint-1599) {
        height: 40rem;
    }
    @media screen and (max-width: $breakpoint-1403) {
        height: 35rem;
    }

    @media screen and (max-width: $breakpoint-1365) {
        height: 31rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        height: 100vh;
    }

    @media screen and (max-width: $breakpoint-550) {
        overflow-y: scroll;
    }

    &__content {
        width: 93%;
        height: 84%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding-left: 4.688rem;
        box-sizing: border-box;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 90%;
            overflow-y: auto;
            height: 50rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            width: 100%;
            padding-left: 5%;
        }
    }

    &__title-container {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        height: 9%;

        @media screen and (max-width: $breakpoint-550) {
            width: 95%;
            height: 4rem;
        }
    }

    &__title {
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-light-blue;
    }

    &__close-icon {
        @include icon();
    }

    &__form-first-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media screen and (max-width: $breakpoint-1599) {
            margin-top: 1rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            flex-direction: column;
        }
    }

    &__room-input-container {
        display: flex;
        flex-direction: column;
        max-width: 15rem;
    }

    &__margin-top-15 {
        margin-top: 1.5rem;
    }

    &__small-text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 700;
        color: $color-darker-blue;
        margin-bottom: 0.7rem;
        margin-left: 1rem;
    }

    &__select-type-container {
        display: flex;
        flex-direction: column;
        margin-left: 4rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 0;
            margin-top: 1.5rem;
        }
    }

    &__types {
        display: flex;
        flex-direction: row;
        width: 9.3rem;
        justify-content: space-between;
        margin-top: 0.5rem;
        margin-left: 1rem;
    }

    &__type {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.2rem;
        height: 1.5rem;
        border-radius: $border-radius-09;
        cursor: pointer;
        user-select: none;

        &:hover {
            filter: $hover-brightness;
        }

        &--background-color-blue {
            background-color: $color-light-blue;
            opacity: 0.4;
        }

        &--background-color-gold {
            background-color: $color-gold;
            opacity: 0.65;
        }

        &--selected {
            width: 4.8rem;
            height: 1.7rem;
            opacity: 1;
        }
    }

    &__about-container {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
    }

    &__max-character-container {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    &__text-area {
        border: none;
        height: 6.125rem;
        width: 100%;
        max-width: 32.375rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 93%;
        }
    }

    &__image-section {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
    }

    &__image-container {
        display: flex;
        flex-direction: row;
        width: 16rem;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $breakpoint-550) {
            justify-content: space-around;
        }

        @media screen and (max-width: $breakpoint-389) {
            width: 12rem;
        }
    }

    &__image {
        width: 4.688rem;
        height: 4.688rem;
        cursor: pointer;
        object-fit: cover;

        &--square {
            cursor: default;
            border-radius: $border-radius-05;
        }
        &--round {
            cursor: default;
            border-radius: 2.5rem;
        }
    }

    &__add-photo {
        width: 4.688rem;
        height: 4.688rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-light-blue-opacity-02;
        border-radius: $border-radius-05;
        cursor: pointer;
        position: relative;

        &:hover {
            cursor: pointer;
            filter: $hover-brightness;
        }
    }

    &__input-file {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: transparent;
        cursor: pointer;

        &::-webkit-file-upload-button {
            visibility: hidden;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }

        &--margin-left-1 {
            font-weight: 500;
            margin-left: 0.625rem;
        }

        &--display-none {
            font-size: $font12;
            @media screen and (max-width: $breakpoint-389) {
                display: none;
            }
        }
    }

    &__manager-section {
        display: none;
        flex-direction: column;
        margin-top: 2rem;
    }

    &__small-title-delete {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 700;
        color: $color-darker-blue;
        margin-bottom: 0.7rem;
        margin-top: 1.5rem;
        margin-left: 1rem;
    }
    &__manager-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__permissions-section {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
    }

    &__checkbox-container {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
        align-items: center;
    }

    &__button-container {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: flex-end;
        margin-top: 3.4rem;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-top: 2.4rem;
            margin-bottom: 2.4rem;
            padding-bottom: 4rem;
        }
    }

    &__button {
        width: 9rem;
        height: 3rem;
        margin-right: 0.5rem;

        @media screen and (max-width: $breakpoint-1365) {
            width: 7.5rem;
            height: 2.3rem;
        }
    }

    &__delete-room-container {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
        align-items: center;
        &:hover {
            cursor: pointer;
            filter: brightness(1.1);
        }
    }

    &__delete-icon {
        margin-right: 1.3rem;
    }

    &__delete-text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-darker-blue;
        margin-right: 0.3rem;
    }

    &__delete-container {
        display: flex;
    }

    &__delete-subtext {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-mid-dark-grey;
        font-style: italic;
    }

    &__error-text {
        max-width: 11rem;
        color: $color-red;
        margin-left: 1rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;

        &--full-width {
            max-width: none;
            margin-left: 0;
            margin-top: 1rem;
        }
    }
}
