@import "../abstracts/variables.scss";

.forgot-password-form {
    font-family: $font-family-inter;
    &__logo {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }
    &__title {
        margin-top: 12.349rem;
        margin-bottom: 1.375rem;
        color: $color-blue;
        @media screen and (max-width: $breakpoint-768) {
            margin-top: 7rem;
        }
    }

    &__text {
        max-width: 27.688rem;
        margin-bottom: 2.438rem;
        color: $color-dark-blue;
    }

    &__input {
        margin-bottom: 2.5rem;
    }

    &__text-email {
        padding-left: 1rem;
        padding-bottom: 0.75rem;
        color: $color-dark-blue;
    }

    &__error-message {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }
    &__button-container {
        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 5rem;
        }
    }

    &__button-size {
        width: 13rem;
        height: 3.25rem;

        @media screen and (max-width: $breakpoint-1599) {
            width: 10rem;
            height: 3rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 8rem;
            height: 2.75rem;
        }
    }
}
