@import "../abstracts/variables.scss";
@import "../abstracts/mixins/layout-mixins.scss";

.room-members {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 6rem;

    @media screen and (max-width: $breakpoint-768) {
        max-width: none;
        height: 100%;
    }

    &__mobile-icon {
        display: flex;
        justify-self: center;
        align-self: center;
        margin-right: 0.5rem;

        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
    }

    &__header {
        font-size: $font14;
        font-weight: 500;
        font-family: $font-family-inter;
        color: $color-red;
        background-color: $color-red-opacity25;
        border-bottom: 0.031rem solid $color-grey-c4;
        height: 3.063rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-768) {
            color: $color-darker-blue;
            background-color: $color-grey;
            justify-content: space-around;
            height: 3.5rem;
        }
    }

    &__search-container {
        width: 79%;
        margin: auto;
        padding-bottom: 1rem;
        padding-top: 1rem;
        box-sizing: border-box;

        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
    }
    &__close-icon {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        min-width: 2.5rem;
        min-height: 2.5rem;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            background-color: $color-grey;
        }

        &--mobile-icon {
            @media screen and (min-width: $breakpoint-768) {
                display: none;
            }
        }
    }

    &__text {
        font-weight: 500;
        @media screen and (max-width: $breakpoint-768) {
            width: 87%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__container {
        flex: 1 1 0;
        height: 85%;
        overflow-y: auto;
        height: calc(100% - 3.063rem);

        &::-webkit-scrollbar-track {
            background-color: $color-light-grey;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
        }
        @media screen and (max-width: $breakpoint-1023) {
            height: 89%;
        }

        @media screen and (max-width: $breakpoint-768) {
            height: 93%;
        }
    }

    &__no-access {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.room-members-item-border {
    @include room-member();
    border-bottom: 0.031rem solid $color-grey-c4;
    &:hover {
        cursor: pointer;
    }
}

.room-members-item-no-border {
    @include room-member();
    &:hover {
        cursor: pointer;
    }
}

.room-members-modal {
    max-width: none;
    height: 29.375rem;
    width: 37rem;

    @media screen and (max-width: $breakpoint-1023) {
        width: 34rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        height: 100vh;
        width: 100%;
    }

    &__mobile-icon {
        display: flex;
        justify-self: center;
        align-self: center;
        margin-right: 0.5rem;
    }

    &__header {
        font-size: $font16;
        font-weight: 500;
        font-family: $font-family-inter;
        border-bottom: 0.031rem solid $color-grey-c4;
        width: 100%;
        display: flex;
        align-items: center;
        color: $color-darker-blue;
        background-color: $color-grey;
        justify-content: space-around;
        height: 3.5rem;
        border-top-left-radius: 0.9rem;
        border-top-right-radius: 0.9rem;

        @media screen and (max-width: $breakpoint-768) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-size: $font14;
            padding-top: 1rem;
        }
    }

    &__close-icon {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        min-width: 2.5rem;
        min-height: 2.5rem;
        background-color: transparent;
        cursor: pointer;
        margin-right: 0.5rem;

        &:hover {
            background-color: $color-grey;
        }
    }

    &__search-container {
        width: 79%;
        margin: auto;
        padding-bottom: 1rem;
        padding-top: 1rem;
        box-sizing: border-box;
    }

    &__text {
        width: 87%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__container {
        height: 69%;
        overflow-x: hidden;
        overflow-y: scroll;

        @media screen and (max-width: $breakpoint-768) {
            height: 79%;
        }
    }
}
