@import "../abstracts/variables.scss";

.single-text-input {
    &__text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-darker-blue;
        padding-left: 1rem;
        margin-bottom: 0.63rem;
        display: flex;

        &--counter {
            display: flex;
            flex-direction: column;
            align-items: end;
            max-width: 26.089rem;

            @media screen and (max-width: $breakpoint-768) {
                max-width: 30.089rem;
            }
        }
    }

    &--margin-top-1 {
        margin-top: 1.2rem;
    }

    &--margin-top-2 {
        margin-top: 2rem;
    }

    &__error-text {
        color: $color-red;
        margin-left: 1rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-right: 1rem;
        }
    }
}
