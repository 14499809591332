@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.notification-settings {
    font-family: $font-family-inter;
    font-size: $font12;
    width: 100%;
    display: flex;
    justify-content: center;
    color: $color-darker-blue;

    &__form {
        min-width: 34rem;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $breakpoint-768) {
            max-width: 25rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            min-width: 25rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            max-width: 21rem;
            min-width: 21rem;
        }

        @media screen and (max-width: $breakpoint-350) {
            max-width: 19rem;
            min-width: 19rem;
            margin-left: 0.75rem;
        }
    }

    &__container {
        padding-left: 4rem;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-self: center;

        @media screen and (max-width: $breakpoint-1919) {
            padding-left: 2.5rem;
        }

        @media screen and (max-width: $breakpoint-1200) {
            padding-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            padding-left: 0rem;
            margin-left: 1rem;
            width: 19rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 4rem;
        }

        @media screen and (max-width: $breakpoint-360) {
            padding-left: 0;
            margin-left: 0;
            align-self: flex-start;
        }
    }
    &__first-row {
        width: 39.5rem;
        display: flex;
        justify-content: space-between;
        padding-bottom: 2.938rem;
        padding-top: 2.875rem;
        margin-left: 1.5rem;

        @media screen and (max-width: $breakpoint-1919) {
            width: 35rem;
        }

        @media screen and (max-width: $breakpoint-1200) {
            width: 30.2rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 8rem;
            padding-bottom: 2rem;
            padding-top: 2rem;
            justify-content: flex-start;
            margin-left: 0;
        }
    }

    &__first-row-start {
        display: flex;
        align-items: center;
    }

    &__first-row-email {
        padding-left: 1.188rem;
        width: 6.438rem;
    }

    &__first-row-end {
        width: 17rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__first-row-immediate {
        padding-right: 1.5rem;

        padding-left: 1rem;
    }

    &__first-row-none {
        padding-right: 1.8rem;
    }

    &__first-row-weekly {
        padding-left: 1.1rem;
    }

    &__title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 35rem;
        display: flex;
        padding-bottom: 2.125rem;
        padding-right: 3.5rem;
        box-sizing: content-box;

        @media screen and (max-width: $breakpoint-1919) {
            width: 33rem;
        }

        @media screen and (max-width: $breakpoint-1200) {
            width: 28rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            flex-direction: column;
            width: 19rem;
            align-items: flex-start;
            padding-right: 0;
        }
    }

    &__email-comment-title {
        @media screen and (max-width: $breakpoint-1200) {
            width: 51%;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
        }
    }
    &__line {
        width: 40rem;
        border-bottom: $bottom-border;
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-1919) {
            width: 35.5rem;
        }

        @media screen and (max-width: $breakpoint-1200) {
            width: 32.5rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            width: 23rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            width: 19rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 17rem;
        }
    }
    &__button-container {
        height: 8%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        top: 93%;
        width: 100%;
        background-color: white;
        right: 0;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        @media screen and (max-width: $breakpoint-768) {
            padding-right: 0;
            height: 1.25rem;
            top: 5.938rem;
        }
    }

    &__button {
        height: 2.3rem;
        width: 7rem;
        margin-right: 2rem;
        @media screen and (max-width: $breakpoint-768) {
            margin-right: 0;
            width: 100%;
        }
    }
}
