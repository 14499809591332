.organization-profile-pinboard-placeholder {
    display: inline-block;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    min-width: 14rem;
    max-width: 19.375rem;
    max-height: 20.625rem;
    font-family: $font-family-inter;
    background: $color-white;
    box-shadow: $card-box-shadow;
    border-radius: 0.62rem;
    margin-bottom: 2rem;
    margin-top: 0.875rem;
    padding-bottom: 5%;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-1023) {
        max-height: 100rem;
        max-width: 50rem;
    }

    &__edit {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;

        width: 2.7rem;
        height: 2.7rem;

        &:hover {
            background-color: $color-grey;
        }

        @media screen and (max-width: $breakpoint-1199) {
            width: 2rem;
            height: 2rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 2.7rem;
            height: 2.7rem;
        }
    }

    &__organization-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding-top: 5%;
        margin-left: 9%;
    }

    &__name {
        color: $color-darker-blue;
        font-size: $font14;
        font-weight: 700;
        align-self: center;
        width: 52%;
        margin-left: 4.5%;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1700) {
            width: 47%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 65%;
            margin-left: 3%;
        }

        @media screen and (max-width: $breakpoint-550) {
            width: 64%;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 61%;
        }
        @media screen and (max-width: $breakpoint-389) {
            width: 55%;
        }
    }

    &__title {
        height: 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 9%;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        width: 14rem;
        font-size: $font20;
        color: $color-blue;
        font-weight: 700;

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font18;
            width: 12rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 14rem;
            font-size: $font20;
        }
    }

    &__subheadline {
        margin-left: 9%;
        font-weight: 500;
        font-size: $font12;
        color: $color-dark-blue;
        word-break: break-all;
        text-overflow: ellipsis;
        margin-bottom: 0.375rem;

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font10;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font12;
        }
    }

    &__add-image {
        width: 90%;
        height: 11.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 0.6rem;
        margin: auto;
        background: rgba(84, 188, 202, 0.15);
        border-radius: 0.563rem;
    }

    &__image-size-text {
        margin-top: 0.478rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-blue;
    }

    &__image-text {
        font-family: $font-family-inter;
        color: $color-blue;
        text-align: center;
        margin-top: 0.7rem;
        font-size: $font20;
    }
}
