.password-modal {
    width: 43rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
    }

    &__input-text {
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-weight: 400;
        padding-left: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &__error {
        width: 80%;
        margin: auto;
        color: $color-red;
        padding-left: 1.25rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font14;
    }

    &__close-icon {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        min-width: 3rem;
        min-height: 3rem;
        background-color: transparent;
        cursor: pointer;
        margin-right: 0.5rem;

        &:hover {
            background-color: $color-grey;
        }
    }

    &__title-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-left: 3.563rem;
        padding-top: 2rem;
        padding-right: 1.5rem;
        padding-bottom: 4.313rem;
        box-sizing: border-box;
        @media screen and (max-width: $breakpoint-420) {
            padding-left: 2rem;
        }
    }

    &__title {
        font-size: $font22;
        color: $color-blue;
        font-family: $font-family-inter;
        font-weight: 700;
    }

    &__input {
        width: 80%;
        margin: auto;
    }

    &__button {
        width: 8.313rem;
        height: 2.313rem;
        margin-top: 4.563rem;
        margin-bottom: 2.063rem;
        margin-right: 2.5rem;
    }
}
