.go-premium-package-info {
    width: 100%;

    &__types-title-container {
        width: 100%;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__types-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 32%;

        @media screen and (max-width: $breakpoint-550) {
            width: 9rem !important;
        }
    }

    &__title {
        font-family: $font-family-inter;

        &--font-weight-500 {
            font-weight: 500;
        }

        &--font-weight-700 {
            font-weight: 700;
        }

        &--font-weight-900 {
            font-weight: 900;
        }

        &--color-gold {
            color: $color-gold;
        }

        &--color-blue {
            color: $color-darker-blue;
        }

        &--font-size-16 {
            font-size: $font16;
        }

        &--font-size-18 {
            font-size: $font18;

            @media screen and (max-width: $breakpoint-550) {
                font-size: $font16;
            }
        }
        &--font-size-60 {
            font-size: $font60;
        }

        &--margin-right-05 {
            margin-right: 0.5rem;
        }

        &--color-grey {
            color: rgba($color: #60a49f, $alpha: 0.5);
        }
    }
}
