@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.settings-privacy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    &__form {
        height: 60%;
        @media screen and (max-width: $breakpoint-768) {
            height: 100vh;
        }
    }

    &__button-container {
        height: 10%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 5rem;

        @media screen and (max-width: $breakpoint-768) {
            position: fixed;
            padding-right: 0;
            height: 1.25rem;
            top: 5.938rem;
            margin-top: 0;
            width: 100%;
        }
    }

    &__button {
        width: 7rem;
        height: 2.3rem;
        margin-right: 2rem;
        @media screen and (max-width: $breakpoint-768) {
            margin-right: 0;
            width: 100%;
        }
    }

    &__title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 4rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-darker-blue;
        margin-bottom: 1.5rem;
        margin-left: 1rem;
    }

    &__icon {
        margin-left: 1rem;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 0;
            margin-right: 0.5rem;
        }
    }

    &__checkbox-container {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 400;
        color: $color-darker-blue;
        width: 80%;
        height: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5.5rem;

        @media screen and (max-width: $breakpoint-450) {
            padding-left: 3.5rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            padding-left: 1.5rem;
        }
    }

    &__checkbox {
        margin-left: 1.5rem;
        margin-right: 1rem;
    }
}
