@import "../abstracts/variables.scss";

.reset-password-form {
    &__logo {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }
    &__single-input {
        margin-bottom: 1.563rem;
    }

    &__title {
        font-family: $font-family-inter;
        margin-top: 9.875rem;
        color: $color-blue;
        font-weight: 700;
        font-size: $font28;
        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font24;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font20;
        }
        @media screen and (max-width: $breakpoint-350) {
            font-size: $font18;
        }
    }

    &__subtitle {
        font-family: $font-family-inter;
        font-size: $font18;
        margin-top: 2.438rem;
        margin-bottom: 1.375rem;
        font-weight: 500;
        color: $color-dark-blue;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font16;
        }
        @media screen and (max-width: $breakpoint-768) {
            font-size: $font14;
        }
    }

    &__small-text {
        font-family: $font-family-inter;
        margin-left: 1rem;
        color: $color-dark-blue;
        margin-bottom: 0.75rem;
    }

    &__button-container {
        margin-top: 2.5rem;
        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 5rem;
        }
    }

    &__button-size {
        width: 15.625rem;
        height: 3.875rem;

        @media screen and (max-width: $breakpoint-1599) {
            width: 12.5rem;
            height: 3rem;
        }
    }

    &__error-message {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }
}
