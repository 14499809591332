@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";
.description-container {
    &__small-text {
        font-size: $font24;
        font-family: $font-family-Caudex;

        @media screen and (max-width: $breakpoint-1919) {
            font-size: $font20;
        }
        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font18;
        }

        &--color-blue {
            color: $color-darker-blue;
        }
        &--font-weight {
            font-weight: 400;
        }
    }

    &__large-text {
        font-size: $font38;
        font-family: $font-family-Caudex;

        @media screen and (max-width: $breakpoint-1919) {
            font-size: $font30;
        }
        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font24;
        }

        &--color-gold {
            color: $color-gold;
        }

        &--font-weight {
            font-weight: 700;
        }
    }
}
