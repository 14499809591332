@import "../abstracts/variables.scss";

.organization-privacy-settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    min-height: 100%;
    min-width: 20rem;

    @media screen and (max-width: $breakpoint-768) {
        align-items: center;
    }

    &__content {
        width: 90%;
        margin-left: 2rem;
        margin-top: 2rem;

        @media screen and (max-width: $breakpoint-768) {
            margin-left: 0;
            width: 30rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            width: 23rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            width: 18rem;
        }
    }

    &__checkbox-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2rem;

        &--display-none {
            display: none;
        }
    }

    &__checkbox-label {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-darker-blue;
        margin-left: 1rem;
    }

    &__button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-768) {
            position: fixed;
            padding-right: 0;
            height: 1.25rem;
            top: 5.938rem;
            margin-top: 0;
            width: 100%;
        }
    }

    &__button {
        width: 7rem;
        height: 2.3rem;
        margin-right: 2rem;
        @media screen and (max-width: $breakpoint-768) {
            margin-right: 0;
            width: 100%;
        }
    }

    &__loading-container {
        width: 100%;
        height: 20rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
