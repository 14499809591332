@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.organization-profile-cover-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding-top: 3rem;

    @media screen and (max-width: $breakpoint-768) {
        padding-top: 3.75rem;
    }

    &__cover-image-container-loading {
        width: 63%;
        height: 18rem;
        display: flex;
        opacity: 0.6;
        border-radius: $border-radius-09;

        filter: brightness(100%);
    }
    &__container {
        position: relative;
        width: 63%;
        height: 22rem;

        @media screen and (max-width: $breakpoint-1700) {
            height: 19rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            width: 54%;
            height: 16rem;
        }

        @media screen and (max-width: $breakpoint-1350) {
            height: 13rem;
        }

        @media screen and (max-width: $breakpoint-1199) {
            height: 11rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 86%;
            height: 16rem;
        }
        @media screen and (max-width: $breakpoint-900) {
            height: 15rem;
        }
        @media screen and (max-width: $breakpoint-819) {
            height: 14rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
            height: 13rem;
        }
        @media screen and (max-width: $breakpoint-661) {
            height: 12rem;
        }
        @media screen and (max-width: $breakpoint-600) {
            height: 11rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            height: 10rem;
        }
        @media screen and (max-width: $breakpoint-500) {
            height: 9rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            height: 8rem;
        }
    }

    &__icon {
        @include camera-icon();
    }

    &__image {
        max-width: 100%;
        min-width: 100%;
        max-height: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: $border-radius-09;

        @media screen and (max-width: $breakpoint-768) {
            border-radius: 0;
        }
    }
}
