.successful-order {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-family-inter;
    text-align: center;
    justify-content: center;
    font-size: $font28;

    @media screen and (max-width: $breakpoint-1599) {
        font-size: $font20;
    }

    @media screen and (max-width: $breakpoint-768) {
        font-size: $font16;
        justify-content: flex-start;
    }

    &__premium-image {
        width: 90%;
        max-width: 38.688rem;
        max-height: 28.688rem;
    }

    &__donate-image {
        max-width: 17.875rem;
        max-height: 17.875rem;
    }

    &__red-text {
        color: $color-red;
        font-weight: 700;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }

    &__text {
        width: 90%;
        max-width: 51.25rem;
        margin-bottom: 4.813rem;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-1599) {
            max-width: 48.25rem;
        }
    }
}
