@import "../abstracts/variables.scss";

.organization-profile-pinboard-container {
    display: inline-block;
    justify-content: space-evenly;
    width: 100%;
    max-height: 100rem;
    padding-top: 1.875rem;
    font-family: $font-family-inter;

    @media screen and (max-width: $breakpoint-1023) {
        max-width: 30rem;
    }

    &__title {
        color: $color-blue;
        font-weight: 700;
        font-size: $font22;
        font-family: $font-family-inter;
    }
}
