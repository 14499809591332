.rooms-dropdown {
    position: absolute;
    top: 3.4rem;
    left: -2rem;
    min-width: 18.5rem;
    max-width: 20.5rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;
    display: flex;
    flex-direction: column;
    z-index: $z-index-third-layer;

    @media screen and (max-width: $breakpoint-550) {
        border-radius: 0;
        box-shadow: none;
        min-width: 100vw;
        max-width: 100vw;
        left: -4.1rem;
        top: 3.3rem;
        border-bottom: solid 0.03rem $color-grey;
    }

    @media screen and (max-width: $breakpoint-389) {
        top: 3.2rem;
    }

    &__title {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-darker-blue;
        border-bottom: solid 0.03rem $color-grey;
        padding-left: 2.25rem;

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__rooms-list {
        width: 100%;
        max-height: 12.5rem;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
    }

    &__room {
        width: 100%;
        min-height: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: solid 0.03rem $color-grey;
        background-color: $color-white;
        padding-left: 1.625rem;
        box-sizing: border-box;
        cursor: pointer;
        padding-right: 0.5rem;

        &:hover {
            filter: $hover-brightness-darker;
        }
    }

    &__room-image {
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
        }
    }

    &__room-name-container {
        max-width: 10rem;
        margin-right: 0.5rem;
    }

    &__room-name {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-dark-grey;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__room-type {
        font-family: $font-family-inter;
        font-size: $font10;
        font-weight: 500;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.5rem;
        height: 1.3rem;
        border-radius: $border-radius-09;
        user-select: none;
        background-color: $color-light-blue;

        &--background-color-blue {
            background-color: $color-blue-opacity-04;
        }

        &--background-color-gold {
            background-color: $color-gold-opacity-65;
        }
    }

    &__add-room {
        width: 100%;
        height: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        background-color: $color-white;
        border-top: solid 0.03rem $color-grey;
        border-bottom-left-radius: $border-radius-09;
        border-bottom-right-radius: $border-radius-09;

        &:hover {
            filter: $hover-brightness-darker;
        }

        @media screen and (max-width: $breakpoint-550) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__add-room-icon {
        margin-left: 1rem;
        margin-right: 0.5rem;
    }

    &__add-room-text {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-dark-grey;
    }

    &__go-premium {
        width: 100%;
        height: 4.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $color-gold-opacity-01;
        border-bottom-left-radius: $border-radius-09;
        border-bottom-right-radius: $border-radius-09;
        cursor: pointer;

        &:hover {
            background-color: $color-gold-opacity-02;
        }

        @media screen and (max-width: $breakpoint-550) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__go-premium-text {
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-gold;
    }
}
