@import "../abstracts/variables.scss";

.image {
    &__cover {
        border-radius: $image-cover-border-radius;
        object-fit: $image-cover-object-fit;

        &--size-small {
            width: $image-cover-small-width;
            height: $image-cover-small-height;
        }

        &--size-large {
            width: $image-cover-large-width;
            height: $image-cover-large-height;
        }
    }

    &__profile {
        border-radius: $border-radius-circular;

        &--holder {
            position: relative;
            display: inline-block;
        }

        &--fit {
            object-fit: $image-cover-object-fit;
        }

        &--size-small {
            width: $image-profile-small-width;
            height: $image-profile-small-height;
        }

        &--pinboard-size-small {
            width: $image-profile-small-width;
            height: $image-profile-small-height;

            @media screen and (max-width: $breakpoint-1023) {
                width: 2.808rem;
                height: 2.808rem;
            }
        }

        &--size-medium-small {
            width: $image-profile-medium-small-width;
            height: $image-profile-medium-small-height;
        }

        &--size-medium {
            width: $image-profile-medium-width;
            height: $image-profile-medium-height;
        }

        &--size-medium-5 {
            width: $image-profile-medium-width-5;
            height: $image-profile-medium-height-5;

            @media screen and (max-width: $breakpoint-1500) {
                width: $image-profile-medium-width;
                height: $image-profile-medium-height;
            }
        }

        &--size-large {
            width: $image-profile-large-width;
            height: $image-profile-large-height;
        }

        &--margin-top-03 {
            margin-top: 0.3rem;
        }
        &--status-online {
            width: 10px;
            height:10px;
            border-top-left-radius: 50% 50%;
            border-top-right-radius: 50% 50%;
            border-bottom-right-radius: 50% 50%;
            border-bottom-left-radius: 50% 50%;
            border: 1px solid #687373;
            margin-bottom: 2px;
            background-color: #89bcb9;
            top: 0;
            right: 0;
                
            position: absolute;
        }

        &--status-offline {
            width: 10px;
            height:10px;
            border-top-left-radius: 50% 50%;
            border-top-right-radius: 50% 50%;
            border-bottom-right-radius: 50% 50%;
            border-bottom-left-radius: 50% 50%;
            border: 1px solid #687373;
            margin-bottom: 2px;
            background-color: white;
            top: 0;
            right: 0;
                
            position: absolute;
        }
    }
}
