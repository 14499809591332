@import "../abstracts/variables.scss";

.go-premium-card-long {
    width: 31rem;
    height: 4.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.03rem solid $color-gold;
    background-color: $color-gold-opacity-01;
    border-radius: $border-radius-05;
    margin-top: 2rem;
    cursor: pointer;

    &:hover {
        background-color: $color-gold-opacity-02;
    }

    @media screen and (max-width: $breakpoint-1279) {
        width: 27rem;
        height: 3.75rem;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 31rem;
        height: 4.25rem;
    }
    @media screen and (max-width: $breakpoint-660) {
        width: 27rem;
        height: 3.75rem;
    }
    @media screen and (max-width: $breakpoint-550) {
        width: 22rem;
        height: 3.75rem;
    }
    @media screen and (max-width: $breakpoint-450) {
        width: 17.5rem;
        height: 5rem;
    }

    &__content {
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        @media screen and (max-width: $breakpoint-450) {
            display: flex;
            flex-direction: column;
            align-content: center;
        }
    }

    &__icon {
        display: flex;

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__icon-small {
        display: flex;

        @media screen and (min-width: $breakpoint-551) {
            display: none;
        }
    }

    &__text {
        font-family: $font-family-inter;
        color: $color-gold;
        font-size: $font20;
        font-weight: 700;

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font20;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-550) {
            font-size: $font14;
        }
    }
}

.go-premium-card-tall {
    width: 14.3rem;
    height: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.06rem solid $color-gold;
    background-color: $color-gold-opacity-01;
    border-radius: $border-radius-05;
    margin-top: 0.7rem;
    cursor: pointer;
    margin-bottom: 1.875rem;
    box-shadow: $card-box-shadow;

    &:hover {
        background-color: $color-gold-opacity-02;
    }

    @media screen and (max-width: $breakpoint-550) {
        width: 14.3rem;
        height: 6rem;
    }

    &__content {
        width: 90%;
        height: 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    &__icon {
        display: flex;

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__icon-small {
        display: flex;

        @media screen and (min-width: $breakpoint-551) {
            display: none;
        }
    }

    &__text {
        font-family: $font-family-inter;
        color: $color-gold;
        font-size: $font18;
        font-weight: 700;
    }
}
