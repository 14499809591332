.payment-settings {
    padding-left: 2.938rem;
    font-family: $font-family-inter;

    @media screen and (max-width: $breakpoint-768) {
        padding-left: 0;
        margin: auto;
        width: 100%;
    }
    &__loading-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__image {
        width: 14.576rem;
        height: 14.243rem;
        margin-top: 3.813rem;
        margin-bottom: 2.938rem;

        @media screen and (max-width: $breakpoint-768) {
            margin-top: 1.813rem;
            margin-bottom: 1.938rem;
        }
    }

    &__text-container {
        display: flex;
        margin-top: 10%;

        @media screen and (max-width: $breakpoint-1439) {
            margin-top: 14%;
        }

        @media screen and (max-width: $breakpoint-768) {
            justify-content: center;
            margin-top: 5%;
        }

        @media screen and (max-width: $breakpoint-420) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__red-text {
        font-weight: 700;
        color: $color-red;
        font-size: $font14;

        &--margin-left-02 {
            margin-left: 0.2rem;
        }

        &--margin-left-06 {
            margin-left: 1.6rem;
            @media screen and (max-width: $breakpoint-768) {
                margin-left: 0%;
            }
        }

        &--align-center {
            text-align: center;
        }

        &--text-decoration {
            text-decoration: underline;
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__gold-text {
        font-weight: 700;
        color: $color-gold;
        font-size: $font14;

        &--margin-left-02 {
            margin-left: 0.2rem;
        }

        &--text-decoration {
            text-decoration: underline;
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__text {
        color: $color-darker-blue;
        font-size: $font14;
    }

    &__center-container {
        margin-left: 14%;

        &--payment-container {
            width: 14.576rem;
            margin-bottom: 1rem;
            @media screen and (max-width: $breakpoint-768) {
                width: 100%;
            }
        }

        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            margin-left: 0%;
            flex-direction: column;
            align-items: center;
        }
    }

    &__text-container-center {
        width: 82%;
        padding-top: 1.5rem;

        @media screen and (max-width: $breakpoint-768) {
            text-align: center;
            margin: auto;
        }
    }

    &__premium-container {
        border: solid $color-gold 0.03rem;
        border-radius: $border-radius-09;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-left: 1.9rem;
        margin-top: 3.25rem;
        width: 17.5rem;
        height: 11.875rem;

        @media screen and (max-width: $breakpoint-1279) {
            width: 13rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 17.5rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 13rem;
            margin: auto;
            margin-top: 2rem;
        }

        &--color-red {
            border: solid $color-red 0.03rem;
        }
    }

    &__premium-text {
        font-family: $font-family-inter;
        display: flex;

        &--font-weight-400 {
            font-weight: 400;
        }

        &--font-weight-500 {
            font-weight: 500;
        }

        &--font-weight-700 {
            font-weight: 700;
        }
        &--font-weight-900 {
            font-weight: 900;
        }

        &--color-gold {
            color: $color-gold;
        }

        &--color-red {
            color: $color-red;
        }

        &--color-blue {
            color: $color-darker-blue;
        }

        &--font-size-16 {
            font-size: $font16;
            text-align: center;
        }

        &--font-size-18 {
            font-size: $font18;
            text-align: center;
            @media screen and (max-width: $breakpoint-660) {
                font-size: $font16;
            }
        }

        &--font-size-30 {
            font-size: $font30;
            height: 2.7rem;
        }

        &--font-size-60 {
            font-size: $font60;
            @media screen and (max-width: $breakpoint-660) {
                font-size: $font45;
            }
        }

        &--margin-right-05 {
            margin-right: 0.5rem;
        }

        &--margin-top {
            margin-top: 0.688rem;
        }
    }

    &__premium-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1.063rem;
    }

    &__permium-icon {
        margin-right: 0.5rem;
    }

    &__offer-container {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $breakpoint-660) {
            height: 100%;
            justify-content: center;
            align-items: center;
            margin-left: 0.5rem;
        }
    }

    &__month-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        margin-top: 0.5rem;
    }

    &__price-container {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
    }
}
