.room-creators-manager-modal {
    max-width: none;
    height: 29.375rem;
    width: 37rem;

    @media screen and (max-width: $breakpoint-1023) {
        width: 34rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        height: 100vh;
        width: 100%;
    }

    &__card {
        height: 100%;
        width: 100%;
    }
}
