@import "../abstracts/variables.scss";

.user-profile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: transparent;
    margin-bottom: 5rem;
    min-width: 20rem;
    max-width: 57rem;
    margin: auto;

    @media screen and (max-width: $breakpoint-550) {
        background-color: $color-white;
        padding-bottom: 9rem;
    }
}
