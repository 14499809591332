@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.organization-profile-description {
    width: 90%;
    background-color: transparent;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }

    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
    }

    &__title-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;

        @media screen and (max-width: $breakpoint-1350) {
            margin-bottom: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            margin-bottom: 0.7rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            margin-bottom: 0.5rem;
        }
    }

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        max-width: 50rem;
        margin-left: 2rem;
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-red;

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-768) {
            font-size: $font16;
        }
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
        }
    }

    &__icon {
        margin-right: 0.5rem;
        @include icon;
    }

    &__text-container {
        width: 92%;
    }

    &__text {
        width: 100%;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-size: $font14;
        font-weight: 500;
        overflow-y: hidden;
        white-space: normal;

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font12;
        }
    }
}
