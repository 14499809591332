@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.user-profile-card {
    width: 100%;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    box-shadow: $box-shadow-overlay;
    border-radius: $border-radius-09;

    &__search-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 92%;
        max-height: 8rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    &__search-options {
        display: flex;
        justify-content: center;
        width: 100%;
        max-height: 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }

    &__filter-item {
        height: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: $font16;
        font-weight: 700;
        font-family: $font-family-inter;
        color: $color-mid-dark-grey;
        cursor: pointer;
        border-bottom: solid 0.2rem transparent;
        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
        }

        &--selected {
            border-bottom: solid 0.2rem $color-light-blue;
            color: $color-light-blue;
        }
    }

    &__search-input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__content {
        height: 77%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1rem;
        margin-top: 1rem;
        max-height: 35rem;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 25%;
        width: 96%;
    }
}
