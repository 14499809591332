@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.header-mobile-profile-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: $font-family-inter;
    height: 100%;
    width: 100%;

    &__left-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: center;
        height: 60%;
        width: 67%;
    }

    &__image {
        border-radius: 50%;
        object-fit: cover;
        width: 4rem;
        height: 4rem;

        @media screen and (max-width: $breakpoint-420) {
            width: 3rem;
            height: 3rem;
        }
    }

    &__text {
        @include label-style();
        &--background-color-red {
            background-color: $color-red;
        }
        &--background-color-gold {
            background-color: $color-gold;
        }
    }

    &__subtext {
        word-break: break-word;
        @include label-name-style();
        &--font-weight-400 {
            font-weight: 400;
        }

        &--font-weight-700 {
            font-weight: 700;
        }
    }
}
