@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.switch {
    position: relative;
    display: inline-block;
    min-width: 2.5rem !important;
    min-height: 1.5rem !important;
    max-width: 2.5rem !important;
    max-height: 1.5rem !important;
}

.input {
    opacity: 0;
    height: 0;
    width: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: $border-radius-09;
    background-color: $color-red;
    transition: all 300ms;

    &::before {
        position: absolute;
        content: "";
        max-height: 1.15rem !important;
        max-width: 1.15rem !important;
        min-height: 1.15rem !important;
        min-width: 1.15rem !important;
        left: 0.13rem !important;
        bottom: 0.2rem !important;
        background-color: $color-white;
        transition: all 300ms;
        border-radius: $border-radius-circular;
    }
}

.input:checked + .slider {
    background-color: $color-light-blue;
}

.input:checked + .slider::before {
    transform: translateX(1.1rem);
}
