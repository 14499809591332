@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.all-rooms-card {
    background: $color-white;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.15);
    border-radius: 0.938rem;
    padding: 1.625rem;

    &__loading-container {
        height: 81.25rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 88%;
        margin: auto;
        margin-top: 2rem;
    }

    @media screen and (max-width: $breakpoint-550) {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    &__title {
        font-size: $font18;
        color: $color-blue;
        font-weight: 700;
        font-family: $font-family-inter;
        padding-bottom: 1.3rem;
    }

    &__search-input {
        width: 59%;
        margin: auto;
        margin-bottom: 1.5rem;
        @media screen and (max-width: $breakpoint-1500) {
            width: 70%;
        }
    }
}

.rooms-card-wrapper {
    width: 100%;
    padding-bottom: 1rem;
    padding-top: 1.25rem;
    border-top: solid 0.03rem $color-grey-c4;
    &:hover {
        cursor: pointer;
    }
}

.rooms-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__no-data-text {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__event-description {
        font-size: $font14;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        word-break: break-word;
        display: flex;
        padding-left: 8%;
        margin-left: 3.5rem;
        padding-top: 0.5rem;
        @media screen and (max-width: $breakpoint-550) {
            font-size: 0.813rem;
            padding-left: 4%;
        }
    }

    &__description {
        font-size: $font14;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        word-break: break-word;
        display: flex;
        padding-left: 7%;
        margin-left: 3.5rem;
        padding-top: 0.5rem;

        @media screen and (max-width: $breakpoint-1599) {
            padding-left: 6.7%;
        }

        @media screen and (max-width: $breakpoint-1500) {
            padding-left: 6.5%;
        }

        @media screen and (max-width: $breakpoint-1279) {
            padding-left: 6.2%;
        }

        @media screen and (max-width: $breakpoint-1023) {
            padding-left: 6.8%;
        }

        @media screen and (max-width: $breakpoint-768) {
            font-size: 0.813rem;
            padding-left: 6.6%;
        }
        @media screen and (max-width: $breakpoint-550) {
            font-size: 0.813rem;
            padding-left: 3%;
        }
    }

    &__members {
        @include members();
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font12;
        }
    }

    &__members-container {
        @include members-container();
        height: 1.5rem;
        margin-bottom: 0.4rem;
    }
}

.rooms-card-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;

    &__image {
        width: $image-profile-small-room;
        height: $image-profile-small-room;
        object-fit: $image-cover-object-fit;
        border-radius: $border-radius-circular;
    }

    &__left-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 4%;
        margin-left: 4%;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 0;
        }
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    &__right-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.5rem;
    }

    &__text,
    &__textMore25 {
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-weight: 700;
        width: 100%;

        &--blue {
            color: $color-blue;
        }
    }

    &__text {
        font-size: $font16;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font14;
        }
    }

    &__textMore25 {
        font-size: $font16;
        width: 100%;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font14;
        }
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font11;
        font-weight: 700;
        color: $color-darker-blue;
        width: 100%;
        @media screen and (max-width: $breakpoint-1599) {
            max-width: none;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: fit-content;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }
    }

    &__right-text {
        font-family: $font-family-inter;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.4rem;
        font-weight: 700;
        width: fit-content;
        min-width: 3.75rem;
        border-radius: 0.9rem;
        font-size: $font10;
        color: $color-white;
        text-align: center;

        @media screen and (max-width: $breakpoint-550) {
            width: 2.9rem;
        }

        &--private {
            background-color: $color-gold-opacity-65;
        }

        &--public {
            background-color: $color-blue-opacity-04;
        }
    }
}
