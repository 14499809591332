.room-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 3.5rem;
    align-items: center;

    &--active-notice {
        min-height: 6rem;
    }
}

.room-header {
    min-height: 3.5rem;
    max-height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;
    width: calc(100% - 1rem);
    position: relative;
    flex-wrap:wrap;

    @media screen and (min-width: $breakpoint-1024) {
        margin-top: 0;
    }

    &__notice {
        width: 100%;
        height: 2.5rem;
        border-radius: $border-radius-09;
        background-color: $color-blue-CD;
        border: solid 0.03rem $color-blue-54;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 0.875rem;
        font-family: Inter;
        color: #375558;
        white-space: pre-wrap;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    &__notice a {
        font-weight: 500;
        font-size: 0.875rem;
        font-family: Inter;
        color: #375558;
        white-space: pre-wrap;
        word-break: break-all;
    }

    &__room-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 4rem;
    }

    &__dropdown-overlay {
        &--breakpoint-768 {
            @media screen and (max-width: $breakpoint-768) {
                position: fixed;
                top: 8.8rem;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color-overlay-07;
                z-index: $z-index-first-layer;
            }
        }

        &--breakpoint-550 {
            @media screen and (max-width: $breakpoint-550) {
                position: fixed;
                top: 8rem;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $color-overlay-07;
                z-index: $z-index-first-layer;
            }
        }
    }

    &__rooms-dropdown-container {
        position: relative;
    }

    &__arrow-icon {
        @include icon;

        transition: all ease-in-out 200ms;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        &--selected {
            transform: rotate(180deg);
        }

        @media screen and (max-width: $breakpoint-1024) {
            min-width: 2.5rem;
            min-height: 2.5rem;
            max-width: 2.5rem;
            max-height: 2.5rem;
        }
    }

    &__name-container {
        max-width: 43rem;

        @media screen and (max-width: $breakpoint-1400) {
            max-width: 25rem;
        }

        @media screen and (max-width: $breakpoint-819) {
            max-width: 16rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            max-width: 13rem;
        }

        @media screen and (max-width: $breakpoint-389) {
            max-width: 10rem;
        }
    }

    &__name {
        font-family: $font-family-inter;
        font-size: $font24;
        font-weight: 700;
        color: $color-red;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1400) {
            font-size: $font18;
        }

        @media screen and (max-width: $breakpoint-900) {
            word-break: break-word;
        }

        @media screen and (max-width: $breakpoint-830) {
            font-size: $font16;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font15;
        }

        @media screen and (max-width: $breakpoint-500) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-340) {
            font-size: $font12;
        }

        &--margin-left-75 {
            margin-left: 0.75rem;
        }
    }

    &__type {
        font-family: $font-family-inter;
        font-size: $font10;
        font-weight: 400;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.125rem;
        height: 1.563rem;
        border-radius: $border-radius-09;
        user-select: none;
        margin-left: 0.75rem;
        margin-right: 0.75rem;

        &--background-color-blue {
            background-color: $color-blue-opacity-04;
        }

        &--background-color-gold {
            background-color: $color-gold-opacity-65;
        }

        @media screen and (max-width: $breakpoint-420) {
            display: none;
        }
    }

    &__edit-icon {
        @include icon;
        //justify-content: flex-start;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        @media screen and (max-width: $breakpoint-1024) {
            min-width: 2.5rem;
            min-height: 2.5rem;
            max-width: 2.5rem;
            max-height: 2.5rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__menu-icon {
        @include icon;
        min-width: 3rem;
        min-height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
        margin-right: 1rem;
        margin-right: 1rem;
    }

    &__menu {
        justify-content: flex-end;
        display: flex;
        margin-left: auto;
    }

    &__button {
        width: 10.5rem;
        height: 2.25rem;

        &--margin-right-3 {
            margin-right: 3rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__button-icon {
        margin-right: 0.5rem;
        margin-bottom: -0.1rem;
    }

    &__button-container {
        display: flex;
        margin-right: 1rem;
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__lower-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 3rem;
        cursor: pointer;
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-white;
        font-weight: 700;

        &--zindex-2 {
            z-index: 2;
        }

        &:hover {
            filter: $hover-brightness;
        }

        &--color-gold {
            background-color: $color-gold;
        }

        &--color-blue {
            background-color: $color-light-blue;
            &:hover {
                filter: none;
            }
            cursor: default;
        }

        &--display-none {
            display: none;
        }

        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
    }

    &__small-button-container {
        display: flex;
        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
    }

    &__small-button {
        width: 7.5rem;
        height: 2.4rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 6rem;
            height: 2.2rem;
        }

        &--margin-right {
            margin-right: 0.4rem;
        }
    }

    &__icon {
        margin-right: 0.5rem;
    }
}
