@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.add-organization-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40rem;
    height: 43rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;

    @media screen and (max-width: $breakpoint-1610) {
        height: 40rem;
    }

    @media screen and (max-width: $breakpoint-1365) {
        height: 35rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        height: 100vh;
    }

    &__content {
        width: 82%;
        height: 39rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 2rem;
        margin-top: 1.5rem;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            height: 33rem;
        }
        @media screen and (max-width: $breakpoint-1365) {
            height: 30rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            height: 39rem;
        }

        @media screen and (max-width: $breakpoint-600) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__title-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 82%;
        margin-top: 1.5rem;

        @media screen and (max-width: $breakpoint-600) {
            margin-top: 3rem;
            width: 90%;
        }
    }

    &__title {
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-light-blue;
    }

    &__icon {
        position: absolute;
        right: -3rem;
        top: -1rem;
        @include icon();

        @media screen and (max-width: $breakpoint-600) {
            right: -1rem;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 700;
        color: $color-darker-blue;
        margin-left: 1rem;
        margin-bottom: 0.75rem;

        &--margin-left-none {
            @media screen and (max-width: $breakpoint-600) {
                margin-left: 0;
            }
        }
    }

    &__error-text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-red;
        margin-left: 1rem;
        margin-top: 0.5rem;
        height: 0.7rem;
    }

    &__input-container {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 1rem;

        @media screen and (max-width: $breakpoint-600) {
            max-width: 18.5rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            margin-left: 0.5rem;
        }
    }

    &__large-input {
        border: none;
        background-color: $color-grey;
        border-radius: $border-radius-05;
        outline: none;
        padding-left: 1.4rem;
        padding-top: 0.688rem;
        box-sizing: border-box;
        height: 5rem;
        width: 25.5rem;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        resize: none;
        color: $color-dark-grey;

        @media screen and (max-width: $breakpoint-600) {
            width: 18rem;
        }
    }

    &__permissions-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 2rem;
    }

    &__checkbox-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 1.5rem;
        margin-bottom: 0.75rem;
        margin-top: 1.25rem;
    }

    &__checkbox-label {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-darker-blue;
        margin-left: 0.5rem;
        max-width: 23.5rem;

        @media screen and (max-width: $breakpoint-600) {
            max-width: 16rem;
        }
    }

    &__switch-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 2rem;
        margin-bottom: 0.75rem;
        margin-top: 2rem;

        @media screen and (max-width: $breakpoint-600) {
            margin-left: -2rem;
        }
    }

    &__switch-text {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-light-blue;
        margin-left: 1rem;
        transition: all 150ms;

        &--color-red {
            color: $color-red;
        }

        &--margin-left-15 {
            margin-left: 1.5rem;
        }
    }

    &__button-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 6rem;
        background-color: transparent;
        @media screen and (max-width: $breakpoint-550) {
            margin-bottom: 2.4rem;
        }
    }

    &__button {
        width: 7rem;
        height: 2.3rem;
        margin-right: 1.5rem;
    }
}
