@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.user-profile-description {
    position: relative;
    margin-top: 3rem;
    width: 90%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    box-shadow: $box-shadow-overlay;
    border-radius: $border-radius-09;

    @media screen and (max-width: $breakpoint-550) {
        background-image: url("../../../public/Background.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 1.5rem;
    }

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }

    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
    }

    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
    }

    @media screen and (max-width: $breakpoint-550) {
        width: 100%;
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
    }

    &__content {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__icon {
        @include icon();
        position: absolute;
        top: 0.1rem;
        right: 0.5rem;

        @media screen and (max-width: $breakpoint-1350) {
            position: absolute;
        }
    }

    &__divider {
        height: 0.03rem;
        width: 100%;
        background-color: $color-grey;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        @media screen and (max-width: $breakpoint-550) {
            height: 0;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 45%;
        min-height: 7rem;
        background-color: $color-white;

        @media screen and (max-width: $breakpoint-550) {
            width: 90%;
            height: 48%;
            background-color: transparent;
        }
    }
}
