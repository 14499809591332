@import "../abstracts/variables.scss";

.organization-profile-add-event {
    width: 37.5rem;
    height: 37.8rem;
    min-width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $box-shadow-overlay;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
    }

    &__top-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: 3.5rem;
        margin-top: 1.5rem;
    }

    &__organization-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    &__organization-photo {
        margin-right: 1rem;
    }

    &__organization-name {
        color: $color-darker-blue;
        font-size: $font14;
        font-weight: 700;
        font-family: $font-family-inter;
    }

    &__close-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        min-width: 3rem;
        min-height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
        cursor: pointer;

        &:hover {
            background-color: $color-grey;
        }
    }

    &__content {
        height: 25.6rem;
        width: 90%;
    }

    &__title-input {
        width: 18rem;
        height: 2.5rem;
        margin-top: 1.5rem;
        font-family: $font-family-inter;
        font-size: $font20;
        font-weight: 700;
        color: $color-light-blue;
        border: solid $color-grey 0.05rem;
        border-radius: $border-radius-05;
        outline: none;

        @media screen and (max-width: $breakpoint-550) {
            width: 15rem;
            font-size: $font18;
        }

        @media screen and (max-width: $breakpoint-389) {
            width: 12rem;
            font-size: $font16;
        }
    }

    &__max-character-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: max-content;

        @media screen and (max-width: $breakpoint-550) {
            width: fit-content;
            padding-right: 1rem;
        }
    }

    &__description-input {
        width: 26rem;
        height: 4.5rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-darker-blue;
        resize: none;
        border: solid $color-grey 0.05rem;
        outline: none;
        margin-top: 1rem;
        border-radius: $border-radius-05;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            width: 20rem;
        }

        @media screen and (max-width: $breakpoint-389) {
            width: 16rem;
        }
    }

    &__image-container {
        position: relative;
    }

    &__input-file {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        color: transparent;

        &:hover {
            cursor: pointer;
            filter: $hover-brightness;
        }

        &::-webkit-file-upload-button {
            visibility: hidden;
        }
    }

    &__image-placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 15rem;
        border-radius: $border-radius-05;
        background-color: $color-light-blue-opacity-02;
        margin-top: 1rem;

        &:hover {
            cursor: pointer;
            filter: $hover-brightness;
        }
    }

    &__image-text {
        display: flex;
        margin-top: 1rem;
    }

    &__image {
        width: 100%;
        height: 13rem;
        border-radius: $border-radius-05;
        margin-top: 1rem;
        cursor: pointer;
        object-fit: contain;
    }

    &__buttons_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        height: 3rem;
        margin-top: 3.5rem;
    }

    &__button {
        width: 7rem;
        height: 2.3rem;
    }

    &__delete-button {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-red;
        cursor: pointer;

        &:hover {
            filter: $hover-brightness;
        }
    }

    &__image-size-text {
        margin-top: 0.478rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-blue;
    }

    &__image-text {
        font-family: $font-family-inter;
        color: $color-blue;
        text-align: center;
        margin-top: 0.7rem;
        font-size: $font20;
    }
}
input::file-selector-button {
    visibility: hidden;
}
