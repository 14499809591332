@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.header-mobile-menu {
    width: 64%;
    max-width: 20rem;
    height: 100vh;
    top: 3.8rem;
    right: 0;
    position: absolute;
    border: 0.005rem solid $color-light-grey;
    background-color: $color-white;
    min-width: 16rem;
    z-index: $z-index-third-layer;

    @media screen and (min-width: $breakpoint-1023) {
        display: none;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3.75rem;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font14;
        color: $color-darker-blue;
        border-bottom: 0.03rem solid $color-light-grey;
        cursor: pointer;
        background-color: $color-white;
        text-decoration: none;

        &:hover {
            filter: brightness(0.95);
        }

        &--height-5-5 {
            height: 5.5rem;
        }

        &--hover-color-red {
            &:hover {
                background-color: $color-red;
            }
        }

        &--text-color-white {
            &:hover {
                color: $color-white;
            }
        }

        &--gold {
            background-color: $color-gold-opacity-2;
            border: $color-gold;
            color: $color-gold;

            &:hover {
                filter: $hover-brightness;
            }
        }
    }
}
