.show-tutorial-modal {
    width: 47rem;
    height: 15rem;
    font-family: $font-family-inter;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1.625rem;

    @media screen and (max-width: $breakpoint-1439) {
        width: 40rem;
    }
    @media screen and (max-width: $breakpoint-1350) {
        width: 35rem;
    }
    @media screen and (max-width: $breakpoint-1200) {
        width: 30rem;
        padding-bottom: 0;
    }
    @media screen and (max-width: $breakpoint-550) {
        width: 28rem;
    }
    @media screen and (max-width: $breakpoint-500) {
        width: 25rem;
    }
    @media screen and (max-width: $breakpoint-420) {
        width: 23rem;
    }

    &__title-container {
        display: flex;
        justify-content: space-between;
        padding-top: 1.938rem;
        padding-bottom: 1.938rem;
        align-items: center;
        width: 100%;
    }
    &__content-container {
        height: 14rem;

        @media screen and (max-width: $breakpoint-420) {
            height: 12rem;
        }
    }

    &__title {
        font-size: $font18;
        font-weight: 700;
        color: $color-blue;
        padding-left: 1.938rem;
    }

    &__close-icon {
        @include icon;
        margin-right: 0.5rem;
    }
    &__explanation-info {
        width: 36.759rem;
        height: 3.188rem;
        margin-top: 0.625px;
        align-items: center;
        display: flex;
        @media screen and (max-width: $breakpoint-1350) {
            width: 29.759rem;
        }
        @media screen and (max-width: $breakpoint-1200) {
            width: 25rem;
        }
    }
    &__info {
        font-size: $font14;
        font-weight: 500;
        text-align: center;
    }
    &__video {
        width: 95%;
        height: 21.854rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $breakpoint-1200) {
            height: 15.625rem;
        }
    }
    &__buttons-container {
        flex-direction: row;
        display: flex;
        justify-content: center;
        padding-top: 2.5rem;
    }
    &__button-size {
        height: 2.313rem;
        width: 11.75rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        @media screen and (max-width: $breakpoint-1200) {
            width: 10.75rem;
            margin-left: 0.1rem;
            margin-right: 0.1rem;
        }
    }

    &__checkbox {
        display: flex;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $breakpoint-1200) {
            width: 70%;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 57%;
        }
        @media screen and (max-width: $breakpoint-500) {
            width: 63%;
        }
        @media screen and (max-width: $breakpoint-420) {
            width: 90%;
        }
    }

    &__checkbox-text {
        font-family: $font-family-inter;
        font-size: $font14;
        color: $color-dark-blue;
        margin-top: 0.125rem;
        width: 22.563;
        height: 1.063rem;
    }
}
