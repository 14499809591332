@import "../abstracts/variables.scss";
@import "../abstracts/mixins/modal-mixins.scss";

.room-invitation-link-button {
    max-width: 11rem;
    width: 100%;
    height: 2.25rem;

    &__text {
        font-size: $font12;
        font-weight: 700;
        padding-left: 0.688rem;
        font-weight: 700;
        box-sizing: border-box;
    }

    &__button-content {
        padding-top: 0.563rem;
        padding-bottom: 0.563rem;
        display: flex;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.room-invitation-link-modal {
    @include link-modal();
    font-weight: 500;
    font-size: $font14;
}
