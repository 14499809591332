@import "../abstracts/variables.scss";

.organization-profile-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: $breakpoint-550) {
        margin-bottom: 7rem;
    }

    &__mobile-pinboard-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (min-width: $breakpoint-1024) {
            display: none;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 90%;
        }
    }

    &__mobile-pinboard-title-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 80%;
        max-width: 50rem;
        margin-top: 3rem;

        @media screen and (min-width: $breakpoint-1024) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 90%;
        }
    }

    &__mobile-pinboard-title {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-blue;
        margin-left: 2rem;

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font16;
            margin-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
        }
    }
}
