.not-found {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url("../../../public/Background.png");
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: $breakpoint-550) {
        justify-content: flex-start;
        padding-top: 4rem;
    }

    &__text {
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-weight: 700;
        width: 90%;
        max-width: 42.375rem;
        text-align: center;

        @media screen and (max-width: $breakpoint-768) {
            max-width: 29.375rem;
        }

        &--font-65 {
            font-size: $font65;
            margin-bottom: 1.875rem;
            @media screen and (max-width: $breakpoint-1599) {
                font-size: $font60;
            }

            @media screen and (max-width: $breakpoint-768) {
                font-size: $font40;
            }

            @media screen and (max-width: $breakpoint-550) {
                font-size: $font30;
            }

            @media screen and (max-width: $breakpoint-389) {
                font-size: $font24;
            }
        }

        &--font-30 {
            font-size: $font30;
            margin-bottom: 5.313rem;
            @media screen and (max-width: $breakpoint-1599) {
                font-size: $font24;
                margin-bottom: 3.313rem;
            }

            @media screen and (max-width: $breakpoint-768) {
                font-size: $font18;
            }

            @media screen and (max-width: $breakpoint-389) {
                font-size: $font16;
            }
        }
    }

    &__image {
        max-width: 38.375rem;
        max-height: 18.125rem;
        @media screen and (max-width: $breakpoint-768) {
            max-width: 28.375rem;
            max-height: 8.125rem;
        }
    }
}
