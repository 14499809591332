@import "../abstracts/variables.scss";

.organization-profile-rooms-screen {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: transparent;
    margin-bottom: 5rem;
    min-width: 20rem;

    &__rooms-container {
        display: flex;
        justify-content: center;
        width: 100%;

        @media screen and (max-width: $breakpoint-550) {
            margin-top: 6rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            margin-top: 4rem;
        }
    }
}
