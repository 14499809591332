@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.web-notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: $font-family-inter;
    font-size: $font12;
    min-width: 5.9rem;
    max-width: 10rem;

    &__radio-title {
        padding-right: 3.125rem;
        padding-bottom: 2rem;
    }

    &__radio-container {
        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin-right: 2.188rem;
        }
    }

    &__radio-label {
        display: none;
        margin-bottom: 0.75rem;
        margin-top: 1.25rem;
        color: $color-grey-c4;

        @media screen and (max-width: $breakpoint-768) {
            display: flex;
        }
    }
}
