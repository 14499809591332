.background-wrapper {
    &--user {
        background-image: url("../../../public/Background.png");
    }

    &--organization {
        background-image: url("../../../public/OrganizationBackground.png");
    }
    background-size: cover;
    background-repeat: no-repeat;
}
