@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.signup-form {
    font-family: $font-family-inter;
    width: 100%;

    &__sign-in-text {
        font-size: $font14;
        display: flex;
        color: $color-darker-blue;
        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
        @media screen and (max-width: $breakpoint-600) {
            margin-top: 20%;
        }
        @media screen and (max-width: $breakpoint-420) {
            margin-top: 25%;
        }
    }
    &__link {
        color: $color-light-blue;
        padding-left: 0.2rem;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__logo {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__title {
        color: $color-darker-blue;
        font-weight: 700;
        font-size: $font28;

        @media screen and (max-width: $breakpoint-1919) {
            font-size: $font24;
        }

        &--big-margin {
            margin-top: 3.375rem;
        }

        &--small-margin {
            margin-top: 2.188rem;
        }
    }

    &__small-text {
        margin-left: 1rem;
        margin-bottom: 0.6rem;
        color: $color-darker-blue;
        font-weight: 400;
        @media screen and (max-width: $breakpoint-768) {
            margin-top: 1.3rem;
        }

        &--margin-top {
            margin-top: 1.55rem;
        }
    }

    &__small-text-no-left-margin {
        margin-left: 0.2rem;
        margin-bottom: 0.6rem;
        color: $color-darker-blue;
        font-weight: 400;
        @media screen and (max-width: $breakpoint-768) {
            margin-top: 1.3rem;
        }

        &--margin-top {
            margin-top: 1.55rem;
        }
    }

    &__double-input-container {
        width: 100%;
        display: flex;
        object-fit: contain;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1.55rem;

        @media screen and (max-width: $breakpoint-768) {
            flex-direction: column;
        }
    }

    &__single-input {
        display: block;
        height: fit-content;
        align-items: flex-start;
        margin-top: 1.563rem;
    }

    &__tooltip-container {
        height: 1.563rem;
        display: flex;
        align-items: center;
    }

    &__small-text-tooltip {
        margin-left: 1rem;
        color: $color-darker-blue;
    }

    &__tooltip {
        margin-bottom: 0.5rem;
        position: relative;
    }

    &__button-container {
        margin-top: 1.8rem;

        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__button-size {
        width: 13.125rem;
        height: 3.875rem;

        @media screen and (max-width: $breakpoint-1599) {
            width: 10rem;
            height: 3rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 8rem;
            height: 2.75rem;
        }
    }

    &__name-error {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }
    &__error-message {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }
    &__loading-animation-container {
        display: flex;
        justify-content: space-evenly;
        @include dropdown();
        @include alignText();
        height: 3.875rem;
        background-color: $color-grey;
        position: relative;

        @media screen and (max-width: 1919px) {
            height: 3.2rem;
        }
        @media screen and (max-width: 1599px) {
            height: 2.9rem;
        }
    }
}

.double-input-container {
    &__single-input {
        width: 50%;
        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
        }
        &--margin-right-1 {
            margin-right: 1rem;
            @media screen and (max-width: $breakpoint-768) {
                margin-right: 0;
            }
        }
    }
}
