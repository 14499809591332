@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.photo-dropdown-profile {
    @include photo-dropdown-card();
    position: relative;
}

.photo-dropdown-organization_profile {
    @include photo-dropdown-card();
    position: absolute;
    bottom: -4.5rem;
    left: 1.2rem;

    @media screen and (max-width: $breakpoint-1350) {
        left: 0.8rem;
    }
    @media screen and (max-width: $breakpoint-1023) {
        left: 1.2rem;
    }
    @media screen and (max-width: $breakpoint-450) {
        left: 0.7rem;
    }
}

.photo-dropdown-organization_cover {
    @include photo-dropdown-card();
    position: absolute;
    top: 4rem;
    right: -3rem;

    @media screen and (max-width: $breakpoint-1350) {
        bottom: -1rem;
    }

    @media screen and (max-width: $breakpoint-768) {
        right: 0.5rem;
    }

    @media screen and (max-width: $breakpoint-350) {
        top: 3rem;
    }
}

.photo-dropdown-cover {
    @include photo-dropdown-card();
    position: absolute;
    top: 4rem;
    right: -3rem;

    @media screen and (max-width: $breakpoint-768) {
        right: -1.5rem;
    }

    @media screen and (max-width: $breakpoint-550) {
        right: 0;
    }
    @media screen and (max-width: $breakpoint-350) {
        top: 3rem;
    }
}

.photo-dropdown-room_photo {
    @include photo-dropdown-card();
    position: absolute;
    top: 4rem;
    @media screen and (max-width: $breakpoint-1023) {
        top: 4.5rem;
    }
}
