@import "../abstracts/variables.scss";

.signin-form {
    &__logo {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }
    &__single-input {
        margin-bottom: 1.56rem;
    }

    &__title {
        display: block;
        margin-top: 8.125rem;
        text-decoration: none;
        color: $color-blue;
        font-weight: 700;
        margin-top: 0.6rem;
        font-family: $font-family-inter;

        &--margin-top-8 {
            margin-top: 3rem;
            @media screen and (max-width: $breakpoint-768) {
                margin-top: 8rem;
            }
        }
        &--margin-top-4 {
            margin-top: 4.438rem;
        }

        &__single-input {
            margin-bottom: 1.56rem;
        }

        &--pointer {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__small-text {
        font-family: $font-family-inter;
        margin-left: 1rem;
        color: $color-dark-blue;
        font-weight: 400;
        margin-bottom: 0.75rem;
    }

    &__forgot-password {
        font-family: $font-family-inter;
        text-decoration: none;
        margin-left: 0.5rem;
        color: $color-blue;
        font-weight: 400;
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 1.8rem;
        width: 100%;

        @media screen and (max-width: $breakpoint-768) {
            justify-content: flex-end;
        }
    }

    &__button-size {
        width: 13.125rem;
        height: 3.875rem;

        @media screen and (max-width: $breakpoint-1599) {
            width: 10rem;
            height: 3rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 8rem;
            height: 2.75rem;
        }
    }

    &__error-message {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }

    &__message-container {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
    }

    &__positive-text {
        max-width: 22.188rem;
        font-size: $font12;
        margin-left: 0.5rem;
    }

    &__negative-text {
        max-width: 22.188rem;
        font-size: $font12;
        margin-left: 0.5rem;
        color: $color-red;
    }
}
