.hashtag-input-container {
    background-color: $color-grey;
    border-radius: 0.5rem;
    border: $text-input-border;
    font-size: $font14;
    font-family: $font-family-inter;
    min-height: 2.5rem;
    padding-left: 1.4rem;
    box-sizing: border-box;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    color: $color-blue-54;

    &:hover {
        border: $text-input-hover-border;
        cursor: text;
    }

    @media screen and (max-width: $breakpoint-768) {
        max-width: none;
    }

    &__item {
        background: $color-opacity-15;
        margin: 0.2rem;
        border-radius: 0.531rem;
        padding: 0.3rem;
        word-break: break-all;
    }

    &__item-text {
        margin-right: 0.2rem;
    }

    &__item-remove-button {
        height: 1.125rem;
        width: 1.125rem;
        font-size: $font12;
        background-color: $color-blue-54;
        border-radius: 50%;
        color: $color-white;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
            font-weight: 700;
        }
    }
    &__input {
        background-color: $color-grey;
        color: $color-blue-54;
        font-size: $font14;
        font-family: $font-family-inter;
        min-width: 0.3rem;
        flex-grow: 1;
        border: none;
        outline: none;
    }

    &__input::placeholder {
        color: $color-blue-54;
        font-size: $font12;
        font-family: $font-family-inter;
    }

    &__input:disabled {
        cursor: not-allowed;
    }
}
