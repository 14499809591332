.tabs-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tabs-container .react-tabs__tab-list {
  flex-shrink: 0;
}

.tabs-container .react-tabs__tab-panel {
  flex-grow: 1;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
    height: 100%;
    margin-top: 0;
  
    &__tab-list {
      border-bottom: 1px solid $color-grey-c4;
      padding-left: 5.5rem;
      font-family: $font-family-inter;
      margin-bottom: 0;
      
    }
  
    &__tab {
      display: inline-block;
      border: 1px solid transparent;
      border-bottom: none;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 6px 12px;
      font-size: $font14;
      font-weight: 500;
      cursor: pointer;
  
      &--selected {
        background: #fff;
        background-color: $color-grey;
        border-color: $color-grey-c4;
        color: black;
        border-radius: 5px 5px 0 0;
      }
  
      &--disabled {
        color: GrayText;
        cursor: default;
      }
  
      &:focus {
        outline: none;
  
        &:after {
          content: "";
          position: absolute;
          height: 5px;
          left: -4px;
          right: -4px;
          bottom: -5px;
          background: #fff;
        }
      }
    }
  
    &__tab-panel {
      display: none;
  
      &--selected {
        height: calc(100% - 2.5rem);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &--overflow-hiden {
            overflow: hidden;
        }
      }
    }
  }