.room-event {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: solid $color-grey-c4 0.05rem;

    &__organizatior-panel {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 4rem;
        align-items: center;
        justify-content: space-between;
    }

    &__organizatior-data-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__organizatior-left-panel {
        width: 5rem;
        min-width: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__organizatior-middle-panel {
        max-width: 20rem;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            max-width: 12rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            max-width: 8rem;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__date-container {
        background-color: $color-grey;
        border-radius: $border-radius-09;
        width: fit-content;
        margin-right: 1rem;
    }

    &__date {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-red;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

    &__event-container {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: 2rem;

        @media screen and (max-width: $breakpoint-389) {
            margin-left: 1rem;
        }
    }

    &__event-title {
        font-family: $font-family-inter;
        font-size: $font20;
        font-weight: 700;
        color: $color-light-blue;
        margin-bottom: 0.75rem;
        margin-top: 1.5rem;
    }

    &__event-date-mobile {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-red;
        margin-bottom: 0.75rem;

        @media screen and (min-width: $breakpoint-1200) {
            display: none;
        }
    }

    &__event-content {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: $breakpoint-1199) {
            flex-direction: column;
        }
    }

    &__event-right-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__event-image {
        max-width: 18rem !important;
        max-height: 18rem !important;
        border-radius: $border-radius-05;
        object-fit: cover !important;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-389) {
            max-width: 16rem !important;
            max-height: 16rem !important;
        }
    }

    &__event-date {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-red;
        margin-left: 1rem;
        margin-bottom: 0.5rem;

        @media screen and (max-width: $breakpoint-1199) {
            display: none;
        }
    }

    &__event-description {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-1199) {
            margin-left: 0;
            margin-top: 1rem;
        }
    }

    &__event-button {
        width: 7.5rem;
        height: 2.3rem;
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-1199) {
            margin-left: 0;
            margin-top: 1rem;
        }
    }
}
