@import "../abstracts/variables.scss";

.main-layout {
    &__container {
        display: flex;
        margin-bottom: 7.188rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-bottom: 0;
        }
    }

    &__sponsored-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $color-darker-blue;
        width: 88%;
        font-size: $font16;
        font-family: $font-family-inter;
        font-weight: 700;

        @media screen and (max-width: $breakpoint-1900) {
            width: 90%;
        }

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font14;
        }
    }

    &__sponsored-icon {
        &:hover {
            cursor: pointer;
        }
    }
}

.main-left-panel {
    width: 20%;
    min-width: 12.8rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3.25rem;
    margin-left: 1rem;
    margin-right: 1rem;

    &__container {
        width: 80%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__text {
        width: 88%;
        font-size: $font16;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-weight: 700;

        @media screen and (max-width: $breakpoint-1900) {
            width: 90%;
        }

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font14;
        }
    }

    @media screen and (max-width: $breakpoint-1599) {
        width: 25%;
    }

    @media screen and (max-width: $breakpoint-1023) {
        display: none;
    }
}

.main-middle-panel {
    width: 60%;
    padding-top: 3.25rem;

    @media screen and (max-width: $breakpoint-1599) {
        width: 50%;
    }

    @media screen and (max-width: $breakpoint-1023) {
        width: 100%;
        min-height: calc(100vh - 15.297rem);
    }

    @media screen and (max-width: $breakpoint-768) {
        padding-top: 3.75rem;
    }

    &--no-padding {
        @media screen and (max-width: $breakpoint-550) {
            padding-top: 0;
        }
    }
}

.main-right-panel {
    width: 20%;
    min-width: 12.8rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3.25rem;
    margin-left: 1rem;
    margin-right: 1rem;

    &__container {
        width: 100%;
        min-width: 14.3rem;
        max-width: 20rem;
        display: flex;
        align-items: center;
        flex-direction: column;

        &--overflow {
            overflow: hidden;
        }

        &--size {
            width: 90%;

            @media screen and (max-width: $breakpoint-1279) {
                width: 95%;
            }
        }
    }
    &__text {
        width: 88%;
        font-size: $font16;
        font-family: $font-family-inter;
        font-weight: 700;
        color: $color-darker-blue;
        @media screen and (max-width: $breakpoint-1900) {
            width: 90%;
        }

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font14;
        }
    }

    &__pinboard-title {
        width: 88%;
        color: $color-blue;
        font-weight: 700;
        font-size: $font22;
        font-family: $font-family-inter;

        @media screen and (max-width: $breakpoint-1900) {
            width: 90%;
        }

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font14;
            margin-top: 2rem;
            margin-left: 1rem;
        }
        @media screen and (max-width: $breakpoint-1024) {
            font-size: $font18;
            width: 100%;
            margin-left: 0;
            padding-left: 2rem;
            box-sizing: border-box;
        }

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font16;
        }
        @media screen and (max-width: $breakpoint-550) {
            padding-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
        }
    }

    @media screen and (max-width: $breakpoint-1599) {
        width: 25%;
    }

    @media screen and (max-width: $breakpoint-1023) {
        display: none;
    }
}
