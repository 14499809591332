@import "../abstracts/variables.scss";

.profile-card {
    max-width: 19.375rem;
    width: 100%;
    min-width: 14rem;
    &__container {
        max-width: 19.375rem;
        width: 100%;
        min-width: 14rem;
        height: 6.875rem;
        margin-bottom: 1.5rem;
        cursor: pointer;
    }

    &__loading-card {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        max-width: 19.375rem;
        width: 100%;
        min-width: 14rem;
        height: 6.875rem;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    &__button {
        display: flex;
        flex-direction: row;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-blue;
        cursor: pointer;
        margin-bottom: 1rem;

        &:hover {
            filter: $hover-brightness;
        }
    }
}

.item {
    justify-self: center;
    align-self: center;
    width: 100%;
    border-radius: 0.95rem;
    background-color: $color-white;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0.3rem;
    padding-right: 0.3rem;

    &__subtext-container {
        display: flex;
    }
    &--margin-right {
        margin-right: 0.7rem;
    }

    &__left-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 6rem;
    }

    &__middle-panel {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        box-sizing: border-box;
    }

    &__text,
    &__textMore25 {
        font-family: $font-family-inter;
        font-weight: 700;
        color: $color-darker-blue;
        word-break: break-word;
    }

    &__text {
        font-size: $font16;
        width: 11rem;
        @media screen and (max-width: $breakpoint-1599) {
        }

        @media screen and (max-width: $breakpoint-1350) {
            width: 8rem;
        }
    }

    &__textMore25 {
        font-size: $font13;
        width: 12rem;
        @media screen and (max-width: $breakpoint-1763) {
            font-size: 12px;
            width: 11rem;
        }

        @media screen and (max-width: $breakpoint-1350) {
            width: 9rem;
        }
    }

    &__subtext {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 0.438rem;
        height: 1.188rem;
        font-family: $font-family-inter;
        padding-left: 0.36rem;
        padding-right: 0.36rem;
        padding-top: 0.2rem;
        width: max-content;
        border-radius: 0.9rem;
        font-size: $font12;
        background: rgba(255, 255, 255, 0.6);
        color: $color-blue-blur;
        filter: blur(1px);
        mix-blend-mode: normal;
        text-align: center;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.no-organization-card {
    background: $color-white;
    box-shadow: $dropdown-box-shadow;
    border-radius: $card-border-radius;
    max-width: 19.375rem;
    width: 100%;
    min-width: 14rem;
    height: 6.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    :hover {
        cursor: pointer;
        filter: brightness(1.1);
        font-size: 1.031rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-blue;
        width: 85%;
        word-break: break-word;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }
}
