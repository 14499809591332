@import "../abstracts/variables.scss";

.FAQ {
    background-image: url("../../../public/Background.png");
    background-size: cover;
    background-repeat: no-repeat;
    font-family: $font-family-inter;

    &__card {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: $card-border-radius;
        overflow-y: hidden;
    }

    &__title {
        font-size: $font36;
        padding-top: 3.375rem;
        font-weight: 700;
        color: $color-darker-blue;
        padding-bottom: 0.625rem;

        @media screen and (max-width: $breakpoint-1200) {
            font-size: $font32;
        }

        @media screen and (max-width: $breakpoint-660) {
            font-size: $font28;
        }

        @media screen and (max-width: $breakpoint-450) {
            padding-left: 1rem;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-items: center;
        margin: auto;
        width: 60%;
        margin-bottom: 10rem;

        @media screen and (max-width: $breakpoint-1400) {
            width: 70%;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 80%;
        }

        @media screen and (max-width: $breakpoint-660) {
            width: 90%;
            margin-bottom: 4.5rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 100%;
        }
    }

    &__accordion-container {
        width: 97%;
        margin-left: 1.25rem;

        @media screen and (max-width: $breakpoint-450) {
            width: 100%;
            margin-left: 0;
        }
    }

    &__category-title {
        font-size: $font24;
        padding-bottom: 1.313rem;
        font-weight: 700;
        color: $color-darker-blue;
        padding-top: 2.5rem;

        @media screen and (max-width: $breakpoint-1200) {
            font-size: $font20;
        }

        @media screen and (max-width: $breakpoint-660) {
            font-size: $font18;
        }

        @media screen and (max-width: $breakpoint-450) {
            padding-left: 1rem;
        }
    }

    &__link {
        text-decoration: none;
        color: $color-blue;
    }

    &__paragraph {
        padding-bottom: 0.3rem;
        padding-top: 0.3rem;
    }

    &__text {
        padding-bottom: 0.3rem;
        padding-top: 4.688rem;
        width: 81%;
        margin: auto;
        color: $color-darker-blue;
    }
}
