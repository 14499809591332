@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

$maxImagesNumber: 7;
.new-line {
    margin-bottom: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.mention-span,
.hashtag {
    color: $color-blue-54 !important;
    background-color: $color-blue-CD;
    border-radius: 0.25rem;
    padding: 0 0.375rem 0 0.375rem;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.room-post {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    border-bottom: solid $color-grey-c4 0.05rem;

    &--is_pinned {
        background-color: rgba(245, 251, 252, 1);
    }

    &__left-panel {
        width: 80%;
        height: 100%;

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
        }
    }

    &__right-panel {
        width: 14.843rem;
        position: relative;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: $breakpoint-1023) {
            display: none;
        }
    }

    &__all-reactions-text {
        padding-left: 0.5rem;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-blue;
        padding-top: 0.3rem;
        cursor: pointer;
    }

    &__emoji-image {
        width: 1.125rem;
        height: 1.125rem;
    }

    &__emoji {
        display: flex;
        background: $color-grey;
        border: 1.59px solid $color-mid-dark-grey;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
        margin-left: -0.4rem;

        @media screen and (max-width: $breakpoint-500) {
            margin-left: -0.47rem;
        }
    }

    &__profile-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__user-data-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__time-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 0.7rem;
    }

    &__profile-photo {
        margin-left: 1rem;
        margin-right: 0.75rem;

        @media screen and (max-width: $breakpoint-360) {
            margin-right: 0.5rem;
        }

        &:hover {
            cursor: pointer;
        }

        &--no-cursor {
            &:hover {
                cursor: default;
            }
        }
    }

    &__username-container {
        max-width: 100%;
        margin-right: 1rem;
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;

        &--organization,
        &--admin {
            opacity: 0.8;
            color: white;
            padding-bottom: 0.05rem;
            text-align: center;
            @include role-room();
        }

        &--organization {
            background-color: #e09f53;
            border-radius: 0.9rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &--admin {
            background-color: #d57467;
            padding-left: 1.125rem;
            padding-right: 1.125rem;
            border-radius: 1.25rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            font-size: 0.75rem;
            word-break: break-all;
        }

        @media screen and (max-width: $breakpoint-360) {
            margin-right: 0.3rem;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
        }

        &:hover {
            cursor: pointer;
        }

        &--deleted {
            color: $color-grey-c4;
            &:hover {
                cursor: default;
            }
        }

        @media screen and (min-width: $breakpoint-1199) {
            min-inline-size: max-content;
        }

        @media only screen and (max-width: $breakpoint-1199) and (min-width: $breakpoint-768) {
            min-inline-size: fit-content;
        }
    }

    &__time {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-grey-c4;
        margin-right: 0.75rem;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        @media screen and (min-width: $breakpoint-768) {
            min-inline-size: fit-content;
        }

        @media screen and (max-width: $breakpoint-1023) {
            min-inline-size: fit-content;
            justify-content: flex-end;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
            justify-content: flex-end;
        }

        @media screen and (max-width: $breakpoint-550) {
            min-inline-size: auto;
        }

        @media screen and (max-width: $breakpoint-340) {
            font-size: $font12;
        }
    }

    &__small-date {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        display: flex;
        justify-content: flex-end;
        padding-right: 0.3rem;

        @media screen and (min-width: $breakpoint-1024) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-350) {
            padding-right: 0.9rem;
        }
    }

    &__post-content {
        display: flex;
        flex-direction: column;
        margin-left: 4.7rem;
        margin-bottom: 0.7rem;

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 1rem;
            margin-top: 0.4rem;
        }

        &--direction-row {
            flex-direction: column;
        }
    }

    &__event-title {
        font-family: $font-family-inter;
        font-size: $font20;
        font-weight: 500;
        color: $color-blue;
        cursor: pointer;
        margin-bottom: 0.4rem;
        margin-left: 0.5rem;
        margin-left: 4.7rem;
        margin-top: -0.438rem;

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 1rem;
            margin-top: 0;
        }
        @media screen and (max-width: $breakpoint-360) {
            font-size: $font16;
        }
    }

    &__post-text-container {
        max-width: 85%;
        min-height: 1.75;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-420) {
            max-width: 95%;
        }
    }

    .button-textPreview-more,
    .button-textPreview-less {
        color: #60a49f;
    }
    .button-textPreview-more,
    .button-textPreview-less {
        padding-top: 0.5rem;
        @media screen and (max-width: $breakpoint-550) {
            padding-bottom: 0.5rem;
        }
    }

    .truncated {
        max-height: 6.5rem;
        margin: 0 auto;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        width: 100%;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-1763) {
            display: -webkit-box;
        }

        @media screen and (max-width: $breakpoint-550) {
            max-height: 6.6rem;
            display: -webkit-inline-box;
        }
    }

    &__post-text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        margin-top: -0.188rem;
        word-break: break-word;
        margin-bottom: 0.7rem;
    }

    @media screen and (max-width: $breakpoint-360) {
        font-size: $font10;
    }

    &__post-text a {
        color: $color-blue;
    }

    &__event-image-container {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-1199) {
            margin-bottom: 0.7rem;
        }
    }

    &__event-image {
        width: 20rem;
        height: 9rem;
        object-fit: cover;
        cursor: pointer;
        border-radius: 0.563rem;

        @media screen and (max-width: $breakpoint-1400) {
            width: 16rem;
            height: 8rem;
        }

        @media screen and (max-width: $breakpoint-768) {
            width: 18rem;
            height: 8rem;
        }
    }
    &__post-image {
        cursor: pointer;
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
        max-width: 40%;
        max-height: 50%;
        border-radius: 0.563rem;
    }

    &__post-image-container {
        display: flex;
        margin-top: 0.75rem;
    }

    &__reactions-container {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        align-content: center;
        position: relative;
        margin-left: 4.7rem;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 0rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 1rem;
        }
    }

    &__add-emoji-icon {
        cursor: pointer;
        align-self: center;
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
    }

    &__emoji-picker {
        z-index: $z-index-third-layer;
    }

    &__reaction {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &--no-margin {
            margin-right: 0;
        }
    }

    &__reaction-plus {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-grey-c4;
        margin-right: 0.5rem;
        margin-left: 0.25rem;
        &:hover {
            cursor: pointer;
            font-weight: 700;
        }
    }

    &__reaction-count {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-grey-c4;
        margin-left: 0.25rem;
        display: flex;
        align-items: center;

        @media screen and (max-width: $breakpoint-420) {
            font-size: $font12;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__right-panel-content {
        margin-right: 1rem;

        &--height-5 {
            height: 5rem;

            @media screen and (max-width: $breakpoint-1500) {
                height: 6.5rem;
            }
        }

        &--no-height {
            height: max-content;
        }
    }

    &__date-container {
        background-color: $color-grey;
        border-radius: $border-radius-09;
        width: fit-content;
    }

    &__comments-actions-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 2.75rem;
        width: fit-content;
        margin-bottom: 1rem;
        cursor: pointer;

        &--display-none {
            @media screen and (min-width: $breakpoint-1024) {
                display: none;
            }
        }

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 0rem;
            align-items: center;
        }
    }

    &__comments-count {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-blue;
        padding-top: 0.4rem;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1023) {
            padding-top: 0rem;
        }

        &:hover {
            filter: $hover-brightness;
        }
    }

    &__comment-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-grey;
        border-radius: $border-radius-09;
        height: 2rem;
        width: 3rem;
        margin-left: 0.75rem;
        cursor: pointer;

        &:hover {
            filter: $hover-brightness;
        }

        @media screen and (max-width: $breakpoint-360) {
            margin-left: 0rem;
            width: 2rem;
        }

        &--background-transparent {
            background-color: transparent;
        }
    }

    &__actions-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 20rem;

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
        }
    }
}

.button-textPreview-more {
    padding-top: 0.5rem;

    &--blue {
        color: $color-blue;
    }

    &--red {
        color: $color-red;
        font-size: $font16;
        font-weight: 700;
        padding: 0;
    }

    &:hover {
        filter: brightness(1.1);
        cursor: pointer;
    }
    @media screen and (max-width: $breakpoint-550) {
        padding-bottom: 0.5rem;
    }
}

.truncated {
    display: -webkit-box;
    height: calc(1.5em * 4);
    line-height: 1.5em;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: $color-darker-blue;
}
