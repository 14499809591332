

.emoji-picker-react  {
    box-shadow: $card-box-shadow !important;
}

.emoji-picker-react .active-category-indicator-wrapper .active-category-indicator {
    background: $color-blue !important;
}

.emoji-picker-react .emoji-scroll-wrapper {

    &::-webkit-scrollbar-track {
        background-color: $color-light-grey;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-mid-dark-grey;
    }
}
