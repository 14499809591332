@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.organization-profile-header {
    top: -5.5rem;
    position: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    width: 90%;
    max-width: 50rem;

    &__profile-image-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7.8rem;
        height: 7.8rem;
        border-radius: $border-radius-circular;
        opacity: 0.75;
        filter: brightness(100%);
    }

    &__location-text {
        cursor: pointer;
    }

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }
    @media screen and (max-width: $breakpoint-1350) {
        top: -4.5rem;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
        top: -5.5rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
    }
    @media screen and (max-width: $breakpoint-660) {
        top: -5rem;
    }

    @media screen and (max-width: $breakpoint-550) {
        top: -1.5rem;
    }

    &__add-location-link {
        cursor: pointer;
    }

    &__upper-panel {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: fit-content;
        width: 100%;
    }

    &__subtext-container {
        display: flex;
    }

    &__image-container {
        display: flex;
        justify-content: center;
        align-content: flex-start;
        position: relative;
        min-width: 10rem;
        min-height: 10rem;

        @media screen and (max-width: $breakpoint-1350) {
            min-width: 7.5rem;
            min-height: 7.5rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            min-width: 10rem;
            min-height: 10rem;
        }

        @media screen and (max-width: $breakpoint-660) {
            min-width: 7.5rem;
            min-height: 7.5rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            min-width: 6rem;
            min-height: 6rem;
        }
    }

    &__profile-image {
        width: 7.8rem;
        height: 7.8rem;
        border-radius: $border-radius-circular;
        object-fit: cover;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1350) {
            width: 6rem;
            height: 6rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            width: 7.8rem;
            height: 7.8rem;
        }

        @media screen and (max-width: $breakpoint-660) {
            width: 6rem;
            height: 6rem;
        }
        @media screen and (max-width: $breakpoint-420) {
            width: 4.5rem;
            height: 4.5rem;
        }
    }

    &__content-container {
        margin-top: 3rem;
        position: relative;
        width: 100%;

        @media screen and (max-width: $breakpoint-1350) {
            margin-top: 1.5rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 3rem;
        }

        @media screen and (max-width: $breakpoint-660) {
            margin-top: 1.5rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            margin-top: 0.9rem;
        }
    }

    &__name-container {
        display: flex;
        align-items: center;
        min-height: 2rem;
        max-height: 4rem;
        margin-top: 0.75rem;
        width: 30rem;

        @media screen and (max-width: $breakpoint-1599) {
            width: 27rem;
        }
        @media screen and (max-width: $breakpoint-1279) {
            width: 24rem;
        }
        @media screen and (max-width: $breakpoint-1199) {
            width: 20rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 26rem;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 24rem;
        }
        @media screen and (max-width: $breakpoint-660) {
            width: 20rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 18rem;
        }
        @media screen and (max-width: $breakpoint-500) {
            width: 14rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            margin-top: 1.5rem;
            width: 12rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 10rem;
        }
    }

    &__name {
        font-family: $font-family-inter;
        font-weight: 700;
        color: $color-darker-blue;
        font-size: $font24;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font22;
        }
        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font20;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font18;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font16;
        }
        @media screen and (max-width: $breakpoint-350) {
            font-size: $font14;
        }
    }

    &__tags-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 72%;
        margin-top: 0.05rem;

        @media screen and (max-width: $breakpoint-1365) {
            width: 94%;
        }
        @media screen and (max-width: $breakpoint-1253) {
            width: 75%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 96%;
        }
        @media screen and (max-width: $breakpoint-819) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__tag {
        @include tag();

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__location-container {
        margin-top: 0.5rem;
        height: 2rem;
        display: flex;
        flex-direction: row;
        width: fit-content;
        align-items: center;
        margin-left: -0.2rem;
    }

    &__location-text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-dark-grey;
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font12;
        }
    }

    &__location-icon {
        margin-right: 0.75rem;
        margin-left: 0.25rem;
    }

    &__location-icon,
    &__location-icon-small {
        margin-right: 0.75rem;
        margin-left: 0.25rem;
    }
    &__location-icon-small {
        @media screen and (min-width: $breakpoint-551) {
            display: none;
        }
    }
    &__location-icon {
        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__url-container {
        height: 2rem;
        display: flex;
        flex-direction: row;
        width: fit-content;
        align-items: center;
        margin-left: -0.1rem;
    }

    &__url-text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-dark-grey;
        text-decoration: none;
        word-break: break-word;

        &:hover {
            text-decoration: underline;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font12;
        }
    }

    &__url-icon,
    &__url-icon-small {
        margin-right: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__url-icon-small {
        @media screen and (min-width: $breakpoint-551) {
            display: none;
        }
    }
    &__url-icon {
        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__edit-icon {
        position: absolute;
        top: 0.1rem;
        right: -0.5rem;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        min-width: 3.5rem;
        min-height: 3.5rem;
        max-width: 3.5rem;
        max-height: 3.5rem;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            background-color: $color-grey;
        }
        @media screen and (max-width: $breakpoint-350) {
            right: -1rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            right: -0.2rem;
        }
        @media screen and (max-width: $breakpoint-360) {
            right: 0.2rem;
        }
    }
    &__buttons {
        position: absolute;
        width: 10.313rem;
        right: 0.563rem;
        top: -0.687rem;
        margin-bottom: 2rem;

        @media screen and (max-width: $breakpoint-1350) {
            right: 2rem;
            top: 0.313rem;
        }
        @media screen and (max-width: $breakpoint-660) {
            right: 0.438rem;
            top: 0.313rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            bottom: -9rem;
            top: auto;
            width: 8.25rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            bottom: -9rem;
        }

        @media screen and (max-width: $breakpoint-360) {
            width: 7.125rem;
            right: -0.4rem;
        }
    }

    &__button {
        width: 10.2rem;
        height: 3.2rem;
        margin: 0.5rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 8.125rem;
            height: 2.5rem;
        }
        @media screen and (max-width: $breakpoint-360) {
            width: 7.125rem;
        }
    }

    &__button-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 7rem;
        height: 3rem;
        color: $color-white;

        @media screen and (max-width: $breakpoint-550) {
            width: 6rem;
        }
    }

    &__buttons-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 2rem;
        mix-blend-mode: normal;
        filter: blur(1.5px);
        border-radius: 0.5rem;
    }
}
