@import "../abstracts/variables.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.room-invite-modal {
    width: 50rem;
    font-family: $font-family-inter;
    @media screen and (max-width: $breakpoint-1439) {
        width: 40rem;
    }
    @media screen and (max-width: $breakpoint-1350) {
        width: 35rem;
    }
    @media screen and (max-width: $breakpoint-1200) {
        width: 30rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        height: 100vh;
    }
    height: 29.375rem;

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 2.5rem;
        margin-right: 1.3rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: 700;
        font-size: $font22;
        color: $color-red;

        @media screen and (max-width: $breakpoint-768) {
            background-color: $color-grey;
            border-bottom: solid 0.03rem $color-grey-c4;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
            padding-top: 1.8rem;
        }
    }

    &__mobile-icon {
        margin-right: 0.5rem;
        @media screen and (min-width: $breakpoint-768) {
            display: none;
        }
    }
    &__text {
        @media screen and (max-width: $breakpoint-768) {
            width: 56%;
            display: flex;
            justify-content: flex-end;
            font-size: $font16;
            color: $color-darker-blue;
        }
    }
    &__close-icon {
        @include icon();
    }

    &__search-container {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: center;
        padding-bottom: 1.563rem;
        box-sizing: border-box;
    }

    &__item-container {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 64%;

        &::-webkit-scrollbar-track {
            background-color: $color-light-grey;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
        }

        &--no-scroll {
            overflow-y: auto;
        }

        @media screen and (max-width: $breakpoint-768) {
            height: 100%;
        }
    }

    &__no-data-text {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-dark-grey;
        font-weight: 500;
        width: calc(100% - 2rem);
        text-align: center;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__invite-button {
        color: $color-red;
        padding-right: 2.25rem;
        box-sizing: border-box;
        font-weight: 700;
        font-size: $font14;
        &:hover {
            filter: brightness(1.1);
        }

        &--pending {
            color: $color-light-blue;
            cursor: default;

            &:hover {
                filter: brightness(1);
            }
        }

        &--grey {
            color: $color-grey-c4;
            &:hover {
                color: $color-red;
            }
        }
    }

    &__item {
        &--border {
            border-bottom: solid 0.03rem $color-grey;
        }
        &--no-border {
            border-bottom: none;
        }
    }
}

.room-invite-modal-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 4.1rem;
    background-color: white;

    cursor: pointer;

    &__left-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 6rem;
        max-width: 6rem;
        padding-left: 2.25rem;

        @media screen and (max-width: $breakpoint-550) {
            min-width: 5rem;
            max-width: 5rem;
            padding-left: 0;
        }

        box-sizing: border-box;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 80%;
    }

    &__text-container {
        max-width: 11rem;

        // @media screen and (min-width: $breakpoint-350) {
        //     max-width: 10.5rem;
        // }
        // @media screen and (min-width: $breakpoint-389) {
        //     max-width: 12.5rem;
        // }

        @media screen and (min-width: $breakpoint-1199) {
            max-width: 26rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            max-width: 13rem;
        }
        @media screen and (max-width: $breakpoint-420) {
            max-width: 9rem;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
        padding-left: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: $breakpoint-420) {
            padding-left: 0;
        }
    }

    &__subtext {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        @media screen and (max-width: $breakpoint-350) {
            display: none;
        }
    }
    &__right-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 4rem;
    }
}
