@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";
@import "../abstracts/mixins/icon-mixins.scss";

.profile-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    height: 23.5rem;
    width: 90%;
    max-width: 50rem;
    background-color: transparent;
    padding-bottom: 3rem;

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
        padding-bottom: 0rem;
    }
    @media screen and (max-width: $breakpoint-550) {
        width: 100%;
        height: fit-content;
        padding-bottom: 1rem;
    }

    &__cover-image-container {
        @include user-profile-cover-container();
    }

    &__cover-image-container-loading {
        @include user-profile-cover-container();
        display: flex;
        opacity: 0.6;
        filter: brightness(100%);
    }

    &__cover-image {
        min-width: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        object-fit: cover;
        border-radius: $border-radius-09;

        @media screen and (max-width: $breakpoint-550) {
            border-radius: 0;
        }

        &--pointer {
            cursor: pointer;
        }

        &--default {
            cursor: default;
        }
    }

    &__panel-container {
        display: flex;
        flex-direction: row;
        width: 75%;
    }

    &__left-panel {
        width: 100%;
        min-width: 17rem;
        height: 100%;
    }

    &__right-panel {
        width: 20%;
        min-width: 17rem;
        height: 100%;
    }

    &__camera-icon {
        @include camera-icon;
    }

    &__profile-image-container {
        position: absolute;
        border-radius: $border-radius-circular;
        left: 7%;
        top: 12rem;
        @media screen and (max-width: $breakpoint-1599) {
            left: 5%;
        }

        @media screen and (max-width: $breakpoint-1439) {
            top: 9rem;
        }

        @media screen and (max-width: $breakpoint-1199) {
            width: 6.5rem;
            height: 6.5rem;
            top: 7.5rem;
        }
        @media screen and (max-width: $breakpoint-1099) {
            top: 5.5rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 7.8rem;
            height: 7.8rem;
            top: 10.5rem;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 6.5rem;
            height: 6.5rem;
            top: 9.5rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            top: 8.5rem;
        }
        @media screen and (max-width: $breakpoint-660) {
            top: 7.5rem;
        }

        @media screen and (max-width: $breakpoint-500) {
            width: 4.5rem;
            height: 4.5rem;
            top: 6.5rem;
            left: 2rem;
        }

        @media screen and (max-width: $breakpoint-350) {
            top: 5.5rem;
            height: 4.5rem;
        }
    }

    &__profile-image {
        cursor: pointer;
        object-fit: $image-cover-object-fit;
        width: 7.8rem;
        height: 7.8rem;
        border-radius: $border-radius-circular;

        @media screen and (max-width: $breakpoint-1199) {
            width: 6.5rem;
            height: 6.5rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 7.8rem;
            height: 7.8rem;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 6.5rem;
            height: 6.5rem;
            top: 9.5rem;
        }
        @media screen and (max-width: $breakpoint-500) {
            width: 4.5rem;
            height: 4.5rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            height: 4.5rem;
        }
    }

    &__profile-image-loading {
        display: flex;
        cursor: pointer;
        object-fit: $image-cover-object-fit;
        width: 7.8rem;
        height: 7.8rem;
        border-radius: $border-radius-circular;
        opacity: 0.75;
        filter: brightness(100%);

        @media screen and (max-width: $breakpoint-1199) {
            width: 6.5rem;
            height: 6.5rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 7.8rem;
            height: 7.8rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 6.5rem;
            height: 6.5rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 5rem;
            height: 5rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 4.5rem;
            height: 4.5rem;
        }
        @media screen and (max-width: $breakpoint-500) {
            width: 4.5rem;
            height: 4.5rem;
        }
    }

    &__user-name,
    &__user-nameMore25 {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        min-height: 2.75rem;
        max-height: 3.75rem;
        width: 70%;
        font-family: $font-family-inter;
        font-weight: 700;
        color: $color-darker-blue;
        font-size: $font24;
        padding-right: 2rem;
        background-color: transparent;
        overflow-wrap: break-word;

        @media screen and (max-width: $breakpoint-1599) {
            width: 66%;
            font-size: $font22;
            padding-right: 4rem;
        }

        @media screen and (max-width: $breakpoint-1500) {
            width: 64.5%;
        }
        @media screen and (max-width: $breakpoint-1365) {
            font-size: $font20;
            width: 60.5%;
        }
        @media screen and (max-width: $breakpoint-1350) {
            max-height: 4.75rem;
            position: static;
            display: flex;
            align-items: flex-end;
            width: 60%;
        }
        @media screen and (max-width: $breakpoint-1280) {
            width: 59%;
        }
        @media screen and (max-width: $breakpoint-1199) {
            width: 58%;
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-1099) {
            width: 57%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font20;
            width: 65%;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 66%;
        }

        @media screen and (max-width: $breakpoint-769) {
            font-size: $font18;
            width: 65%;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font18;
            width: 60.5%;
        }
        @media screen and (max-width: $breakpoint-600) {
            font-size: $font16;
            width: 59%;
        }

        @media screen and (max-width: $breakpoint-500) {
            width: 60.5%;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 57.8%;
            font-size: $font14;
        }
        @media screen and (max-width: $breakpoint-420) {
            width: 54%;
        }
        @media screen and (max-width: $breakpoint-389) {
            width: 49.9%;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 47%;
        }
    }

    &__user-nameMore25 {
        padding-top: 0.7rem;
        min-height: 0;
        @media screen and (max-width: $breakpoint-1439) {
            padding-top: 1.5rem;
        }
        @media screen and (max-width: $breakpoint-1199) {
            padding-top: 1rem;
        }
        @media screen and (max-width: $breakpoint-1099) {
            padding-top: 1.5rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            padding-top: 0.5rem;
        }
        @media screen and (max-width: $breakpoint-900) {
            padding-top: 0.2rem;
        }
        @media screen and (max-width: $breakpoint-819) {
            padding-top: 1rem;
        }
        @media screen and (max-width: $breakpoint-660) {
            padding-top: 1.2rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            padding-top: 0.9rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            padding-top: 0.5rem;
        }
    }

    &__edit-icon {
        @include icon;
        position: absolute;
        top: 0.1rem;
        right: 0.5rem;

        @media screen and (max-width: $breakpoint-1350) {
            top: 10.7rem;
        }
        @media screen and (max-width: $breakpoint-1199) {
            top: 9.7rem;
        }
        @media screen and (max-width: $breakpoint-1099) {
            top: 7.7rem;
        }
        @media screen and (max-width: $breakpoint-1050) {
            top: 8.7rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            top: 12.7rem;
        }
        @media screen and (max-width: $breakpoint-819) {
            top: 11.65rem;
        }

        @media screen and (max-width: $breakpoint-769) {
            top: 10.65rem;
        }
        @media screen and (max-width: $breakpoint-660) {
            top: 9.65rem;
        }

        @media screen and (max-width: $breakpoint-550) {
            top: 8rem;
            right: 1.5rem;
        }
        @media screen and (max-width: $breakpoint-420) {
            top: 8.2rem;
        }
    }

    &__tags-container {
        width: 74%;

        @media screen and (max-width: $breakpoint-1365) {
            width: 71%;
        }

        @media screen and (max-width: $breakpoint-819) {
            width: 76%;
        }
        @media screen and (max-width: $breakpoint-769) {
            width: 75%;
        }
        @media screen and (max-width: $breakpoint-660) {
            width: 71%;
        }
        @media screen and (max-width: $breakpoint-500) {
            width: 73%;
        }
        @media screen and (max-width: $breakpoint-420) {
            width: 69%;
        }
        @media screen and (max-width: $breakpoint-389) {
            width: 67%;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 65%;
        }
    }

    &__tags-content {
        width: 85%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0.3rem;

        @media screen and (max-width: $breakpoint-1365) {
            width: 94%;
        }
        @media screen and (max-width: $breakpoint-1253) {
            width: 75%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 96%;
        }

        &--width-99 {
            width: 99%;
        }
    }

    &__tag {
        @include tag();

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__location-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 71.5%;
        padding-right: 1rem;
        @media screen and (max-width: $breakpoint-1500) {
            width: 70.2%;
        }
        @media screen and (max-width: $breakpoint-1365) {
            align-items: flex-start;
            flex-direction: column;
            width: 67.5%;
        }

        @media screen and (max-width: $breakpoint-1280) {
            width: 66.8%;
        }
        @media screen and (max-width: $breakpoint-1099) {
            width: 65.5%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 72%;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 73%;
        }
        @media screen and (max-width: $breakpoint-769) {
            width: 72.3%;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font22;
            width: 68%;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 67.5%;
        }
        @media screen and (max-width: $breakpoint-500) {
            width: 69.5%;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 68%;
        }
        @media screen and (max-width: $breakpoint-420) {
            width: 64.5%;
        }
        @media screen and (max-width: $breakpoint-389) {
            width: 62%;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 60%;
        }
    }

    &__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        font-family: $font-family-inter;
        font-weight: 500;
        color: $color-dark-grey;
        font-size: $font14;
        margin-right: 0.6rem;

        @media screen and (max-width: $breakpoint-660) {
            max-width: 12.5rem;
            font-size: $font12;
            word-break: break-word;
        }
    }

    &__text-location {
        cursor: pointer;
    }

    &__location-icon {
        margin-right: 0.3rem;
    }

    &__buttons {
        position: absolute;
        width: 10.313rem;
        right: 0;
        top: 1.313rem;
        margin-bottom: 2rem;

        @media screen and (max-width: $breakpoint-1350) {
            top: auto;
            bottom: -2.125rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            bottom: -2rem;
            right: auto;
            left: 0;
            margin-left: 6rem;
        }

        @media screen and (max-width: $breakpoint-350) {
            bottom: -1.313rem;
        }
    }

    &__button {
        width: 10.2rem;
        height: 3.2rem;
        margin: 0.5rem;
        @media screen and (max-width: $breakpoint-550) {
            width: 8.125rem;
            height: 2.5rem;
        }
    }

    &__button-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 7rem;
        height: 3rem;
        color: $color-white;

        @media screen and (max-width: $breakpoint-550) {
            width: 6rem;
        }
    }

    &__buttons-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 2rem;
        background: rgba(255, 255, 255, 0.6);
        mix-blend-mode: normal;
        filter: blur(1.5px);

        border-radius: 0.5rem;

        @media screen and (max-width: $breakpoint-1350) {
            display: flex;
            flex-direction: row;
            margin: auto;
            width: 70%;
            justify-content: center;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 80%;
        }
    }

    &__subtext-container {
        display: flex;
        width: 74.4%;
        height: 2.2rem;
        @media screen and (max-width: $breakpoint-1500) {
            width: 73.4%;
        }
        @media screen and (max-width: $breakpoint-1365) {
            width: 70.4%;
        }
        @media screen and (max-width: $breakpoint-1099) {
            width: 69.5%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 74%;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 76%;
        }
        @media screen and (max-width: $breakpoint-660) {
            width: 72%;
        }
        @media screen and (max-width: $breakpoint-500) {
            width: 74%;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 73%;
        }
        @media screen and (max-width: $breakpoint-420) {
            width: 70.5%;
        }
        @media screen and (max-width: $breakpoint-389) {
            width: 67.5%;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 66%;
        }
    }

    &__add-location-link {
        cursor: pointer;
    }

    &__contact-text {
        font-weight: 700;
        font-family: $font-family-inter;
        font-size: $font14;
        text-align: center;
        color: $color-blue;

        @media screen and (max-width: $breakpoint-660) {
            font-size: $font12;
        }

        &:hover {
            cursor: pointer;
        }

        &--blurry {
            filter: blur(1px);
            mix-blend-mode: normal;
        }
    }
}
