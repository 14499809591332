@import "../abstracts/variables.scss";

.tooltip__wrapper {
    display: inline-block;
    position: relative;
}

.tooltip__icon {
    margin-left: 0.25rem;
}

.tooltip__tip {
    position: absolute;
    border-radius: $tooltip-border-radius;
    left: 50%;
    transform: translateX(-50%);
    padding: $tooltip-padding;
    color: $color-white;
    background: $color-aquamarine;
    font-size: $tooltip-font-size;
    font-family: $font-family-inter;
    line-height: $tooltip-line-height;
    z-index: $tooltip-z-index;
    white-space: $tooltip-white-space;

    &::before {
        content: " ";
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: $tooltip-arrow-size;
        margin-left: $tooltip-arrow-size * -1;
    }

    &--right {
        left: calc(100% + 0.9rem);
        top: 50%;
        transform: $tooltip-tip-transform;

        &::before {
            left: $tooltip-arrow-size * -1;
            top: 50%;
            transform: $tooltip-tip-transform;
            border-right-color: $color-aquamarine;
        }

        @media screen and (max-width: $breakpoint-768) {
            top: $tooltip-margin * -2.2;
            position: absolute;
            border-radius: $tooltip-border-radius;
            left: 46%;
            transform: translateX(-36%);
            padding: $tooltip-padding;
            color: $color-white;
            background: $color-aquamarine;
            font-size: $font12;
            font-family: $font-family-inter;
            line-height: $tooltip-line-height;
            z-index: $tooltip-z-index;
            white-space: $tooltip-white-space;
            &::before {
                top: 109%;
                border-top-color: $color-aquamarine;
                left: 6.3rem;
                transform: 0;
                border-right-color: transparent;
            }
        }
    }

    &--left {
        left: auto;
        right: calc(100% + 0.9rem);
        top: 50%;
        transform: $tooltip-tip-transform;

        &::before {
            left: auto;
            right: $tooltip-arrow-size * -2;
            top: 50%;
            transform: $tooltip-tip-transform;
            border-left-color: $color-aquamarine;
        }

        @media screen and (max-width: $breakpoint-768) {
            top: $tooltip-margin * -2;
            position: absolute;
            border-radius: $tooltip-border-radius;
            left: auto;
            right: 50%;
            transform: translateX(55%);
            padding: $tooltip-padding;
            color: $color-white;
            background: $color-aquamarine;
            font-size: $tooltip-font-size;
            font-family: $font-family-inter;
            line-height: $tooltip-line-height;
            z-index: $tooltip-z-index;
            white-space: $tooltip-white-space;
            &::before {
                top: 111%;
                border-top-color: $color-aquamarine;
                left: auto;
                transform: $tooltip-tip-transform;
                right: 8.8rem;
                border-left-color: transparent;
            }
        }
    }

    &--bottom {
        bottom: $tooltip-margin * -1.7;

        &::before {
            bottom: 100%;
            border-bottom-color: $color-aquamarine;
        }
    }

    &--top {
        top: $tooltip-margin * -1.9;

        &::before {
            top: 100%;
            border-top-color: $color-aquamarine;
        }
    }

    &--settings-top {
        top: -5rem;
        transform: translateX(-18%);
        &::before {
            left: 19%;
            top: 100%;
            border-top-color: $color-aquamarine;
        }

        @media screen and (max-width: $breakpoint-350) {
            transform: translateX(-26%);
            &::before {
                left: 27%;
            }
        }
    }
}
