@import "../abstracts/variables.scss";
@import "../abstracts/mixins/component-mixins.scss";

.organization-profile-admin-section {
    width: 100%;
    max-width: 50rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    height: 7.5rem;

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
        height: 6.7rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
        margin-top: 3rem;
    }

    &__title {
        margin-left: 2rem;
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-light-blue;

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-768) {
            font-size: $font16;
            margin-bottom: 0;
        }
        @media screen and (max-width: $breakpoint-550) {
            margin-left: 1rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
        }
    }

    &__content {
        position: relative;
        height: 4.35rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 97%;
    }

    &__admin-container {
        position: relative;
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 2rem;

        @media screen and (max-width: $breakpoint-450) {
            margin-left: 0.5rem;
        }
    }

    &__admin {
        position: relative;
        min-width: 3rem;
        min-height: 3rem;
        margin-right: 1rem;
        border-radius: $border-radius-circular;
        background-color: transparent;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-450) {
            margin-right: 0.6rem;
        }
    }

    &__add {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 3rem;
        min-height: 3rem;
        border-radius: $border-radius-circular;
        background-color: $color-lighter-blue;
        cursor: pointer;

        &:hover {
            filter: $hover-brightness-darker;
        }
    }

    &__transfer-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 15.75rem;
        max-width: 14.75rem;
        height: 100%;

        &:hover {
            filter: $hover-brightness;
            cursor: pointer;
        }

        @media screen and (max-width: $breakpoint-1400) {
            min-width: 11.2rem;
            width: 69%;
            justify-content: flex-end;
        }
        @media screen and (max-width: $breakpoint-1023) {
            display: none;
        }
    }

    &__transfer-text {
        color: $color-gold;
        font-size: $font16;
        font-weight: 700;
        font-family: $font-family-inter;
        display: flex;
        align-self: center;
        margin-left: 0.5rem;

        @media screen and (max-width: $breakpoint-1400) {
            font-size: $font14;
        }
    }

    &__transfer-text-container {
        display: flex;
        height: 75%;

        @media screen and (max-width: 1120px) {
            width: 55%;
        }
    }
}

.admin-card-unselected {
    display: none;
}

.admin-card-selected {
    z-index: $z-index-second-layer;
    position: absolute;
    display: flex;
    width: 17rem;
    height: 5.57rem;
    background-color: white;
    border-radius: $border-radius-09;
    box-shadow: $box-shadow-overlay;
    top: 80%;
    right: -2rem;

    @media screen and (max-width: $breakpoint-1023) {
        right: -9rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        right: -10rem;
        width: 14rem;
    }

    @media screen and (max-width: $breakpoint-500) {
        right: inherit;
        width: fit-content;
        height: fit-content;
        padding: 0.7rem;
    }

    &__close-icon {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        min-width: 2.5rem;
        min-height: 2.5rem;
        background-color: transparent;
        cursor: pointer;
        margin-right: 0.5rem;

        &:hover {
            background-color: $color-grey;
        }
    }

    &__right-panel {
        display: flex;
        justify-self: center;
        align-items: center;
        height: 100%;
    }

    &__left-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 5rem;

        @media screen and (max-width: $breakpoint-500) {
            display: none;
        }
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 12rem;

        @media screen and (max-width: $breakpoint-768) {
            width: 9rem;
        }

        @media screen and (max-width: $breakpoint-500) {
            width: fit-content;
            min-width: 4.5rem;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-500) {
            font-size: $font12;
        }
    }

    &__subtext {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        @media screen and (max-width: $breakpoint-500) {
            display: none;
        }
    }

    &__tag {
        @include tag();
    }
}
