.contact-info {
    width: 35rem;
    font-family: $font-family-inter;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
    }

    &__close-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $border-radius-circular;
        min-width: 3rem;
        min-height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
        cursor: pointer;

        &:hover {
            background-color: $color-grey;
        }
    }

    &__title-container {
        display: flex;
        width: 100%;
        margin: auto;
        padding-top: 1rem;
        padding-right: 1rem;
        padding-left: 2.125rem;
        padding-bottom: 0.75rem;
        box-sizing: border-box;
        justify-content: space-between;
        border-bottom: 0.05rem solid $color-grey-c4;
        margin-bottom: 1.125rem;
    }

    &__title {
        font-weight: 700;
        color: $color-blue;
        font-size: $font14;
        display: flex;
        align-items: center;
    }

    &__text-container {
        padding-left: 2.125rem;
    }

    &__bold-text {
        font-weight: 700;
        color: $color-dark-blue;
        font-size: $font14;

        &--margin-left {
            margin-left: 0.5rem;
        }
    }

    &__email-container {
        display: flex;
        margin-top: 1.25rem;
        margin-bottom: 0.5rem;
        align-items: center;
    }

    &__text {
        font-size: $font14;
        font-weight: 400;
        margin-left: 2.25rem;
        margin-bottom: 1.875rem;
    }
}
