@import "../abstracts/variables.scss";

.room-mobile-menu {
    position: absolute;
    height: 82%;
    left: -1rem;
    right: 0rem;
    top: 9rem;
    z-index: $z-index-third-layer;

    &--margin-top {
        margin-top: -2.5rem;
    }

    &__background {
        background-color: $color-white;
        opacity: 0.5;
        width: 100%;
        height: 100vh;
    }

    &__item-container {
        position: absolute;
        cursor: pointer;

        right: 0rem;
        width: 50%;
        background-color: $color-white;

        @media screen and (max-width: $breakpoint-768) {
            width: 100%;
            left: 0rem;
        }

        @media screen and (min-width: $breakpoint-768) {
            box-shadow: $card-box-shadow;
            border-radius: 1rem;
        }

        &--top-4 {
            top: -4.57rem;
        }

        &--top-2 {
            top: -2.57rem;
        }
    }

    &__item {
        font-size: $font16;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 4.688rem;
        border-bottom: $bottom-border;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        &:hover {
            background-color: $color-grey;
            filter: brightness(1);
        }

        &--members {
            padding-left: 4rem;
        }

        &--red {
            background-color: $color-red;
            color: $color-white;

            @media screen and (min-width: $breakpoint-768) {
                box-shadow: $card-box-shadow;
                border-bottom-right-radius: 1rem;
                border-bottom-left-radius: 1rem;
            }

            &:hover {
                background-color: $color-red;
                filter: $hover-brightness;
            }
        }
    }

    &__text {
        padding-left: 0.5rem;

        &--edit-room {
            padding-left: 0.7rem;
        }

        &--invite {
            padding-left: 0.6rem;
        }

        &--share {
            padding-left: 0.6rem;
        }
    }
    &__room-information {
        font-family: $font-family-inter;
        color: $color-darker-blue;
        padding-top: 1.375rem;
        padding-bottom: 1.5rem;
        box-sizing: border-box;
        border-bottom: $bottom-border;
        display: flex;
        flex-direction: column;
    }

    &__card {
        margin-left: 1.3rem;
        max-width: 12.188rem;
        max-height: 4.25rem;
        &--padding-top {
            padding-top: 1.5rem;
        }
        &--padding-bottom {
            padding-bottom: 1.2rem;
        }
    }

    &__divider {
        @media screen and (max-width: $breakpoint-768) {
            border-bottom: $bottom-border;
        }
        display: none;
    }

    &__description-container {
        @media screen and (max-width: $breakpoint-768) {
            margin-top: 1.375rem;
            padding-left: 1.875rem;
            font-size: $font14;
            margin-bottom: 1.438rem;

            &--margin-top {
                margin-top: 2rem;
            }
        }

        display: none;
        
    }

    &__description-text {
        @media screen and (max-width: $breakpoint-768) {
            font-family: $font-family-inter;
            width: 85%;
            margin-bottom: 0.438rem;
            font-weight: 500;
            color: $color-darker-blue;
            -webkit-line-clamp: unset;
        }

        display: none;
    }

    &__button {
        border: none;
        background-color: transparent;
        color: $color-red;
        font-size: $font16;
        font-weight: 700;
        padding: 0;

        &:hover {
            filter: brightness(1.1);
        }
    }
}
