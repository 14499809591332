.share-event-to-room-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 37rem;
    height: 24rem;
    background-color: $color-white;
    border-radius: $border-radius-09;
    box-shadow: $card-box-shadow;

    @media screen and (max-width: $breakpoint-768) {
        width: 100%;
        border-radius: 0;
        box-shadow: none;
        height: 100vh;
    }

    &__title-container {
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $breakpoint-768) {
            height: 8;
        }
    }

    &__close-icon {
        @include icon;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }

    &__title {
        font-family: $font-family-inter;
        font-size: $font20;
        font-weight: 700;
        color: $color-darker-blue;
        margin-left: 2rem;
    }

    &__content {
        width: 100%;
        height: 14rem;
        margin-bottom: 2rem;

        overflow-y: auto;
        &::-webkit-scrollbar-track {
            background-color: $color-light-grey;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
        }

        @media screen and (max-width: $breakpoint-768) {
            height: 82vh;
            margin-bottom: 3rem;
        }
    }

    &__button-container {
        width: 100%;
        height: 4rem;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: $breakpoint-768) {
            height: 10vh;
        }
    }

    &__button {
        margin-right: 2rem;
        height: 2.3rem;
        width: 7.5rem;
    }
}

.room-card {
    display: flex;
    width: 100%;
    height: 4rem;
    border-bottom: solid 0.03rem $color-grey-c4;
    flex-direction: row;

    &__left-panel {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 5rem;
    }

    &__middle-panel {
        display: flex;
        align-items: center;
        width: 26rem;
        @media screen and (max-width: $breakpoint-768) {
            width: 80%;
        }
    }

    &__text-container {
        max-width: 18rem;

        @media screen and (max-width: $breakpoint-550) {
            max-width: 10rem;
        }

        @media screen and (max-width: $breakpoint-389) {
            max-width: 7rem;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-dark-grey;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__right-panel {
        width: 4rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $breakpoint-768) {
            margin-right: 1rem;
        }
    }

    &__room-type-public {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-white;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: $border-radius-09;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        background-color: $color-light-blue;
    }

    &__room-type-private {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-white;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: $border-radius-09;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        background-color: $color-gold;
    }
}

.check-box-icon {
    width: 3rem;
    height: 3rem;

    @media screen and (max-width: $breakpoint-1199) {
        width: 2.7rem;
        height: 2.7rem;
    }

    @media screen and (max-width: $breakpoint-550) {
        width: 2.2rem;
        height: 2.2rem;
    }
}
