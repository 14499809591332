@import "../abstracts/variables.scss";

.feed-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;

    &__character-counter-container {
        margin-left: 0.75rem;
        font-family: $font-family-inter;
        font-size: $font12;
        color: red;
        cursor: default;
    }

    &__text a {
        color: $color-blue;
        word-break: break-all;
    }

    @media screen and (max-width: $breakpoint-550) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    &__dyk-box {
        height: auto;
        border: 0.25rem solid $color-green;
    }

    &__cta-box {
        height: auto;
        border: 0.25rem solid $color-green;
    }

    &__entity-list {
        padding-bottom: 0.5rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        display: flex;
        height: 12rem;
        flex-direction: row;
        justify-content: start;
        overflow-x: scroll;

        &::-webkit-scrollbar-track {
            background-color: transparent;
            height: 0.25rem;
        }

        &::-webkit-scrollbar {
            height: 0.375rem;
            width: 0.375rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-mid-dark-grey;
            border-radius: 1rem;
        }
    }

    &__title {
        padding-top: 1rem;
        padding-left: 1rem;
        font-family: $font-family-inter;
        font-size: $font20;
        line-height: normal;
        color: $color-darker-blue;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        justify-content: left;
        text-align: center;
    }

    &__post-info {
        position: relative;
    }

    &__cta-edusiia-img {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 1rem;
    }

    &__cta-button-container {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 3rem;
    }

    &__cta-button {
        width: 10rem;
    }

    &__cta-text {
        padding-top: 3rem;
        font-family: $font-family-inter;
        font-size: $font24;
        line-height: normal;
        color: $color-darker-blue;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
    }

    &__reactions-container {
        display: flex;
        flex-direction: row;
        align-content: center;
        position: relative;
        margin-left: 2rem;
        margin-bottom: 1rem;
        margin-right: 1.5rem;
        margin-top: 0rem;
        padding-top: 1rem;

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 0rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            margin-left: 1rem;
        }
    }

    &__add-emoji-icon {
        cursor: pointer;
        align-self: center;
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
    }

    &__emoji-picker {
        z-index: $z-index-third-layer;
    }

    &__reaction {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &--no-margin {
            margin-right: 0;
        }
    }

    &__reaction-plus {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-grey-c4;
        margin-right: 0.5rem;
        margin-left: 0.25rem;

        &:hover {
            cursor: pointer;
            font-weight: 700;
        }
    }

    &__reaction-count {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-grey-c4;
        margin-left: 0.25rem;
        display: flex;
        align-items: center;

        @media screen and (max-width: $breakpoint-420) {
            font-size: $font12;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__emoji {
        display: flex;
        background: $color-grey;
        border: 1.59px solid $color-mid-dark-grey;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
        margin-left: -0.4rem;
    }

    &__emoji-image {
        width: 1.125rem;
        height: 1.125rem;
    }

    &__emoji-picker {
        z-index: $z-index-third-layer;
    }

    &__loading-card {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        margin: 1rem;
        width: 45%;
        box-sizing: border-box;
        height: 19.438rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $breakpoint-1599) {
            width: 100%;
        }
    }

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }

    &__card {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        width: 100%;
    }

    &__editor {
        display: flex;
        width: 100%;
        max-height: 15rem;
        cursor: default;
        flex-direction: row;
        align-items: baseline;
    }

    &__editor-action-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-start;
        font-family: $font-family-inter;
        font-size: $font14;
        padding: 0.5rem;
        cursor: default;
    }

    &__room-dropdown {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $breakpoint-768) {
            max-width: 12rem;
        }
    }

    &__editor-container {
        display: flex;
        flex-direction: column;
        border-top: solid 0.03rem $color-grey;
        padding-left: 2rem;
        padding-right: 1rem;
        padding-top: 0.938rem;
        margin-bottom: 1.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        //height: calc(1.19em * 5);
        line-height: 1.19em;
        word-break: break-word;
        overflow: visible;

        &:hover {
            cursor: pointer;
        }
    }

    &__editor-share-text {
        white-space: nowrap;
        margin-right: 1rem;
        color: $color-darker-blue;

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__text-container {
        border-top: solid 0.03rem $color-grey;
        padding-left: 2rem;
        padding-right: 1rem;
        padding-top: 0.938rem;
        margin-bottom: 1.2rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        //height: calc(1.19em * 5);
        line-height: 1.19em;
        word-break: break-word;

        &:hover {
            cursor: pointer;
        }
    }

    &__text-container-no-border {
        padding-left: 2rem;
        padding-right: 1rem;
        padding-top: 0.938rem;
        margin-bottom: 1.2rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        //height: calc(1.19em * 5);
        line-height: 1.19em;
        word-break: break-word;

        &:hover {
            cursor: pointer;
        }
    }

    &__interaction-container {
        display: flex;
        flex-direction: row;
        height: 2rem;
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        justify-content: flex-end;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
    }

    &__request {
        margin-left: 6%;
        margin-right: 3%;
        margin-bottom: 3%;
        color: $color-red;
        font-family: $font-family-inter;
        font-size: $font15;
    }
}

.feed-item-room-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    padding-bottom: 0.8rem;

    &__image {
        width: $image-profile-medium-width;
        height: $image-profile-medium-height;
        object-fit: $image-cover-object-fit;
        border-radius: $border-radius-circular;

        @media screen and (max-width: $breakpoint-550) {
            width: 3.063rem;
            height: 3.063rem;
        }
    }

    &__left-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 2%;

        &:hover {
            cursor: pointer;
        }

        margin-left: 2%;
        margin-top: 1rem;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 78%;
        margin-top: 1rem;
    }

    &__right-panel {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        margin-right: 1rem;
        align-items: center;

        @media screen and (max-width: $breakpoint-550) {
            margin-right: 0.6rem;
        }
    }

    &__text,
    &__textMore25 {
        font-family: $font-family-inter;
        color: $color-red;
        font-weight: 700;
        max-width: 17.2rem;
        width: 100%;

        &:hover {
            cursor: pointer;
        }
    }

    &__text {
        font-size: $font20;

        @media screen and (max-width: $breakpoint-1763) {
            max-width: 13.2rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            max-width: 17.5rem;
        }

        @media screen and (max-width: $breakpoint-1279) {
            max-width: 14.5rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 17.5rem;
            width: fit-content;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font16;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 10rem;
        }
    }

    &__textMore25 {
        font-size: $font16;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font20;
            max-width: 24.2rem;
        }

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font16;
        }

        @media screen and (max-width: $breakpoint-550) {
            word-break: break-word;
        }
    }

    &__overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-darker-blue;
        max-width: 15.2rem;
        width: 100%;

        @media screen and (max-width: $breakpoint-1763) {
            max-width: 13.2rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            max-width: none;
            font-size: $font13;
        }

        @media screen and (max-width: $breakpoint-1279) {
            max-width: 14.5rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 15.5rem;
            width: fit-content;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 10rem;
        }

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }
    }

    &__right-text {
        font-family: $font-family-inter;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.563rem;
        font-weight: 700;
        width: fit-content;
        min-width: 3.75rem;
        border-radius: 0.9rem;
        font-size: $font10;
        color: $color-white;
        text-align: center;

        @media screen and (max-width: $breakpoint-550) {
            width: 2.9rem;
        }

        &--private {
            background-color: $color-gold-opacity-65;
        }

        &--public {
            background-color: $color-blue-opacity-04;
        }
    }
}

.feed-item-room-card-small {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    padding-bottom: 0.8rem;
    border-top: solid 0.03rem $color-grey-c4;
    margin-top: 0.5rem;

    &__image {
        width: $image-profile-small-width;
        height: $image-profile-small-height;
        object-fit: $image-cover-object-fit;
        border-radius: $border-radius-circular;

        @media screen and (max-width: $breakpoint-550) {
            width: 3.063rem;
            height: 3.063rem;
        }
    }

    &__left-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 2%;

        &:hover {
            cursor: pointer;
        }

        margin-left: 1.75rem;
        margin-top: 1rem;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 78%;
        margin-top: 1rem;
    }

    &__right-panel {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        margin-right: 1rem;
        align-items: center;

        @media screen and (max-width: $breakpoint-550) {
            margin-right: 0.6rem;
        }
    }

    &__text,
    &__textMore25 {
        font-family: $font-family-inter;
        color: $color-red;
        font-weight: 700;
        max-width: 17.2rem;
        width: 100%;

        &:hover {
            cursor: pointer;
        }
    }

    &__text {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: $font14;

        @media screen and (max-width: $breakpoint-1763) {
            max-width: 25rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            max-width: 17.5rem;
        }

        @media screen and (max-width: $breakpoint-1279) {
            max-width: 12.5rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 17.5rem;
            width: fit-content;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font14;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 10rem;
        }
    }

    &__textMore25 {
        font-size: $font16;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font20;
            max-width: 24.2rem;
        }

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font16;
        }

        @media screen and (max-width: $breakpoint-550) {
            word-break: break-word;
        }
    }

    &__overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $font14
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-darker-blue;
        max-width: 15.2rem;
        width: 100%;

        @media screen and (max-width: $breakpoint-1763) {
            max-width: 13.2rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            max-width: none;
            font-size: $font13;
        }

        @media screen and (max-width: $breakpoint-1279) {
            max-width: 14.5rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 15.5rem;
            width: fit-content;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 10rem;
        }

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }
    }

    &__right-text {
        font-family: $font-family-inter;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.563rem;
        font-weight: 700;
        width: fit-content;
        min-width: 3.75rem;
        border-radius: 0.9rem;
        font-size: $font10;
        color: $color-white;
        text-align: center;

        @media screen and (max-width: $breakpoint-550) {
            width: 2.9rem;
        }

        &--private {
            background-color: $color-gold-opacity-65;
        }

        &--public {
            background-color: $color-blue-opacity-04;
        }
    }
}

.feed-item-menu {
    position: relative;
    display: inline-block;
}

.feed-item-menu-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 14rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    padding: 12px 16px;
    z-index: 1;
    top: 2rem;
    right: 0rem;
    font-family: $font-family-inter;
    font-size: $font14;
    font-weight: 400;
    padding: 0;
    height: 2.5rem;
}

.feed-item-menu-item {
    display: flex;
    justify-content: center;
    font-size: $font12;
    align-items: center;
    height: 2rem;
    padding: 0.25rem;
    border-radius: inherit;
    color: $color-dark-grey;

    &:hover {
        background-color: $color-grey;
    }
}

.feed-item-profile-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5rem;
    word-break: break-word;

    &__dot {
        height: 0.3rem;
        margin: 0.1rem;
        width: 0.3rem;
        background-color: $color-grey-c4;
        border-radius: 50%;
        display: inline-block;
    }

    &__dots-menu {
        display: flex;
        flex-direction: column;
        margin-left: 0.25rem;
        cursor: pointer;
    }

    &__left-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        min-width: 4.5rem;
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 0.5rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            margin-left: 0.3rem;
        }

        &:hover {
            cursor: pointer;
        }

        &--deleted {
            &:hover {
                cursor: default;
            }
        }
    }

    &__middle-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 87%;
    }

    &__right-panel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-right: 0.6rem;
        }
    }

    &__text,
    &__textMore25 {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        &--deleted {
            color: $color-grey-c4;

            &:hover {
                cursor: default;
                text-decoration: none;
            }
        }
    }

    &__textMore25 {
        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-grey;
        margin-right: 0.3rem;
    }

    &__right-text {
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font12;
        display: flex;
        width: max-content;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $breakpoint-550) {
            font-size: 11px;
        }
    }

    &__date {
        width: max-content;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $color-red;
        background-color: $color-grey;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        padding-top: 0.15rem;
        padding-bottom: 0.15rem;
        margin-left: 0.75rem;
        border-radius: 0.9rem;

        @media screen and (max-width: $breakpoint-550) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-left: 0.5rem;
        }
    }

    &__comments {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-blue;
        cursor: pointer;
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;


        &:hover {
            filter: $hover-brightness;
        }
    }

    &__comment-title {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-blue;
        cursor: default;
        margin-left: 1rem;
    }

    &__comment-section {
        width: 100%;
        margin-bottom: 2rem;

        @media screen and (max-width: $breakpoint-1023) {
            //width: 95%;
        }

        @media screen and (max-width: $breakpoint-550) {
            //margin-left: 1rem;
        }

        @media screen and (max-width: $breakpoint-420) {
            //margin-left: 0.75rem;
        }
    }

    &__comment-section-hidden {
        display: none;
    }

    &__time {
        color: $color-grey-c4;
    }
}

.comments-feed-container {
    margin-left: 0.5rem;
}

.tox-toolbar--scrolling {
    overflow: auto;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;

    &::-webkit-scrollbar {
        display: none !important;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent !important;
    }
}

.orga-feed-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 50%;
    box-sizing: border-box;

    &__text a {
        color: $color-blue;
        word-break: break-all;
    }

    @media screen and (max-width: $breakpoint-550) {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    &__loading-card {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        margin: 1rem;
        width: 45%;
        box-sizing: border-box;
        height: 19.438rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $breakpoint-1599) {
            width: 100%;
        }
    }

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }

    &__card {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        width: 100%;
    }

    &__text-container {
        padding-left: 2rem;
        padding-right: 1rem;
        padding-top: 0.938rem;
        margin-bottom: 1.2rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: calc(1.19em * 5);
        line-height: 1.19em;
        word-break: break-word;

        &:hover {
            cursor: pointer;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
    }

    &__request {
        margin-left: 6%;
        margin-right: 3%;
        margin-bottom: 3%;
        color: $color-red;
        font-family: $font-family-inter;
        font-size: $font15;
    }
}

.orga-feed-item-room-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: solid 0.03rem $color-grey-c4;
    height: fit-content;
    padding-bottom: 0.8rem;

    &__image {
        width: $image-profile-medium-width;
        height: $image-profile-medium-height;
        object-fit: $image-cover-object-fit;
        border-radius: $border-radius-circular;

        @media screen and (max-width: $breakpoint-550) {
            width: 3.063rem;
            height: 3.063rem;
        }
    }

    &__left-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 4%;

        &:hover {
            cursor: pointer;
        }

        margin-left: 4%;
        margin-top: 1rem;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 78%;
        margin-top: 1rem;
    }

    &__right-panel {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-right: 0.6rem;
        }
    }

    &__text,
    &__textMore25 {
        font-family: $font-family-inter;
        color: $color-red;
        font-weight: 700;
        max-width: 17.2rem;
        width: 100%;

        &:hover {
            cursor: pointer;
        }
    }

    &__text {
        font-size: $font20;

        @media screen and (max-width: $breakpoint-1763) {
            max-width: 13.2rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            max-width: 17.5rem;
        }

        @media screen and (max-width: $breakpoint-1279) {
            max-width: 14.5rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 17.5rem;
            width: fit-content;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font16;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 10rem;
        }
    }

    &__textMore25 {
        font-size: $font16;
        word-break: break-word;

        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font20;
            max-width: 24.2rem;
        }

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font16;
        }

        @media screen and (max-width: $breakpoint-550) {
            word-break: break-word;
        }
    }

    &__overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-darker-blue;
        max-width: 15.2rem;
        width: 100%;

        @media screen and (max-width: $breakpoint-1763) {
            max-width: 13.2rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            max-width: none;
            font-size: $font13;
        }

        @media screen and (max-width: $breakpoint-1279) {
            max-width: 14.5rem;
        }

        @media screen and (max-width: $breakpoint-1023) {
            max-width: 15.5rem;
            width: fit-content;
        }

        @media screen and (max-width: $breakpoint-450) {
            width: 10rem;
        }

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }
    }

    &__right-text {
        font-family: $font-family-inter;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.563rem;
        font-weight: 700;
        width: fit-content;
        min-width: 3.75rem;
        border-radius: 0.9rem;
        font-size: $font10;
        color: $color-white;
        text-align: center;

        @media screen and (max-width: $breakpoint-550) {
            width: 2.9rem;
        }

        &--private {
            background-color: $color-gold-opacity-65;
        }

        &--public {
            background-color: $color-blue-opacity-04;
        }
    }
}

.orga-feed-item-profile-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5rem;
    word-break: break-word;

    &__left-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        min-width: 4.5rem;
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-left: 0.5rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            margin-left: 0.3rem;
        }

        &:hover {
            cursor: pointer;
        }

        &--deleted {
            &:hover {
                cursor: default;
            }
        }
    }

    &__middle-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 87%;
    }

    &__right-panel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 1rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-right: 0.6rem;
        }
    }

    &__text,
    &__textMore25 {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        &--deleted {
            color: $color-grey-c4;

            &:hover {
                cursor: default;
                text-decoration: none;
            }
        }
    }

    &__textMore25 {
        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-grey;
        margin-right: 0.3rem;
    }

    &__right-text {
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font12;
        display: flex;
        width: max-content;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: $breakpoint-550) {
            font-size: 11px;
        }
    }

    &__date {
        width: max-content;
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $color-red;
        background-color: $color-grey;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        padding-top: 0.15rem;
        padding-bottom: 0.15rem;
        margin-left: 0.75rem;
        border-radius: 0.9rem;

        @media screen and (max-width: $breakpoint-550) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-left: 0.5rem;
        }
    }

    &__time {
        color: $color-grey-c4;
    }
}

.feed-item-suggested-content {
    z-index: 2;
    margin-bottom: -1rem;
    border: solid 0.03rem $color-grey-c4;
    border-radius: 0.62rem;
    padding: 0.4rem;
    background: white;
    font-family: $font-family-inter;
    font-size: 1rem;
    font-weight: 700;
    color: $color-dark-grey;
}